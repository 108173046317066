
import {pairwise, filter} from 'rxjs/operators';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ReactiveFormsModule, FormsModule, UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { ProgressBarService, AuthService, NewOrderService, PricingService, ConfigService } from '../../../services';
import { AutogrowDirective } from '../../../directives';
import { CONSTANTS } from '../../../app.constants'
import { DialogService } from "ng2-bootstrap-modal";
import { MessageComponent } from '../../../dialog/message/message.component';
import { SuccessComponent } from '../../../dialog/success/success.component';
import { Observable } from 'rxjs';
import { ErrorDialogComponent } from '../../../dialog/error-dialog/error-dialog.component';
import { Location } from '@angular/common';
import { FileService } from '../../../services';
import { UUID } from 'angular2-uuid';
import * as _ from 'underscore';
import { MatSelect } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';


declare var google: any

@Component({
  selector: 'app-neworder2',
  templateUrl: './neworder2.component.html',
  styleUrls: ['./neworder2.component.scss']
})
export class Neworder2Component implements OnInit {

  @ViewChild('firstFocus') firstFocus: MatSelect;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('myInputState') myInputState: ElementRef;
  @ViewChild('myInputState1') myInputState1: ElementRef;
  @ViewChild('myInputState2') myInputState2: ElementRef;
  @ViewChild('myInputCounty') myInputCounty: ElementRef;
  @ViewChild('myInputCounty1') myInputCounty1: ElementRef;
  @ViewChild('myInputCounty2') myInputCounty2: ElementRef;

  newOrderForm: UntypedFormGroup;
  newOrderModel: any = {};
  error: String = '';
  errors: any = {};
  fieldError=[];
  searchError: boolean = false;
  states = [];
  counties = [];  
  selectedStates = [];
  selectedCounties = [];
  primaryColor = '#0F9D58';
  googlePlaceState = '';
  latitude: any;
  longitude: any;
  documents = [];
  selectedCriteria: any;
  selectedOption:string;
  resetScreenData = true;
  input = "";
  checkAvlCounty: any;
  timer: any = [];
  showCityInCounty: boolean = false;
  allIndepdentCities: any;
  // products: Observable<any>
  constructor(
    private frmBuilder: UntypedFormBuilder,
    private progressServ: ProgressBarService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private auth: AuthService,
    private newOrder: NewOrderService,
    private pricingService: PricingService,
    private route: ActivatedRoute,
    private matDialog:MatDialog,
    private location: Location,
    private fileService: FileService,
    private config : ConfigService
  ) {
    this.newOrderForm = frmBuilder.group({
      Order_Co_Buyer: [null, Validators.compose([Validators.maxLength(127)])],
      Order_Buyer: [null, Validators.compose([Validators.maxLength(127)])],
      Property_Address_1: [null, Validators.compose([Validators.required,Validators.maxLength(127)])],
      Property_Address_2: [null, Validators.maxLength(127)],
      Property_City: [null, Validators.compose([Validators.required,Validators.maxLength(127)])],
      // Property_State_Abbr: [null, Validators.required],
      Property_State_Abbr: new UntypedFormControl({value: null, disabled: false}, Validators.required),
      // Property_County: [null, Validators.required],
      Property_County: new UntypedFormControl({value: null, disabled: false}, Validators.required),
      Property_ZipCode: [null, Validators.compose([Validators.required, Validators.maxLength(11)])],
      Property_First_Name: [null, Validators.maxLength(127)],
      searchValue: [null],
      radioBtn: [null],

      // Order_Co_Buyer: [null],
      // Order_Buyer: [null],
      // Order_Buyer: [null, Validators.compose([Validators.maxLength(127)])],
      // Property_Address_1: [null, Validators.compose([Validators.maxLength(127)])],
      // Property_City: [null, Validators.compose([Validators.maxLength(127)])],
      // Property_State_Abbr: [null, Validators.compose([Validators.required, Validators.maxLength(2)])],
      // Property_ZipCode: [null, Validators.compose([Validators.maxLength(11)])],      
      // Property_Order_Number: [null, Validators.compose([Validators.required, Validators.maxLength(25)])],
      // Property_Order_Notes: [null],
      Order_Type: [null, Validators.required],
      // Order_Instructions: [null],
      // Order_Seller: [null, Validators.compose([Validators.maxLength(127)])],
      // Order_Co_Seller: [null, Validators.compose([Validators.maxLength(127)])],
      // Order_Seller: [null],
      // Order_Co_Seller: [null],
      Order_Parcel: [null, Validators.compose([Validators.maxLength(255)])],
      Order_Subdivision: [null, Validators.compose([Validators.maxLength(255)])],
      Order_Loan: [null, Validators.compose([Validators.maxLength(255)])],
      // Order_Mortgage_Amount: [null],
      // Order_Mortgage_Date: [null],
      Order_Book: [null, Validators.compose([Validators.maxLength(255)])],
      Order_Page: [null, Validators.compose([Validators.maxLength(255)])],
    })
    this.newOrderForm.valueChanges.subscribe((data) => {
      this.error = '';
    });
    if (Number(this.auth.getUserCode()) == CONSTANTS.userStatus.deActivated) {
      this.router.navigate(['/orders/new'])
    }
  }

  ngOnInit() {
    //this.checkForReset()
    this.getCountyForIndependCity();
    this.progressServ.setPrimaryColor(this.primaryColor);
    this.progressServ.setHeight(2);
    this.progressServ.setStage(1);
    this.progressServ.setTotalStages(3);
    this.progressServ.setProgressState((1.5 / 3) * 100);
    this.progressServ.setBarType(1);
   this.pricingService.getStateList().subscribe((states) => { 
       if (states) {
          var tempStates = [];
          states.forEach((state) => tempStates.push({ State_Short_Name : state[0].toUpperCase() }));
          this.states = tempStates;
          this.selectedStates = tempStates;
         }
       });
    this.newOrderModel.Property_State_Abbr = '';
    this.newOrderModel.orderDocs = [];
    this.newOrderModel.OrderDocId = [];
    if (this.newOrder.getselectedOption()) this.selectedOption = this.newOrder.getselectedOption();
    else this.selectedOption = "Auto Search";
    if (this.newOrder.getStep1Data()) {
      this.selectedCriteria = this.newOrder.getSelectedCriteria();
      this.resetScreenData = false;
      // this.newOrderModel = this.newOrder.getStep1Data();
      this.newOrderModel = Object.assign({}, this.newOrder.getStep1Data());
      this.latitude = this.newOrderModel.Property_Latitude;
      this.longitude = this.newOrderModel.Property_Longitude;
      // this.newOrderModel.selectedCriteria = this.selectedCriteria;
      // this.newOrderModel.selectedCriteria = this.newOrderModel.Property_Products[0];
      //if (this.newOrderModel.Order_Mortgage_Date) document.getElementById('Order_Mortgage_Date').setAttribute('type', 'date');
      this.timer.push(setTimeout(() => {
        this.config.setSideBarNode(0);
        this.firstFocus.focus();
      }, 0));
    }
    else this.router.navigate(['/customer/orders/new/step1']);
    // if(this.newOrder.getNewStepData()){
    //    console.log("if -new order step 3",this.newOrder.getNewStepData());
    //    this.newOrderModel = this.newOrder.getNewStepData();
    // }
    // this.products = this.orderList.getOrderProducts();

  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  // checkForReset() {
  //   console.log('looking for url param back', this.route.snapshot.params['back'])
  //   if (!this.route.snapshot.params['back']) {
  //     this.newOrder.resetAll();
  //   }
  // }

  ngAfterViewInit() {
    let searchBox: any = document.getElementById('search-box');
    let options = {
      types: [
        // return only geocoding results, rather than business results.
        'geocode',
      ]
    };
    var autocomplete = new google.maps.places.Autocomplete(searchBox, options);
    // Add listener to the place changed event
    autocomplete.addListener('place_changed', () => {
      this.getPlaceDetails(autocomplete.getPlace());

    });
  }



  validateForm() {
    console.log("order MODEL: ", this.newOrderModel);
    console.log("order type: ", this.selectedCriteria);
    this.errors = {};
    if (!this.newOrderModel.Property_State_Abbr) this.errors['State'] = true;
    if (!this.newOrderModel.Property_County) this.errors['County'] = true;
    if (this.selectedCriteria == 1) {
      if (!this.newOrderModel.Property_Address_1) this.errors['Property Address'] = true;
      if (!this.newOrderModel.Property_City) this.errors['City'] = true;
      if (!this.newOrderModel.Property_ZipCode) this.errors['ZipCode'] = true;
    } else if (this.selectedCriteria == 2) {
      if (!this.newOrderModel.Order_Buyer) this.errors['Buyer'] = true;
    } else if (this.selectedCriteria == 3) {
      if (!this.newOrderModel.Order_Parcel) this.errors['Parcel'] = true;
      if (!this.newOrderModel.Order_Loan) this.errors['Loan'] = true;
    }

    if (Object.keys(this.errors).length == 0) {
      this.goToStep3();
    }
    else {
      // this.errors['Minimun_Mandatory_Fields'] = true;
      //this.fieldError = "Please fill all required fields.";
      this.fieldError = Object.keys(this.errors);
    }
  }

  getPlaceDetails(place: any) {
    this.newOrderModel.searchValue = '';
    console.log("place.geometry: ", place);
    this.clearErrors();
    if (place.geometry == undefined) {
      //set an error - show the error under google search line
      // a custom validator need to be defined and an error message "Place not found" need to be shown

      //console.log("noBookmarkError:",this.noBookmarkError);
      //this.newOrderModel.Property_Address_1 = '';
      this.newOrderModel.Property_City = '';
      this.newOrderModel.Property_State_Abbr = '';
      this.newOrderModel.Property_ZipCode = '';
      // this.newOrderModel.Property_County = '';
      this.checkAvlCounty='';
      // document.getElementById('search-box').parentElement.classList.add('is-empty');
      // document.getElementById('Property_City').parentElement.classList.add('is-empty');
      // document.getElementById('Property_State_Abbr').parentElement.classList.add('is-empty');
      // document.getElementById('Property_ZipCode').parentElement.classList.add('is-empty');
      this.searchError = true;
      return;
    } else {
      this.searchError = false;
      this.latitude = place.geometry.location.lat();
      this.longitude = place.geometry.location.lng();

      this.newOrderModel.Property_Address_1 = '';
      // this.newOrderModel.Property_Address_2 = '';
      this.newOrderModel.Property_City = '';
      this.newOrderModel.Property_State_Abbr = '';
      this.newOrderModel.Property_ZipCode = '';
      this.newOrderModel.Property_County = '';
      this.checkAvlCounty='';
      // for (let i = 0; i < place.address_components.length; i++) {
      for (let i = place.address_components.length - 1; i >= 0; i--) {
        switch (place.address_components[i].types[0]) {
          case 'premise': this.newOrderModel.Property_Address_1 = place.address_components[i].long_name + ' ' +  this.newOrderModel.Property_Address_1;
            document.getElementById('Property_Address_1').parentElement.classList.remove('is-empty');
            break;
          case 'street_number': this.newOrderModel.Property_Address_1 = place.address_components[i].long_name + ' ' + this.newOrderModel.Property_Address_1;
            document.getElementById('Property_Address_1').parentElement.classList.remove('is-empty');
            break;
          case 'route': this.newOrderModel.Property_Address_1 =place.address_components[i].long_name;
            document.getElementById('Property_Address_1').parentElement.classList.remove('is-empty');
            break;
          case 'sublocality_level_1': if (this.newOrderModel.Property_City.length == 0) this.newOrderModel.Property_City = place.address_components[i].long_name;
            document.getElementById('Property_City').parentElement.classList.remove('is-empty');
            break;
          case 'sublocality': if (this.newOrderModel.Property_City.length == 0) this.newOrderModel.Property_City = place.address_components[i].long_name;
            document.getElementById('Property_City').parentElement.classList.remove('is-empty');
            break;
          case 'locality': if (this.newOrderModel.Property_City.length == 0) this.newOrderModel.Property_City = place.address_components[i].long_name;
            document.getElementById('Property_City').parentElement.classList.remove('is-empty');
            break;
          case 'administrative_area_level_2': {
            if (this.checkSaint(place.address_components[i].long_name)) this.checkCountyNReplace(place, i)
            else this.checkCountyNReplace(place, i)
            this.checkAvlCounty = place.address_components[i].long_name.toUpperCase();
            // place.address_components[i].long_name = place.address_components[i].long_name.replace('St.', 'saint');
            //if (this.checkCounty(place.address_components[i].long_name))
            // console.log("Switch Case",this.newOrderModel.Property_County, this.newOrderModel.Property_County);
            // document.getElementById('Property_County').parentElement.classList.remove('is-empty');
            break;
          }
          case 'administrative_area_level_1':{
            this.newOrderForm.controls['Property_State_Abbr'].setValue(place.address_components[i].short_name);
            this.googlePlaceState = place.address_components[i].short_name;
            // this.newOrderModel.Property_State_Abbr = place.address_components[i].short_name;
            if (place.address_components[i].short_name == 'DC') this.checkAvlCounty = "WASHINGTON"
            // document.getElementById('Property_State_Abbr').parentElement.classList.remove('is-empty');
            // console.log("Am I Here..");
            break;
          }
          case 'postal_code': this.newOrderModel.Property_ZipCode = place.address_components[i].short_name;
            //document.getElementById('Property_ZipCode').parentElement.classList.remove('is-empty');
            break;
          case 'country': this.newOrderModel.Country = place.address_components[i].short_name;
            break;
          default: break;
        }
      }
      if (this.checkIfState(this.newOrderModel.Property_State_Abbr, this.newOrderModel.Country)) {
        if(!this.checkAvlCounty){
          this.checkIfCityIsIndependent();
          this.showCityInCounty = true;
        } else this.showCityInCounty = false;
      }else {
        this.newOrderModel.Property_State_Abbr = '';
        this.newOrderModel.Property_County = '';
        this.newOrderModel.Country = '';
        this.errors['State'] = true;
        this.errors['County'] = true;
      }
      this.ref.detectChanges();
      console.log("errors", this.errors);
      console.log("this.newOrderForm", this.newOrderForm);
    }

  }

  checkSaint(county) {
    //console.log("saint check",county);
    var lowecaseCounties= CONSTANTS.saintArray.map((value)=>value.toLowerCase())
    if (county.toLowerCase().startsWith('st.') && lowecaseCounties.indexOf(county.toLowerCase()) == -1) return true;
    else return false;
  }

  checkCounty(county) {
    //console.log("county check",county)
    var lowecaseCounties= CONSTANTS.countyArray.map((value)=>value.toLowerCase())
    if (county.toLowerCase().endsWith(' county') && lowecaseCounties.indexOf(county.toLowerCase()) == -1) return true;
    else return false;
  }

  checkCountyNReplace(place, i){
    place.address_components[i].long_name = place.address_components[i].long_name.replace(' County', '')
  } 

  clearFormsEvent(event) {
    if (event) {
      this.getCountyList(event);
      if (event != this.googlePlaceState) {
      //   this.newOrderModel.Property_County = ''
      }
    }
    if(this.newOrderModel.Property_State_Abbr) delete this.errors.State;

  }

  detectManualChange(){
    this.newOrderModel.Property_County = null;
  }
  changeCriteria(){
    if(this.selectedCriteria == 1 || this.selectedCriteria == 3 ){
      this.newOrderForm.controls['Order_Buyer'].clearValidators();
      this.newOrderForm.controls['Order_Buyer'].setValidators([Validators.maxLength(127)])
      this.newOrderForm.controls['Order_Buyer'].updateValueAndValidity();
    }else{
      this.newOrderForm.controls['Order_Buyer'].setValidators([Validators.required, Validators.maxLength(127)])
      this.newOrderForm.controls['Order_Buyer'].updateValueAndValidity();
    }
    this.showCityInCounty = false;
  }

  clearForms() {
    if(this.resetScreenData){
      this.errors = {};
      this.counties=[];
      this.checkAvlCounty= null;
      this.newOrderForm.controls['Property_First_Name'].reset();
      this.newOrderForm.controls['Property_Address_1'].reset();
      this.newOrderForm.controls['Property_Address_2'].reset();
      this.newOrderForm.controls['Property_City'].reset();
      this.newOrderForm.controls['Property_State_Abbr'].reset();
      this.newOrderForm.controls['Property_County'].reset();
      this.newOrderForm.controls['Property_ZipCode'].reset();
      this.newOrderForm.controls['Order_Buyer'].reset();
      this.newOrderForm.controls['Order_Co_Buyer'].reset();
      this.newOrderForm.controls['Order_Parcel'].reset();
      this.newOrderForm.controls['Order_Loan'].reset();
      this.newOrderForm.controls['Order_Book'].reset();
      this.newOrderForm.controls['Order_Page'].reset();
      // this.newOrderModel.Property_Address_1 = '';
      // this.newOrderModel.Property_State_Abbr = '';
      // this.newOrderModel.Property_County = '';
      // this.newOrderModel.Order_Buyer = '';
      // this.newOrderModel.Order_Co_Buyer = '';
      // this.newOrderModel.Order_Parcel = '';
      // this.newOrderModel.Order_Loan = '';
      // this.newOrderModel.Order_Book = '';
      // this.newOrderModel.Order_Page = '';
      this.fieldError = [];
    } else this.resetScreenData = true;
  }

  clearErrors() {
    this.errors = {};
    this.fieldError = [];
  }

  validateDateFn(event) {
    //var DATE_REGEXP = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)\d{2}/; //for mm/dd/yyyy
    var DATE_REGEXP = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/;    //for m/d/yyyy and mm/dd/yyyy    
    if (event.targetElement.value && !DATE_REGEXP.test(event.targetElement.value)) this.errors.Order_Mortgage_Date = true;
    else this.errors = {};
  }

  getIfErrors() {
    if (Object.keys(this.errors).length) return true;
    else return false;
  }

  // sendMessage(text) {
  //   var data = {
  //     Message_Text: text,
  //     Sender_ID: this.auth.getUserId()
  //   }
  //   this.newOrder.customOrderMessage(data)
  //     .subscribe((data) => {
  //       console.log(data);
  //       this.openSuccessDialog();
  //       this.router.navigate(['/orders/new'])
  //     }, (err) => {
  //       console.log(err);
  //       this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
  //     })
  // }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  // openMessageInterface(msg) {
  //   let disposable = this.matDialog.open(MessageComponent, {
  //     title: this.text.get('BTN13'),
  //     message: msg
  //   })
  //     .subscribe((res) => {
  //       if (res) this.sendMessage(res)
  //       console.log("Closed", res)
  //     });
  // }

  openSuccessDialog() {
    let disposable = this.matDialog.open(SuccessComponent, {data:{
      title: 'Success',
      message: 'We have received your request. We will get back to you as soon as possible.'
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        console.log("Closed", res)
      });
  }


  getCountyList(event) {
    if (event) {
      if( this.checkAvlCounty ||this.checkAvlCounty == ''){
        if(this.newOrderModel.Property_County) this.newOrderModel.Property_County = null;
      }
      this.pricingService.getCountyList(this.newOrderModel.Property_State_Abbr).subscribe((counties) => {
        this.counties = counties;
        this.selectedCounties = counties;
        let avlCountyName = [];
        this.counties.forEach((county) => avlCountyName.push(county.County_Name.toUpperCase()))
        if(this.showCityInCounty) this.newOrderModel.Property_County = this.checkAvlCounty
        else {
          if (avlCountyName.indexOf(this.checkAvlCounty) > -1)
          this.newOrderModel.Property_County = this.checkAvlCounty;
        }
        this.ngAfterViewInit();
        this.ref.detectChanges();
      },(err) => {console.log("Fetching county list failed")});
    }
  }


  goToStep3() {
    console.log("Logging")
    this.newOrderModel.Property_Latitude = this.latitude;
    this.newOrderModel.Property_Longitude = this.longitude;
    this.newOrderModel.independent_City = this.showCityInCounty
    // this.newOrderModel.Property_Products = [];
    // this.newOrderModel.Property_Products.push(this.newOrderModel.selectedCriteria);
    this.newOrder.setStep1data(this.newOrderModel);
    console.log("Step 2...", this.newOrderModel);
    this.newOrder.setSelectedCriteria(this.selectedCriteria);
    this.newOrder.setSelectedOption(this.selectedOption);
    this.router.navigate(['/customer/orders/new/step3']);
    // this.router.navigate(['/user/' + this.auth.getUserId() + '/orders/new/step3']);
  }

  focusFunction(event) {
    event.srcElement.type = 'date'
  }


  goBack() {
    this.router.navigate(['/customer/orders/new/step1/1']);
    // this.router.navigate(['/user/' + this.auth.getUserId() + '/orders/new/step1/1']);
  }

  getFormGrpvalidation() {
    var flag = false;
    switch (this.selectedCriteria) {
      case 1:
        if (this.newOrderForm.controls['Property_Address_1'].invalid) flag = true;
        else if (this.newOrderForm.controls['Property_Address_2'].invalid) flag = true;
        else if (this.newOrderForm.controls['Property_State_Abbr'].invalid) flag = true;
        else if (this.newOrderForm.controls['Property_County'].invalid) flag = true;
        else if (this.newOrderForm.controls['Property_City'].invalid) flag = true;
        else if (this.newOrderForm.controls['Property_ZipCode'].invalid) flag = true;
        else if (this.newOrderForm.controls['Property_First_Name'].invalid) flag = true;
        break;
      case 2:
        if (this.newOrderForm.controls['Order_Buyer'].invalid) flag = true;
        else if (this.newOrderForm.controls['Order_Co_Buyer'].invalid) flag = true;
        else if (this.newOrderForm.controls['Property_State_Abbr'].invalid) flag = true;
        else if (this.newOrderForm.controls['Property_County'].invalid) flag = true;
        break;
      case 3:
        if (this.newOrderForm.controls['Order_Book'].invalid) flag = true;
        else if (this.newOrderForm.controls['Order_Page'].invalid) flag = true;
        else if (this.newOrderForm.controls['Order_Parcel'].invalid) flag = true;
        else if (this.newOrderForm.controls['Order_Loan'].invalid) flag = true;
        else if (this.newOrderForm.controls['Property_State_Abbr'].invalid) flag = true;
        else if (this.newOrderForm.controls['Property_County'].invalid) flag = true;
        break;
      default: flag = false;
        break;
    }
    return flag;
  }

  clearCountyError() {
    if (this.newOrderModel.Property_County) delete this.errors.County;
  }

  checkIfState(stateName, country) {
    if (country == 'US' && this.states.findIndex((state) => state.State_Short_Name.toLowerCase() == stateName.toLowerCase()) > -1) return true;
    else return false;
  }

  getLoanFormat(e, newOrder) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if ((this.input.length == 0 && e.key == "0")) e.preventDefault();
    else {
      if ((e.keyCode == 8 || e.keyCode == 46) && (this.input.length > 0 || this.input.length < this.config.getLimitPriceMaxLength())) {
        this.input = this.input.slice(0, this.input.length - 1);
        e.target.value = this.numberFormat(this.input);
        // if (e.target.value == '') newOrder.Order_Loan = null;
        newOrder.Order_Loan = e.target.value;
      }
      else if (this.input.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          this.input = this.input + key;
          e.target.value = this.numberFormat(this.input);
          newOrder.Order_Loan = e.target.value;
        }
      }
      return false;
    }
  }

  numberFormat(val) {
    if (isNaN(parseFloat(val))) return null;
    var num = parseFloat(val);
    return (num / 100).toFixed(2);
  }

  keyValue(keyCode) {
    if (keyCode > 57) keyCode -= 48;
    if (keyCode >= 48 && keyCode <= 57)
      return String.fromCharCode(keyCode);
  }

  clearValid(control){
    control.markAsUntouched();
  }

  detectTypeChange(event) {
    this.counties=[];
    this.checkAvlCounty= null;
    this.newOrderForm.controls['searchValue'].reset();
    this.newOrderForm.controls['Property_Address_1'].reset();
    this.newOrderForm.controls['Property_Address_2'].reset();
    this.newOrderForm.controls['Property_City'].reset();
    this.newOrderForm.controls['Property_State_Abbr'].reset();
    this.newOrderForm.controls['Property_County'].reset();
    this.newOrderForm.controls['Property_ZipCode'].reset();
  }

  searchOptions(query, type: string, id: string){
    if(query.keyCode == 32 && query.type == 'keydown') query.stopPropagation();
    switch(type){
      case 'state': this.selectedStates = this.select(query.target.value, this.states, [0], id);break;
      case 'county': this.selectedCounties = this.select(query.target.value, this.counties, ['County_Name'], id);break;      
      default: console.log("Invalid");
    }    
  }

  select(query: string, entities, key, id):string[]{
    console.log("entities", entities);
    let result: string[] = [];
    if(_.isArray(key)){
      for(let a of entities){
        var matched = null;
        for(let k of key){
          if(a[k] && a[k].toLowerCase().indexOf(query.toLowerCase().trim()) > -1){
            if((matched == null) || (matched && matched[id] != a[id])) matched = a;
          }
        } 
        if(matched) result.push(matched);    
      }
    }
    else if(_.isString(key)){
      for(let a of entities){
        if(a[key].toLowerCase().indexOf(query.toLowerCase().trim()) > -1){
          result.push(a)
        }
      }
    }
    else console.log("Invalid search key");  
    return result;
  }

  clearValue(myInput, key){
    if(myInput.nativeElement) myInput.nativeElement.value = '';
    else myInput.value = '';
    if(key == 'state') this.selectedStates = this.states; 
    else if(key == 'county') this.selectedCounties = this.counties;
    else console.log("Invalid");
  }

  clickedState(event){
    if(event) this.timer.push(setTimeout(() => this.myInputState.nativeElement.focus(), 0));
    else this.clearValue(this.myInputState, 'state');
  }

  clickedState1(event){
    if(event) this.timer.push(setTimeout(() => this.myInputState1.nativeElement.focus(), 0));
    else this.clearValue(this.myInputState1, 'state');
  }

  clickedState2(event){
    if(event) this.timer.push(setTimeout(() => this.myInputState2.nativeElement.focus(), 0));
    else this.clearValue(this.myInputState2, 'state');
  }

  clickedCounty(event){
    if(event) this.timer.push(setTimeout(() => this.myInputCounty.nativeElement.focus(), 0));
    else this.clearValue(this.myInputCounty, 'county');
  }

  clickedCounty1(event){
    if(event) this.timer.push(setTimeout(() => this.myInputCounty1.nativeElement.focus(), 0));
    else this.clearValue(this.myInputCounty1, 'county');
  }

  clickedCounty2(event){
    if(event) this.timer.push(setTimeout(() => this.myInputCounty2.nativeElement.focus(), 0));
    else this.clearValue(this.myInputCounty2, 'county');
  }

  getCountyForIndependCity = (state?: string) => {
		this.pricingService.getIndpendentCities()
		.subscribe((cities) => {
			this.allIndepdentCities = cities;
		})
	};

	checkIfCityIsIndependent() {
	let findCityIndex = this.allIndepdentCities.findIndex((city) => {
		if(city) return this.newOrderModel.Property_City.toLowerCase() === city.System_Independent_City.toLowerCase()
		else return false;
	})
	if(findCityIndex > -1 ) {
		if(this.allIndepdentCities[findCityIndex].System_Independent_City_Custom_Name)  this.checkAvlCounty = this.allIndepdentCities[findCityIndex].System_Independent_City_Custom_Name.toUpperCase()
		else this.checkAvlCounty = this.newOrderModel.Property_City.toUpperCase();
	}else this.checkAvlCounty = this.newOrderModel.Property_City.toUpperCase();
	}
}
