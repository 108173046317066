// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge {
  font-weight: normal !important;
  font-size: 12px !important;
  background-color: transparent;
}

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
.mat-mdc-standard-chip.mdc-evolution-chip--selected:not(.mdc-evolution-chip--disabled) {
  border: 2px solid #143306 !important;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/orders/confimed-orders/confimed-orders.component.scss"],"names":[],"mappings":"AACA;EACI,8BAAA;EACA,0BAAA;EACA,6BAAA;AAAJ;;AAGA,4HAAA;AAKA;EACI,oCAAA;EACA,YAAA;AAJJ","sourcesContent":["@import '../../../scss/variables';\n.badge {\n    font-weight: normal !important;    \n    font-size: 12px !important;\n    background-color:transparent;\n}\n\n/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */\n// .mat-mdc-chip.mat-mdc-standard-chip.mat-chip-selected.mat-primary {\n//     border: 3px solid #2196f3 !important;\n//     color:black;    \n// }\n.mat-mdc-standard-chip.mdc-evolution-chip--selected:not(.mdc-evolution-chip--disabled) {\n    border: 2px solid  #143306 !important;\n    color:black; \n  } "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
