import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, Renderer2 } from '@angular/core';
import {
  AuthService, OrdersService, ProgressBarService, UsersService, MessagesService, ConfigService,
  AbstractorService, AwsS3Service, SearchMasterService, QuickbooksService, RoutingStateService,
  TagsService,
  UsermessageService,
  SubscribeService,
  ExpenseService,
  OrganizationService,
  SchedulingService,
} from '../../services';
import { Router, ActivatedRoute } from '@angular/router';
import { CONSTANTS } from '../../app.constants';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { MessageComponent } from '../../dialog/message/message.component';
import { AssignComponent } from '../../dialog/assign/assign.component';
import { FileService } from '../../services';
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { SuccessComponent } from '../../dialog/success/success.component';
import { CompleteOrderComponent } from '../../dialog/complete-order/complete-order.component';
import { Location, DatePipe } from '@angular/common';
import { CancelOrderComponent } from '../../dialog/cancel-order/cancel-order.component';
import { ErrorReportComponent } from '../../dialog/error-report/error-report.component';
import { ChangeOrderStatusComponent } from '../../dialog/change-order-status/change-order-status.component';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { DragDropDocsComponent } from '../../dialog/drag-drop-docs/drag-drop-docs.component';
import { AbstractorSendComponent } from '../../dialog/abstractor-send/abstractor-send.component';
import { AbstractorQuoteComponent } from '../../dialog/abstractor-quote/abstractor-quote.component';
import { CreateAbsDialogComponent } from '../../dialog/create-abs-dialog/create-abs-dialog.component';
import { RateAbstractorComponent } from '../../dialog/rate-abstractor/rate-abstractor.component';
import { NewAbsQuoteComponent } from '../../dialog/new-abs-quote/new-abs-quote.component';
import { AbstractorMessageComponent } from '../../dialog/abstractor-message/abstractor-message.component';
import { AbsQuoteMessageComponent } from '../../dialog/abs-quote-message/abs-quote-message.component';
import { AbstractorMessagesComponent } from '../../dialog/abstractor-messages/abstractor-messages.component';
import { CancelOrderMailComponent } from '../../dialog/cancel-order-mail/cancel-order-mail.component'
import { UUID } from 'angular2-uuid';
import * as _ from 'underscore';
import { ReviewCompletePackageComponent } from 'app/dialog/review-complete-package/review-complete-package.component';
import { ActionCommentComponent } from 'app/dialog/action-comment/action-comment.component';
import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';
import { NewTagComponent } from '../../dialog/new-tag/new-tag.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { UntypedFormControl, Validators } from '@angular/forms';
import { InternalNotesComponent } from '../../dialog/internal-notes/internal-notes.component';
import { ScreenOrderComponent } from '../../dialog/screen-order/screen-order.component';
import { ValidateOrdAddressComponent } from '../../dialog/validate-ord-address/validate-ord-address.component';
import { SitusamcAcceptReject } from '../../dialog/situsamc-accept-reject/situsamc-accept-reject.component';
import { EtaChangeReasonComponent } from '../../dialog/eta-change-reason/eta-change-reason.component';
import { OrderChecklistComponent } from 'app/dialog/order-checklist/order-checklist.component';
import { TrackerproResponseHandlerComponent } from 'app/dialog/trackerpro-response-handler/trackerpro-response-handler.component';
import { environment } from 'environments/environment';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
// import { AllCommunityModules } from '@ag-grid-community/all-modules';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { AgGridTooltipComponent } from '../../common/ag-grid-tooltip/ag-grid-tooltip.component';
import { OrderNoteComponent } from '../../dialog/order-note/order-note.component';
import { RptBuilderService } from 'app/report-builder/rpt-builder.service';
import { DocCategoryComponent } from 'app/doc-category/doc-category.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  providers: [DatePipe]
})
export class OrderDetailsComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
  @ViewChild('otherDocTagInp') otherDocTagInp: ElementRef;
  docLinkInClipBoard = '';
  orderSources : any = [];
  orderId: string;
  primaryColor = '#03a9f4';
  orderDetails: any = {};
  orderStatus: any;
  completeAddress: any;
  coborrowerAddress: any;
  billingAddress: string;
  fullAddress: any;
  orderNotes: any = {};
  orderIns: any = {};
  orderInternalNote: any = {};
  orderAdditionalNote: any = {};
  messages: any = [];
  parentMsgId = null;
  orderProducts: any = [];
  documents: any = [];
  adminList: any = {};
  isAllowed: boolean = false;
  isSearchAlreadyAvailable: boolean = false;
  copyMessage = '';
  constants = CONSTANTS;
  showPropStatus: boolean = false;
  abstractors = [];
  assignedAbstractor = {};
  absPricings: any;
  clientDocs: any = [];
  adminDocs: any = [];
  internalDocs: any = [];
  newDocs: any = [];
  invoicePresent: boolean = false;
  isRushOrder: boolean;
  isEscalatedOrder: boolean;
  uploadType: number;
  selectedDocsTab: number;
  isDate: boolean = false;
  netrOnlineLink: string = 'https://publicrecords.netronline.com/state/';
  ordTrackerLink: string = '';
  texasT00Link: string = '';
  runsheetLink: string = '';
  wimbaTemlLink: string = '';
  orderTags: any = [];
  orderExpenses: any = [];
  searchTag: string = '';
  availableTags: any = [];
  associatedTags: any = [];
  gSheetChecklist: any = [];
  gSheetCountyInfo: any = {};
  gSheetClientInfo: any = {};
  gSheetBillingInfo: any = {};
  gsheetSearchInfo: any = {};
  etaChangeReasons: any = [];
  ordsWithSameAddress: any = [];
  ordsWithSameParcel: any = []; // PT-3598
  ordsWithSameSubdivision: any = []; // PT-3598
  orderhistory: any = [];
  ordsWithSimilarCountyOrZip: any = [];
  ordsWithSameClient: any = [];
  showAllWithSameAdd: boolean = false;
  showAllWithSimilarAdd: boolean = false;
  showAllWithSameClient: boolean = false;
  isChecklist: boolean = false;
  isCountyInfo: boolean = false;
  isClientInfo: boolean = false;
  isBillingInfo: boolean = false;
  isRelatedSearches: boolean = false;
  isSearchInfo: boolean = false;
  isOrderHistory: boolean = false;
  isETAChange: boolean = false;
  subscribeDetails: any = {};
  isQualiaOrder: boolean = false;
  bills: any = [];
  subscriptionStatus: boolean;
  loadingCheckList: boolean = false;
  loadingCountyInfo: boolean = false;
  loadingClientInfo: boolean = false;
  loadingBillingInfo: boolean = false;
  loadingRelatedSearches: boolean = false;
  loadingOrderHistory: boolean = false;
  loadingSearchInfo: boolean = false;
  loadingETAChange: boolean = false;
  documentsTag: any = [];
  tagDocumentName: any;
  isOtherDocumentTag: boolean = false;
  otherDocsTag: boolean = false;
  tooltipText = "1. Assign to Search master for in-house processing\n 2. Assign to Admin and send to abstractor"
  otherDocTagInput = new UntypedFormControl(null, [Validators.required, Validators.maxLength(64)]);
  mainChargeRefundedAmount;
  orderAbstractor: any = null;
  timer: any = [];
  cancelOrderFlag: boolean = false
  cancelOrderBool: boolean = false;
  mail_text: string = '';
  progressBar: boolean = false;
  isTrackerProOrder: boolean = false;
  trackerProStatus: number = 0;
  cancelPayment: boolean = false;
  holidayList: any;
  isReadyForBilling: boolean = false;
  invoiceDetailsInfo : any = null;
  allOrderStatus: any;
  modules = [ClientSideRowModelModule, InfiniteRowModelModule];
  domLayout = "autoHeight"
  editType = "fullRow";
  columnDefs: any;
  rowData = [];
  gridApi: any;
  gridColumnApi: any;
  borrowerDetails: any = {};
  copyBorrowerDetails : any={};
  defaultColDef = { width: 250, sortable: true, resizable: true }
  dataDepthYears : any;
  environment = environment;
  documentCategories:any = [];
  private ngUnsubscribe = new Subject();
  
  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrdersService,
    private userService: UsersService,
    private matDialog:MatDialog,
    private fileService: FileService,
    private progressServ: ProgressBarService,
    private msgService: MessagesService,
    private config: ConfigService,
    private abstractorService: AbstractorService,
    private s3Service: AwsS3Service,
    private quickbooks: QuickbooksService,
    private routingState: RoutingStateService,
    private tagService: TagsService,
    private userMsg: UsermessageService,
    private subscribeService: SubscribeService,
    private expenseService: ExpenseService,
    private organizationService: OrganizationService,
    private holidayScheduling: SchedulingService,
    // private reportBuiderService: ReportBuilderService
    private reportBuiderService: RptBuilderService,
    private location: Location,
    public dialog: MatDialog,
    private clipboard: Clipboard
  ) { 
    this.config.setMySidebar(false);
  }

  ngOnInit() {
    this.auth.refreshToken();
    this.orderService.setChecklistData([]);
    if(this.checkAdminType()){
      this.getOrderSourceTypes();
      if (this.checkIfSearchMaster()) {
        this.route.params.subscribe(params => {
          this.orderService.getStatus(params['orderId'])
            .subscribe((orderDetails) => {
              let status = orderDetails.Order_Status;
              let orderCreatedBy = orderDetails.Order_Created_BY;
              if ((status == CONSTANTS.orderStatus.cancelled)) {
                this.progressBar = false;
                this.router.navigate(['/']);
              } else {
                if(this.auth.isSearchManager()) {
                  this.executeOninit();
                } else {
                this.orderService.getOrderAssignment(params['orderId'])
                  .subscribe((assign) => {
                    if (((assign.Order_Assignment_Admin_ID != this.auth.getUserId()) && (status != CONSTANTS.orderStatus.completed) && (orderCreatedBy != this.auth.getUserId()))) {
                      this.progressBar = false;
                      this.router.navigate(['/']);
                    } else this.checkGroupTagsNOrdPermission();
                  })
                }
              }
            })
        })
      } else this.checkGroupTagsNOrdPermission();
    }else this.router.navigate(['/']);
    this.orderService.documentCategories$.pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((data) => {
      this.documentCategories = data;
      this.adminDocs = this.adminDocs.map((item) => {
        if(item.Doc_Cat_ID){
          let matchedItem =  this.documentCategories.find((cat) => item.Doc_Cat_ID == cat.Id);
          item.Category = matchedItem ? matchedItem.Category : 'Click to categorize';
        }
        else item.Category = 'Click to categorize'
        return item;
      });
      this.internalDocs = this.internalDocs.map((item) => {
        if(item.Doc_Cat_ID){
          let matchedItem =  this.documentCategories.find((cat) => item.Doc_Cat_ID == cat.Id);
          item.Category = matchedItem ? matchedItem.Category : 'Click to categorize';
        }
        else item.Category = 'Click to categorize'
        return item;
      });
    });
  }

  showSideBarPanel(){
    if(Number(this.config.getSideBarNode())) this.timer.push(setTimeout(() => this.config.setSideBarNode(this.config.getSideBarNode()), 0));
    else this.timer.push(setTimeout(() => this.config.setSideBarNode(1), 0));
  }

  ngOnDestroy() {
    this.timer.forEach((time) => {
      clearTimeout(time)
    });
    this.ngUnsubscribe.next(null);
    this.ngUnsubscribe.complete();
  }

  isCompAdmin(){
    let role = Number(this.auth.getUserRole());
    if(role == CONSTANTS.userRole.superAdmin || role == CONSTANTS.userRole.compAdmin) return true;
    else return false;
  }

  checkGroupTagsNOrdPermission() { 
    if(this.isCompAdmin()) this.executeOninit();
    else this.route.params.subscribe(params =>
    this.orderService.checkOrderTags(params['orderId'], this.auth.getUserId())
      .subscribe((status) => {
        if (!status.approved) {
          this.progressBar = false;
            if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) this.router.navigate(['/orders/inprocess']);
            else this.router.navigate(['/orders/new']);
            this.openDenyPermission();
        } else this.executeOninit();
      }, (err) => {
        if (err.code) this.openErrorPopup(err.msg);
        if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) this.router.navigate(['/orders/inprocess']);
        else this.router.navigate(['/orders/new']);
      }))
  }

  executeOninit() {
    this.progressBar = true;
    this.route.params.subscribe(params => {
      this.orderId = params['orderId'];
      this.initializeProgressBar();
      this.columnDefs = this.createBorrowerCol()
      this.getOrderDetails(this.orderId);
      this.getBorrowerDetails(this.orderId);
      this.getSubscriptionDetails(this.orderId);
      this.getOrderTags(this.orderId);
      this.getOrderNotes(this.orderId);
      this.getAllNotesbyOrdId(this.orderId);
      this.getOrderMessages(this.orderId);
      this.getOrderProducts(this.orderId);
      this.getDocs(this.orderId);
      this.getTimeline(this.orderId);
      this.getChecklistFromGsheet(this.orderId);
      this.getAllOrderStatus(this.orderId);
      this.auth.deleteUserNotificationbyOrderIdAndUserId(this.orderId).subscribe(() => { });
      this.orderStatus = this.orderService.getOrderStatus();
      // this.getAllSystemDocumentsTag();
      if (this.checkIfSearchMaster()) this.uploadType = CONSTANTS.orderDocumentType.internalDocs;
      else this.uploadType = CONSTANTS.orderDocumentType.adminDocs;
      // this.userService.getAllAdminsToOrderAssign().subscribe((adminData) => {
      //   this.adminList = adminData;
      // });
      console.log("Len", this.documents.length);
      //  this.userService.getAllCustomers().subscribe((adminData) =>{
      //   this.adminList = adminData;
      // });
      // this.timer.push(setTimeout(() => this.config.setSideBarNode(0), 0));
      this.showSideBarPanel();
      this.fetchUploadProgress();
      this.s3Service.resetPresignedURL();
      this.s3Service.presignedURL$.subscribe((data) => {
        this.docLinkInClipBoard = data;
        this.clipboard.copy(data);
      })
    });
    this.getholidaylistFromLocal();
    // this.getholidaylist();
    // this.quickbooks.getOrderInvoice(this.orderDetails.Order_ID)
    // .subscribe((invoice) => {if(invoice) this.invoicePresent=true })      
    this.orderService.getDocumentCategories().subscribe();
  }

  getAllSystemDocumentsTag() {
    this.orderService.getAllSystemDocumentsTag()
      .subscribe((documentTag) => {
        if (documentTag) this.documentsTag = documentTag;
        else this.documentsTag = [];
      })
  }

  initializeProgressBar() {
    this.progressServ.setPrimaryColor(this.primaryColor);
    this.progressServ.setHeight(2);
    this.progressServ.setStage(0);
    this.progressServ.setProgressState((0 / 4) * 100);
    this.progressServ.setBarType(2);
    this.progressServ.setTotalStages(5);
  }

  checkQuickBookSession() {
    if (this.quickbooks.getSharedSessionData()) {
      if (this.quickbooks.getSharedSessionData().url == '/orders/' + this.orderId + '/view') {
        switch (this.quickbooks.getSharedSessionData().continue) {
          case 'complete_order': this.composeAndComplete(this.quickbooks.getSharedSessionData().data); break;
          case 'download_invoice': {
            if (this.quickbooks.getSharedSessionData().screen == 'info') this.downloadInvoice();
            else if (this.quickbooks.getSharedSessionData().screen == 'review') {
              this.orderService.setCompleteOrderData(this.quickbooks.getSharedSessionData().data);
              this.reviewPackage();
            }
            else return;
          }; break;
          case 'generate_invoice': {
            if (this.quickbooks.getSharedSessionData().screen == 'info') this.generateInvoice();
            else if (this.quickbooks.getSharedSessionData().screen == 'review') this.completeOrder();
            else return;
          }; break;
          default: break;
        }
      }
    }
  }

  checkForPermission() {
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin || Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin) this.isAllowed = true;
    else {
      if (!this.orderDetails.Organization_ID) this.isAllowed = true;
      else {
        var data = {
          Organization_ID: this.orderDetails.Organization_ID,
          Admin_ID: this.auth.getUserId()
        }
        this.orderService.checkOrderPermission(data) 
          .subscribe(allow => {
            this.isAllowed = allow;           
          }, (err) => {
            console.log(err);
          })
      }
    }
  }

  getOrderDetails(orderId) {
    this.orderService.getOrderDetails(orderId)
      .subscribe((details) => {
        if(details.Order_Admin_ID == this.auth.getUserId() && details.Order_Status > CONSTANTS.orderStatus.cancelled
            && details.Order_Status < CONSTANTS.orderStatus.quotecompleted)
          this.orderService.insertOrderView(orderId).subscribe();
        this.orderDetails = details;
        this.orderService.setOrderCompanyId(this.orderDetails.Organization_ID);
        this.orderService.setOrderCompanyName(this.orderDetails.Organization_Name);
        this.getOrderSource();
        if (details) {
          this.timer.push(setTimeout(() => {
            this.getCountyInfoFromGsheet();
            this.getClientInfoFromGsheet();
            this.getRelatedSerarchOrders();
            this.getBillingInfoFromGsheet();
            if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
            this.getETAChangeReasonsById(this.orderId);
            this.getSearchInfo()
            this.getDataDepthInfo()
          }, 4000))
          if (this.orderDetails.Order_ETA && (this.orderDetails.Order_Status != CONSTANTS.orderStatus.received || this.orderDetails.Order_Status != CONSTANTS.orderStatus.assigned)) this.isDate = true;
          else this.isDate = false;
          this.loadOrderPriorityData(details);
          this.loadOrderEscalationData(details);
          // if (!this.checkOrderAuthForSearchMaster(details)) this.router.navigate(['/orders/inprocess']);
          this.checkQuickBookSession();
          if (details.Property_Address_1) this.checkIfPropertyAvailable();
          this.setProgressBarStatus(details['Order_Status'])
          this.buildAddress(this.orderDetails);
          this.constructOrdTrackerLink(this.orderDetails);
          this.getDataFromEnvFile();
          if(this.orderDetails.Co_Borrower_Property_ID) this.buildCoborrowerAddress(this.orderDetails);
          this.billingAddress = details.stripeCharge && details.stripeCharge.billing_details ? this.getBillingAddress(details.stripeCharge.billing_details.address) : '';
          if (details.stripeCharge && Object.keys(details.stripeCharge).length > 0) {
            this.mainChargeRefundedAmount = this.getTotalAmountFromRefunds(details.stripeCharge.refunds);
          }
          this.loadPricingFields(details);
          this.loadDetailFields();
          this.checkForPermission();
          this.getAbstractorData();
          this.getOrderExpenses();
          this.dismissSnackbar();
          this.getOrderBills();
          if (this.orderDetails.Order_Status == CONSTANTS.orderStatus.completed || this.orderDetails.Order_Status == CONSTANTS.orderStatus.cancelled) {
            this.quickbooks.getOrderInvoice(this.orderDetails.Order_ID)
              .subscribe((invoice) => {
                if (invoice) {
                  this.invoicePresent = true;
                  this.invoiceDetailsInfo = invoice;
                  if (this.cancelOrderBool && this.invoicePresent && this.orderDetails.Order_Status !== CONSTANTS.orderStatus.cancelled) this.getCancelOrdInvoice();
                };
              }, (err) => {
                if (err.code) this.openErrorPopup(err.msg);
              })
            this.checkIfTrackerProOrderAndContinue(true);
            this.checkReadyBillingStatus();
          };
        } else {
          if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) this.router.navigate(['/orders/inprocess']);
          else this.router.navigate(['/orders/new']);
        }

      }, (err) => {
        console.log(err);
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        // this.auth.logout();
        // this.router.navigate(['/login']);
      })
  }

  openDenyPermission() {
    this.matDialog.open(
      SuccessComponent,
      {data:{
        message: "You do not have sufficient permissions to view this order. Please get in touch with your team lead to obtain access to this order.",
        title: "Access denied"
      },
      ...this.config.getDialogOptions()}
    )
  }

  dismissSnackbar() {
    if (this.auth.getSnackRef()) {
      let ref = this.auth.getSnackRef()
      if (ref && ref.instance && ref.instance.data) {
        if (ref.instance.data.orderId == this.orderDetails.Order_ID) ref.dismiss();
      }
    }
  }

  loadOrderPriorityData(data) {
    if (data.Order_Priority == CONSTANTS.orderPriority.level2) {
      this.isRushOrder = true;
    } else {
      this.isRushOrder = false;
    }
  }

  userAction(event) {
    this.orderService.setPriorityForRushOrders(this.orderId, this.isRushOrder)
      .subscribe((details) => {
        if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.orderPriority);
        this.isRushOrder = !this.isRushOrder;
      })
  }

  getOrderTags(orderId) {
    this.orderService.getOrderTags(orderId)
      .subscribe((ordTags) => {
        if (ordTags) this.orderTags = ordTags;
        else this.orderTags = [];
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  openModal(title, msg) {
    let disposable = this.matDialog.open(SuccessComponent, {data:{
      title: title,
      message: msg
    }, ...this.config.getDialogOptions()})
  }

  checkOrderAuthForSearchMaster(details) {
    if (this.checkIfSearchMaster()) {
      if (details.Order_Admin_ID == this.auth.getUserId()) return true;
      else return false;
    }
    else return true;
  }

  //Get order instruction like search ins and special ins
  getOrderNotes(orderId) {
    this.orderService.getOrderNotes(orderId)
      .subscribe((notes) => {
        console.log("notes", notes);
        notes.forEach(note => {
          if (note.Instruction_Type == CONSTANTS.instructionType.orderNotes) this.orderNotes = note;
          if (note.Instruction_Type == CONSTANTS.instructionType.orderIns) this.orderIns = note;
        })
      }, (err) => {
        console.log(err);
      })
  }

  //Get all notes of an order
  getAllNotesbyOrdId(orderId) {
    this.orderService.getAllNotesbyOrdId(orderId)
      .subscribe((notes) => {
        for (let i=0; i< notes.length; i++) {
          if (notes[i].Message_Type == CONSTANTS.orderNoteType.internalNote) {
            this.orderInternalNote = notes[i];
            this.orderInternalNote.Authour_Timestamp = this.getAuthourWithTimestam(notes[i]);
            break;
          }
        }
        for (let i=0; i< notes.length; i++) {
          if (notes[i].Message_Type == CONSTANTS.orderNoteType.additionalNote) {
            this.orderAdditionalNote = notes[i];
            this.orderAdditionalNote.Authour_Timestamp = this.getAuthourWithTimestam(notes[i]);
            break;
          }
        }
      }, (err) => {
        console.log(err);
      })
  }

  getAuthourWithTimestam(note) {
    let name = '';
    if(note.User_Full_Name) name += note.User_Full_Name
    if(note.Created_At) {
      // let estShift = momentTimeZone.tz(note.Created_At, 'America/New_York').isDST() ? 4 : 5;
      name += '- '+momentTimeZone.tz(note.Created_At, 'America/New_York').format('MM/DD/YYYY');
      name += ' at '+momentTimeZone.tz(note.Created_At, 'America/New_York').format('hh:mmA') + ' EST';
    }
    return name;
  }

  createOrderNote(msgText, noteType, title) {
    //Allow to remove existing note but don't allow to create empty message
    let allowEmtyMsg = false;
    if(msgText) allowEmtyMsg = true;
    if(!msgText) msgText = '';
    
    let disposable = this.matDialog.open(OrderNoteComponent, {data:{
      title: title,
      messageText: msgText,
      allowEmtyMsg: allowEmtyMsg
    }, ...{ closeByClickingOutside: false }}).afterClosed()
      .subscribe((res) => {
        if (res) {
         if(res.state) {
           let obj = {
            Order_ID:this.orderId,
            Message_Text: res.Message_Text,
            Message_Type: noteType,
            Admin_ID : this.auth.getUserId()
           }
          this.orderService.createOrderNote(obj)
          .subscribe((resp) => {
            this.getAllNotesbyOrdId(this.orderId);
          }, (err) => {
            this.openErrorPopup("Error while creating the order note.");
          })
         }
        }
      });
  }

  //Get order tracker generated status message for custmers
  getCustomGeneratedStatus(orderId) {
    this.orderService.getCustomGeneratedStatus(orderId)
      .subscribe((generatedStatus) => {
        this.orderDetails.Custom_Generated_Note = generatedStatus;
      }, (err) => {
        console.log(err);
      })
  }

  getOrderMessages(orderId) {
    this.orderService.getOrderMessages(orderId)
      .subscribe((messages) => {
        this.messages = messages;
        this.messages.forEach((message) => {
          this.isThreadedMessage(message);
          this.rephraseOldMessage(message);
        })
      }, (err) => {
        console.log(err);
      })
  }

  editOrder() {
    this.router.navigate(['/orders/' + this.orderDetails.Order_ID + '/edit']);
  }

  getTimeline(orderId) {
    this.orderService.getOrderTimeline(orderId)
      .subscribe((timeline) => {
        this.progressServ.setTimeline(timeline);
      }, (err) => {
        console.log(err);
      })
  }

  buildCompAddress(model) {
    this.fullAddress = '';
    if (model.Property_Address_1) this.fullAddress += model.Property_Address_1
    if (model.Property_Address_2) {
      if (this.fullAddress) this.fullAddress += ' '
      this.fullAddress += model.Property_Address_2
    }
    if (model.Property_City) {
      if (this.fullAddress) this.fullAddress += ', '
      this.fullAddress += model.Property_City
    }
    if (model.Property_State_Abbr) {
      if (this.fullAddress) this.fullAddress += ', '
      this.fullAddress += model.Property_State_Abbr
    }
    if (model.Property_ZipCode) {
      if (this.fullAddress) this.fullAddress += ' '
      this.fullAddress += model.Property_ZipCode
    }
    return this.fullAddress;
  }

  checkIfDataAvailable(data) {
    if (data) return data;
    else return '';
  }

  checkIfExternalSearcher(clientRef) {
    if (this.checkIfNotExternalSM()) return clientRef;
    else return '';
  }

  checkPropOwnerName() {
    if (this.orderDetails.Property_First_Name) return this.orderDetails.Property_First_Name;
    else return '';
  }

  copyOrderDetails() {
    var fileId = 'Order#		    : ' + this.checkIfExternalSearcher(this.orderDetails.File_ID);
    var ownerName = 'Owner Name	    : ' + this.checkPropOwnerName();
    var productOrdered = 'Product Type        : ' + this.orderDetails.Product_Description;
    var address = 'Address		    : ' + this.buildCompAddress(this.orderDetails);
    var county = 'County		    : ' + this.orderDetails.Property_County;
    // var client_Name = 'County		    : ' + this.orderDetails.Organazation_Name;

    var gtd = 'GTD		    : ' + '';
    var parcelId = 'Parcel ID	    : ' + this.checkIfDataAvailable(this.orderDetails.Order_Parcel);
    var legal = 'Legal 	            : ' + '';
    var borrower = 'Borrower 	    : ' + this.checkIfDataAvailable(this.orderDetails.Order_Buyer);
    var coBorrower = 'Co-borrower 	    : ' + this.checkIfDataAvailable(this.orderDetails.Order_Co_Buyer);
    var borrowerSSN = 'Borrower SSN last 4 Digits	    : ' + this.checkIfDataAvailable(this.orderDetails.Borrower_SSN);
    var coBorrowerSSN = 'Co-borrower SSN last 4 Digits 	    : ' + this.checkIfDataAvailable(this.orderDetails.Co_Borrower_SSN);
    var loanId = 'Loan ID 	    : ' + this.checkIfDataAvailable(this.orderDetails.Loan_ID);
    var coBorrowerAddress = 'Co-borrower address 	    : ' +this.coborrowerAddress;
    var searchedName = 'Searched Name	    : ' + '';
    var dashedLine = '=========================================================';
    var headerColumnName = 'Doc Name	Rec Date	Book/Page	Instr';

    var dataToCilpboard = fileId + '\n\n'
      + ownerName + '\n\n'
      + productOrdered + '\n\n'
      + address + '\n\n'
      + county + '\n\n'
      + gtd + '\n\n'
      + parcelId + '\n\n'
      + legal + '\n\n'
      + borrower + '\n\n'
      + coBorrower + '\n\n'
      + borrowerSSN + '\n\n'
      + coBorrowerSSN + '\n\n'
      + coBorrowerAddress + '\n\n'   
      + loanId + '\n\n'   
      + searchedName + '\n\n'
      + dashedLine + '\n\n'
      + headerColumnName + '\n\n'
      + dashedLine + '\n\n'
    return dataToCilpboard;
  }

  copyToClipBoard() {
    var sl = '';
    var receivedDate = '';
    var orderNo = this.checkIfExternalSearcher(this.orderDetails.File_ID);
    var pippinInvoice = this.orderDetails.Order_ID;
    var client = '';
    var customer = '';
    var propOwner = this.checkPropOwnerName();
    var propAddress = this.buildCompAddress(this.orderDetails);
    var state = this.orderDetails.Property_State_Abbr;
    var county = this.orderDetails.Property_County;
    let borrower= '';
    let coBorrower = '';
    let coBorrowerAddress = '';
    let borrowerSSN = '';
    let coBorrowerSSN = '';
    let loanId = '';
    // var client = this.orderDetails.Organazation_Name;

    var searchType = this.orderDetails.Product_Description;
    var clientETA = '';
    var pippinETA = '';
    var status = '';
    var completedDate = '';

    if (this.orderDetails.Organization_ID && !this.checkIfSearchMaster()) {
      if (this.orderDetails.Organization_ID == this.config.getQualiaOrgId()) this.isQualiaOrder = true;
      client = this.orderDetails.Organization_Name;
    }
    if (!this.checkIfSearchMaster()) {
      if (this.isQualiaOrder || !this.orderDetails.Organization_ID) customer = this.orderDetails.User_First_Name + ' ' + this.orderDetails.User_Last_Name;
      else customer = this.orderDetails.Organization_Name;
    }
    if (this.orderDetails.Order_Status == CONSTANTS.orderStatus.received) {
      status = 'RECEIVED';
    } else if (this.orderDetails.Order_Status == CONSTANTS.orderStatus.cancelled) {
      status = 'CANCELLED';
    } else if (this.orderDetails.Order_Status == CONSTANTS.orderStatus.assigned) {
      status = 'ASSIGNED';
    } else if (this.orderDetails.Order_Status == CONSTANTS.orderStatus.confirmed) {
      status = 'CONFIRMED';
    } else if (this.orderDetails.Order_Status == CONSTANTS.orderStatus.processing) {
      status = 'PROCESSING';
    } else if (this.orderDetails.Order_Status == CONSTANTS.orderStatus.completed) {
      status = 'COMPLETED';
    }
    if (this.orderDetails.Order_Creation_Date) receivedDate = new Date(this.orderDetails.Order_Creation_Date).toLocaleDateString("en-US", { timeZone: "America/New_York" });

    if (this.orderDetails.Order_Completion_Date && this.orderDetails.Order_Status == CONSTANTS.orderStatus.completed) {
      completedDate = new Date(this.orderDetails.Order_Completion_Date).toLocaleString("en-US", { timeZone: "America/New_York" });
      completedDate = completedDate.replace(',', '');
    }
    if (this.orderDetails.Order_ETA) {
      clientETA = new Date(this.orderDetails.Order_ETA).toLocaleString("en-US", { timeZone: "America/New_York" });
      clientETA = clientETA.replace(',', '');
    }

    if(this.orderDetails.Co_Borrower_Property_ID) coBorrowerAddress = this.coborrowerAddress;
    if(this.orderDetails.Borrower_SSN) borrowerSSN = this.orderDetails.Borrower_SSN;
    if(this.orderDetails.Co_Borrower_SSN) coBorrowerSSN = this.orderDetails.Co_Borrower_SSN;
    if(this.orderDetails.Loan_ID) loanId = this.orderDetails.Loan_ID;
    if(this.orderDetails.Order_Buyer) borrower = this.orderDetails.Order_Buyer;
    if(this.orderDetails.Order_Co_Buyer) coBorrower = this.orderDetails.Order_Co_Buyer;    
    var dataToClip = sl + '\t'
      + receivedDate + '\t'
      + orderNo + '\t'
      + pippinInvoice + '\t'
      + client + '\t'
      + customer + '\t'
      + propOwner + '\t'
      + propAddress + '\t'
      + state + '\t'
      + county + '\t'
      + searchType + '\t'
      + clientETA + '\t' + '\t' + '\t' + '\t' + '\t' + '\t' + '\t' + '\t' + '\t'
      + status + '\t' + '\t' + '\t' + '\t'
      + completedDate + '\t'
      + borrower + '\t'      
      + borrowerSSN + '\t' 
      + coBorrower + '\t'
      + coBorrowerSSN + '\t'
      + coBorrowerAddress + '\t'
      + loanId + '\t'
    return dataToClip;
  }

  constructAddress() {
    var address = '';
    if (this.orderDetails.Property_Address_1) address += this.orderDetails.Property_Address_1;
    // if (this.orderDetails.Property_Address_2) {
    //   if (address) address += ', ';
    //   if (address) address += this.orderDetails.Property_Address_2
    // }
    // if (this.orderDetails.Property_City) {
    //   if (address) address += ', ';
    //   if (address) address += this.orderDetails.Property_City
    // }
    // if (this.orderDetails.Property_State_Abbr) {
    //   if (address) address += ' ';
    //   address += this.orderDetails.Property_State_Abbr
    // }
    // if (this.orderDetails.Property_ZipCode) {
    //   if (address) address += ' ';
    //   address += this.orderDetails.Property_ZipCode
    // }
    return address;
  }

  getAbstractorFee(absId) {
    this.abstractorService.getAbstractorPricingById(absId).subscribe((absPricings) => { this.absPricings = absPricings; })
  }

  afterCopy() {
    this.copyMessage = "Order details copied to clipboard(Excel format).";
    this.timer.push(setTimeout(() => this.copyMessage = "", 3000));
  }

  afterOrdDetailsCopy() {
    this.copyMessage = "Order details copied(Search note format).";
    this.timer.push(setTimeout(() => this.copyMessage = "", 3000));
  }

  afterTextCopy() {
    this.userMsg.setSuccessCopyMsg("Text copied successfully");
  }

  getOrderProducts(orderId) {
    this.orderService.getCustomerOrderProducts(orderId)
      .subscribe((products) => {
        //console.log("products", products);
        this.orderProducts = products;
      }, (err) => {
        //console.log(err);
      })
  }


  setProgressBarStatus(status) {
    var stage = this.getStage(status);
    //console.log("Received status", status);
    this.progressServ.setStage(stage);
    if (stage == 4) this.progressServ.setProgressState((((stage - 1) / 4) * 100) - 1);
    else this.progressServ.setProgressState(((stage - 1) / 4) * 100);
  }

  getStage(status) {
    if (status < CONSTANTS.orderStatus.assigned) return 1;
    else if (status < CONSTANTS.orderStatus.confirmed) return 2;
    else if (status == CONSTANTS.orderStatus.confirmed) return 3;
    else if (status < CONSTANTS.orderStatus.completed) return 4;
    else return 5
  }

  isPricingDone() {
    if (this.orderProducts.length && this.orderDetails.Order_Status > CONSTANTS.orderStatus.received) return true
    return null;
  }

  getBaseProduct() {
    if (this.orderProducts.length) return this.orderProducts.filter((product) => (product.Order_Product_Status == 1 || product.Order_Product_Status == 0))
    return null;
  }

  getSubProducts() {
    if (this.orderProducts.length) return this.orderProducts.filter((product) => product.Order_Product_Status != 1);
    return null;
  }

  openMessageInterface() {
    let disposable = this.matDialog.open(MessageComponent, {data:{
      title: 'Send Message',
      msgSendType: CONSTANTS.messageSendStatus.orderMessage
    }, ...{ closeByClickingOutside: false }}).afterClosed()
      .subscribe((res) => {
        if (res) {
          if (!res.checked) this.sendMessage(res.messageText)
          else this.sendInternalMessage(res.messageText, CONSTANTS.messageType.internalMessage)
        }
      });
  }

  sendInternalMessage(text, msgType) {
    var data = {
      Message_Text: text,
      Message_Type: msgType
    }
    this.orderService.sendInternalMessage(this.orderId, data, this.auth.getUserId())
      .subscribe((data) => {
        console.log(data);
        this.getOrderMessages(this.orderId);
      }
        , (err) => {
          console.log(err);
          this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
        })
  }

  sendMessage(text) {
    var data = {
      Message_Text: text,
      Customer_ID: this.orderDetails.Customer_ID
    }
    this.orderService.sendMessage(this.orderId, data, this.auth.getUserId())
      .subscribe((data) => {
        console.log(data);
        this.getOrderMessages(this.orderId);
        // this.matDialog.open(SuccessComponent, {
        //   title: 'Success',
        //   message: 'Message has been sent successfully.'
        // })
        //   .subscribe((res) => {
        //     console.log("Closed", res)
        //   });
      }, (err) => {
        console.log(err);
        this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
      })
  }

  buildAddress(model) {
    this.completeAddress = '';
    if (model.Property_Address_1) this.completeAddress += model.Property_Address_1
    if (model.Property_Address_2) {
      if (this.completeAddress) this.completeAddress += '\n'
      this.completeAddress += model.Property_Address_2
    }
    if (model.Property_City) {
      if (this.completeAddress) this.completeAddress += '\n'
      this.completeAddress += model.Property_City
    }
    if (model.Property_State_Abbr) {
      if (this.completeAddress) this.completeAddress += ', '
      this.completeAddress += model.Property_State_Abbr
    }
    if (model.Property_ZipCode) {
      if (this.completeAddress) this.completeAddress += ' '
      this.completeAddress += model.Property_ZipCode
    }
    this.constructLink();
  }

  buildCoborrowerAddress(model) {
    this.coborrowerAddress = '';
    if (model.Co_Borrower_Property_Address_1) this.coborrowerAddress += model.Co_Borrower_Property_Address_1;    
    if (model.Co_Borrower_Property_City) {
      if (this.coborrowerAddress) this.coborrowerAddress += '\n'
      this.coborrowerAddress += model.Co_Borrower_Property_City
    }
    if (model.Co_Borrower_Property_State_Abbr) {
      if (this.coborrowerAddress) this.coborrowerAddress += ', '
      this.coborrowerAddress += model.Co_Borrower_Property_State_Abbr
    }
    if (model.Co_Borrower_Property_ZipCode) {
      if (this.coborrowerAddress) this.coborrowerAddress += ' '
      this.coborrowerAddress += model.Co_Borrower_Property_ZipCode;
    }
  }

  getBillingAddress(billingAddress) {
    const ba = billingAddress;
    let address = '';
    address += `${ba['line1']}\n`;
    address += ba['line2'] ? ba['line2'] + '\n' : '';
    address += `${ba['city']}, `;
    address += `${ba['state']} `;
    address += `${ba['postal_code']}`;
    return address;
  }

  getTotalAmountFromRefunds(refunds) {
    if (refunds) {
      let total = 0;
      for (const refund of refunds.data) {
        total += refund.amount;
      }
      return total / 100;
    }
    else return;
  }

  getDocs(orderId) {
    // this.orderService.getOrderRelatedDocsTags(orderId)
    this.orderService.getOrderDocuments(orderId)
      .subscribe((data) => {
        this.documents = data;
        this.clientDocs = this.documents.filter((x) => x.Document_Type == CONSTANTS.orderDocumentType.clientDocs);//Client Type
        this.adminDocs = this.documents.filter((x) => x.Document_Type == CONSTANTS.orderDocumentType.adminDocs).map((item) => {
          if(item.Doc_Cat_ID){
            let matchedItem =  this.documentCategories.find((cat) => item.Doc_Cat_ID == cat.Id);
            item.Category = matchedItem ? matchedItem.Category : 'Click to categorize';
          }
          else item.Category = 'Click to categorize'
          return item;
        });//Admin Type
        this.internalDocs = this.documents.filter((x) => x.Document_Type == CONSTANTS.orderDocumentType.internalDocs).map((item) => {
          if(item.Doc_Cat_ID){
            let matchedItem =  this.documentCategories.find((cat) => item.Doc_Cat_ID == cat.Id);
            item.Category = matchedItem ? matchedItem.Category : 'Click to categorize';
          }
          else item.Category = 'Click to categorize'
          return item;
        });//Internal Type        
        if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
        if (this.checkForRoleAdmin) {
          // this.getOrderDetails(this.orderId);
          if (this.cancelOrderBool) this.cancelPayment = true;
        }
      }, (err) => {
        console.log(err);
      })
  }

  getOrdDocumentTagName(document) {
    if (document.Document_Tag_ID == CONSTANTS.orderDocumentsTags.otherTagDocs) {
      let otherTagDocs = this.checkDocumentTags(document)
      return otherTagDocs + "-" + document.Document_Tag_Other;
    } else return this.checkDocumentTags(document);
  }

  checkDocumentTags(document) {
    let orderDocsTagIndex: any
    if (document && document.Document_Tag_ID && this.documentsTag.length > 0) {
      orderDocsTagIndex = this.documentsTag.findIndex((x) => x.Document_Tag_ID == document.Document_Tag_ID);
      return this.documentsTag[orderDocsTagIndex].Document_Tag_Name;
    } else return null;
  }
  downloadFile(filename, orderDocId) {
      this.fileService.download(orderDocId)
      .subscribe(
        (userFile) => {
          if (userFile) {
            var link = document.createElement('a');
            document.body.appendChild(link);
            link.download = filename;
            link.href = userFile.Link;
            link.click();
            document.body.removeChild(link);
          }
        },
        (err) => {
          console.log(err);
          this.openErrorPopup(CONSTANTS.apiErrors.docdownload);
        })
       
  }

  previewFile(orderDocId) {
    this.fileService.preview(orderDocId)
      .subscribe(
        (userFile) => {
          var link = document.createElement('a');
          document.body.appendChild(link);
          link.target = '_blank';
          link.href = userFile.Link;
          link.click();
          document.body.removeChild(link);
        },
        (err) => {
          console.log(err);
          this.openErrorPopup(CONSTANTS.apiErrors.docdownload);
        })
  }

  getByteArray(file) {
    // var byteCharacters = atob(file);
    // var returnArray = [];
    // var byteNumbers = new Array(byteCharacters.length);
    // for (var i = 0; i < byteCharacters.length; i++) {
    //   byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }
    // returnArray.push(new Uint8Array(byteNumbers));
    // return returnArray;
    var sliceSize = 512;

    var byteCharacters = atob(file);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    return byteArrays;
  }




  deleteFile(filename, orderDocId) {
    this.matDialog.open(ConfirmComponent, {data:{
      title: 'Delete Document',
      message: 'Are you sure you want to delete ' + filename + ' ?'
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res) {
          this.fileService.delete(orderDocId, this.auth.getUserId())
            .subscribe(
              (data) => {
                console.log("Data", data);
                this.getDocs(this.orderId);
                if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
              },
              (err) => {
                console.log(err);
                this.openErrorPopup(CONSTANTS.apiErrors.docdelete);
              })
        } else {
          console.log("Closed", res)
        }
      });
  }

  cancelOrder() {
    this.openCancelDialog().afterClosed()
      .subscribe((res) => {
        // if (res) {
        if (res && res.state) {
          console.log("res", res);
          this.cancelPayment = false;
          this.orderService.cancelOrder(this.orderId, res.Message_Text, this.orderDetails.Customer_ID, res.Order_Cancellation_Reason_Type_ID,res.cancelledMail)
            .subscribe((data) => {
              console.log(data);
              this.cancelOrderBool = true;
              if (this.orderDetails.Order_Internal_Due_Time) this.updateCurrentTaskDueDate(this.orderId)
              this.getOrderDetails(this.orderId);
              this.getOrderMessages(this.orderId);
              this.getSubscriptionDetails(this.orderId);
              this.getOrderTags(this.orderId);
              if(!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
              this.checkReadyBillingStatus();
            }, (err) => {
              console.log(err);
              this.openErrorPopup(CONSTANTS.apiErrors.ordercancel);
            })
        } else {
          console.log("Dialog closed")
        }
      })
  }

  updateCurrentTaskDueDate(orderId) {
    this.orderService.updateCurrentTaskDue(orderId)
      .subscribe(() => { })
  }


  changeOrderStatus() {
    var currentOrderStatus = this.orderDetails.Order_Status;
    var orderStatusList = []
    var orderStatus = {};
    if(this.orderDetails && this.orderDetails.Order_ID){
      this.getAllOrderStatus(this.orderDetails.Order_ID);
    }
    if(currentOrderStatus != CONSTANTS.orderStatus.received){
      orderStatus['text'] = "Received";
      orderStatus['val'] = CONSTANTS.orderStatus.received;
      orderStatusList.push(orderStatus);
    }
    if (currentOrderStatus > CONSTANTS.orderStatus.assigned) {
      var orderStatus1 = {};
      orderStatus1['text'] = "Assigned";
      orderStatus1['val'] = CONSTANTS.orderStatus.assigned;
      orderStatusList.push(orderStatus1);
    }
    if (currentOrderStatus > CONSTANTS.orderStatus.confirmed) {
      var orderStatus2 = {};
      orderStatus2['text'] = "Confirmed";
      orderStatus2['val'] = CONSTANTS.orderStatus.confirmed;
      orderStatusList.push(orderStatus2);
    }
    if (currentOrderStatus > CONSTANTS.orderStatus.processing) {
      var orderStatus3 = {};
      orderStatus3['text'] = "Processing";
      orderStatus3['val'] = CONSTANTS.orderStatus.processing;
      orderStatusList.push(orderStatus3);
    }
    if(this.checkIfAlreadyCompleted() && currentOrderStatus != CONSTANTS.orderStatus.completed){
      var orderStatus5 = {};
      orderStatus5['text'] = "Completed";
      orderStatus5['val'] = CONSTANTS.orderStatus.completed;
      orderStatusList.push(orderStatus5);
    }  
    let disposable = this.matDialog.open(ChangeOrderStatusComponent, {data:{
      title: 'Change Order Status',
      message: orderStatusList
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        var data = {};
        if (res) {
          if(res == 50){
            data['flag'] = 'QCKCOMP'
            data['Order_ID'] = this.orderId
            data['Customer_ID'] = this.orderDetails.Customer_ID
          }
          this.orderService.changeOrderStatus(this.orderId, res, data)
            .subscribe((data) => {
              console.log(data);
              this.getOrderTags(this.orderId);
              this.initializeProgressBar();
              this.getOrderDetails(this.orderId);
              this.getOrderMessages(this.orderId);
              this.getTimeline(this.orderId);
              this.getSubscriptionDetails(this.orderId);
              if(!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
              this.checkReadyBillingStatus();
            }, (err) => {
              console.log(err);
            })
        } else {
          console.log("Dialog closed")
        }
      });
  }

  getAdminDocuments() {
    return this.documents.filter(function (document) {
      return document.Document_Type == CONSTANTS.orderDocumentType.adminDocs;
    });
  }

  completeOrder() {
    let disposable = this.matDialog.open(CompleteOrderComponent, {data:{
      title: this.getTitleForComment(),
      message: { docs: this.getAdminDocuments().length, abstrator: this.assignedAbstractor, assignment: this.orderDetails }
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res) {
          if (res.state) this.reviewPackage();
          else this.dragDropDocs();
          // else this.orderCompleteChecklist();
        }
        else this.orderService.resetCompleteOrderData();
      })
  }

  getTitleForComment() {
    // if (this.orderDetails.Abstrator_ID) return 'Completion (3 of 4) - Comments & Ratings';
    // else return 'Completion (3 of 4) - Comments';
    if (this.orderDetails.Abstrator_ID) return 'Completion (2 of 3) - Comments & Ratings';
    else return 'Completion (2 of 3) - Comments';
  }

  reviewPackage() {
    this.matDialog.open(ReviewCompletePackageComponent, {data:{
      message: { Order: this.orderDetails },
      // title: 'Completion (4 of 4) - Review complete package'
      title: 'Completion (3 of 3) - Review complete package'
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res) {
          if (res.state) {
            var completeData = {
              Order_ID: this.orderId,
              Titles_ID: this.orderDetails.Titles_ID,
              Customer_ID: this.orderDetails.Customer_ID,
              Comment: res.comment,
              Is_Abstractor_Assigned: false,
              Invoice_Update: res.Invoice_Update,
              Invoice_Details: res.invoice,
              Mail_Body: res.mail_text,
              Open_Billing_Popup: res.Open_Billing_Popup,
              Order_Check_List: this.orderDetails.checkListData,
              isPartial: this.orderDetails.isPartial,
              TrackerPro_DocID: this.getDeliverableIds()
            }
            if (this.assignedAbstractor['User_ID']) {
              completeData['notes'] = res.notes;
              completeData['Order_Rating'] = res.rating;
              completeData['Is_Abstractor_Assigned'] = true;
            }
            this.composeAndComplete(completeData);
          }
          else {
            this.completeOrder();
          }
        }
        else this.orderService.resetCompleteOrderData();
      });
  }

  checkReadyBillingStatus(){
    this.orderService.checkIfOrderReadyForBilling(this.orderId)
    .subscribe(
      (data) => { console.log() ;this.isReadyForBilling = data.status },
      (err) => {})
  }

  composeAndComplete(completeData) {
    this.orderService.completeOrder(completeData)
      .subscribe((data) => {
        if (data) {
          if (data.body) this.handleSubmitOrderResponse(data.body);
          if (this.orderDetails.Order_Internal_Due_Time) this.updateCurrentTaskDueDate(this.orderId)
          this.checkReadyBillingStatus();
          this.getOrderDetails(this.orderId);
          this.getOrderMessages(this.orderId);
          this.getDocs(this.orderId);
          this.getTimeline(this.orderId);
          this.getOrderTags(this.orderId);
          this.getSubscriptionDetails(this.orderId);
          if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId)
          if (completeData.Open_Billing_Popup) this.router.navigate(['/edit-order-expense/' + this.orderId], { queryParams: { tab: 'bills' } })
        }
      }, (err) => {
        if (err) {
          if (err.code && err.code.includes('PTQ')) {
            this.openErrorPopup(err.msg);
          } else if (err.code == 'PTOR06') {
            this.openErrorPopup(CONSTANTS.apiErrors.orderCanNotComplete);
          } else {
            this.openErrorPopup(CONSTANTS.apiErrors.ordercomplete);
          }
        }
      })
  }

  dragDropDocs() {
    var allData = {
      docs: this.documents.filter((docType) => docType.Document_Type != CONSTANTS.orderDocumentType.abstractorDocs &&
        docType.Document_Type != CONSTANTS.orderDocumentType.clientDocs &&
        docType.Document_Type != CONSTANTS.orderDocumentType.internalDocs),
      orderId: this.orderId,
      propertyId: this.orderDetails.Property_ID,
      // documentTags: this.documentsTag,
      isTrackerProOrder: this.isTrackerProOrder
    };
    let disposable = this.matDialog.open(DragDropDocsComponent, {data:{
      // title: 'Completion (1 of 4) - Documents review',
      title: 'Completion (1 of 3) - Documents review',
      message: allData
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res && res.value == 'next') {
          // this.orderService.getOrderRelatedDocsTags(this.orderId).subscribe((data) => {
          this.orderService.getOrderDocuments(this.orderId).subscribe((data) => {
            this.documents = data;
            this.clientDocs = this.documents.filter((x) => x.Document_Type == CONSTANTS.orderDocumentType.clientDocs);
            this.adminDocs = this.documents.filter((x) => x.Document_Type == CONSTANTS.orderDocumentType.adminDocs).map((item) => {
              if(item.Doc_Cat_ID){
                let matchedItem =  this.documentCategories.find((cat) => item.Doc_Cat_ID == cat.Id);
                item.Category = matchedItem ? matchedItem.Category : 'Click to categorize';
              }
              else item.Category = 'Click to categorize'
              return item;
            });
            this.internalDocs = this.documents.filter((x) => x.Document_Type == CONSTANTS.orderDocumentType.internalDocs).map((item) => {
              if(item.Doc_Cat_ID){
                let matchedItem =  this.documentCategories.find((cat) => item.Doc_Cat_ID == cat.Id);
                item.Category = matchedItem ? matchedItem.Category : 'Click to categorize';
              }
              else item.Category = 'Click to categorize'
              return item;
            });
            if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
            // this.orderCompleteChecklist();
            this.orderDetails['checkListData'] = [];
            this.orderDetails['isPartial'] = false;
            this.completeOrder();

          }, (err) => {
          })
        } else {
          this.getDocs(this.orderId);
          this.orderService.resetCompleteOrderData();
        }
      })
  }


  orderCompleteChecklist() {
    let checkListObj = {
      title: 'Completion (2 of 4) - Order Checklist',
      orderDetails: this.orderDetails
    }
    if (this.orderDetails && this.orderDetails.checkListData) checkListObj['checklistData'] = this.orderDetails.checkListData
    let disposable = this.matDialog.open(OrderChecklistComponent, {data:checkListObj,
      ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res && res.state) {
          this.orderDetails['checkListData'] = res.retObj.checkListObj;
          this.orderDetails['isPartial'] = res.retObj.isPartial;
          if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderDetails.Order_ID);
          this.completeOrder();
        } else if (res && !res.state) {
          this.orderService.getOrderDocuments(this.orderId).subscribe((data) => {
            this.documents = data;
            this.clientDocs = this.documents.filter((x) => x.Document_Type == CONSTANTS.orderDocumentType.clientDocs);
            this.adminDocs = this.documents.filter((x) => x.Document_Type == CONSTANTS.orderDocumentType.adminDocs).map((item) => {
              if(item.Doc_Cat_ID){
                let matchedItem =  this.documentCategories.find((cat) => item.Doc_Cat_ID == cat.Id);
                item.Category = matchedItem ? matchedItem.Category : 'Click to categorize';
              }
              else item.Category = 'Click to categorize'
              return item;
            });
            this.internalDocs = this.documents.filter((x) => x.Document_Type == CONSTANTS.orderDocumentType.internalDocs).map((item) => {
              if(item.Doc_Cat_ID){
                let matchedItem =  this.documentCategories.find((cat) => item.Doc_Cat_ID == cat.Id);
                item.Category = matchedItem ? matchedItem.Category : 'Click to categorize';
              }
              else item.Category = 'Click to categorize'
              return item;
            });
            this.dragDropDocs();
          }, (err) => {
          })

        }
      })
  }


  verifyToComplete() {
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) this.SMCompleteOrder()
    else if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.admin || Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin || Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin) this.dragDropDocs()
  }

  SMCompleteOrder() {
    this.rejectOrder(false);
  }

  openCompleteDialog() {
    return this.matDialog.open(CompleteOrderComponent, {data:{}, ...this.config.getDialogOptions()})
  }

  openCancelDialog() {
    return this.matDialog.open(CancelOrderComponent, {data:{}, ...this.config.getDialogOptions()})
  }

  openCancelmailDialog() {
    return this.matDialog.open(CancelOrderMailComponent, {data:{
      message: this.orderDetails
    }, ...this.config.getDialogOptions()})
  }

  openErrorReportDialog() {
    return this.matDialog.open(ErrorReportComponent, {data:{ message: { Order_ID: this.orderId } }, ...this.config.getDialogOptions()})
  }

  confirmOrder() {
    if("Order_Customer_File_Number" in this.orderDetails){
      if(this.orderDetails.Order_Customer_File_Number !== null){
        if(this.orderDetails.Order_Customer_File_Number.includes("-SitusAMC")){
          this.acceptOrRejectSitusAMCOrder(this.orderDetails);
        }else{
          this.validateOrderAddrModal(this.orderDetails);
        }
      }else{
        this.validateOrderAddrModal(this.orderDetails);
      }
    }else{
      this.validateOrderAddrModal(this.orderDetails);
    }
  }

  acceptOrder() {
    let data = this.getOrganizationData();
    // this.userService.getAllOrgAdminsToOrderAssign(data).subscribe((adminData) => {
    //   this.userService.getAllOrgSearchMasterToOrderAsign(data).subscribe((searchData) => {
      this.userService.getAllOrgBackOfficeAgentsToOrderAssign(data).subscribe((adminData) => {
        let disposable = this.matDialog.open(AssignComponent, {data:{
          title: 'Assign to process',
          message: {
            Order_ETA: this.orderDetails.Order_ETA,
            admins: adminData['admins'],
            Search_Masters: adminData['searchers'],
            Current_Admin: this.orderDetails.Order_Admin_ID
          },
          listOfHolidays:this.holidayList
        }, ...this.config.getDialogOptions()}).afterClosed()
          .subscribe((res) => {
            if (res) {
              let msgType;
              if (res.msgText) msgType = CONSTANTS.messageType.internalNotes
              else {
                res.msgText = ""
                msgType = CONSTANTS.messageType.internalNotes;
              }

              if(res.admin && res.getTimeToCompl){
              
              if (this.isETAChanged(res.Order_ETA) && this.orderDetails.Order_Status >= CONSTANTS.orderStatus.confirmed) {
                this.openETAChangeMsgInterface(this.orderId, res.admin, res.Order_ETA, res.msgText, msgType, res.getTimeToCompl, res.Order_ETA_Range)
              } else {
                this.acceptOrderByAdmin(this.orderId, res.admin, res.Order_ETA, res.msgText, msgType, res.getTimeToCompl, res.Order_ETA_Range, '', '');
              }

            }else{
              
              this.orderService.unassignOrder(this.orderId,res.msgText,msgType,this.orderDetails.Order_Status).subscribe((data)=>{
                
                }, (err) => {
                  this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
              })

            }
            }
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
      })
    })
  }

  acceptOrderByAdmin(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, orderETAChangeReason, Order_ETA_Change_Reason_Type_ID) {
    this.orderService.initOrderProcess(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, orderETAChangeReason, Order_ETA_Change_Reason_Type_ID)
      .subscribe((data) => {
        this.getOrderDetails(this.orderId);
        this.getOrderMessages(this.orderId);
        this.getTimeline(this.orderId);
        if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
        this.getETAChangeReasonsById(this.orderId);
      }, (err) => {
        console.log(err);
        this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
      })
  }

  isETAChanged(eta) {
    var oldETA;
    var newETA;
    // if(this.orderDetails.Order_ETA) oldETA = moment(this.orderDetails.Order_ETA).add(new Date().getTimezoneOffset()/60, 'hours').toISOString();
    // else oldETA = null;
    // if(eta) newETA = moment(eta).toISOString();
    // else newETA = null;
    if (this.orderDetails.Order_ETA) {
      let estShift = momentTimeZone.tz(this.orderDetails.Order_ETA, 'America/New_York').isDST() ? 4 : 5;
      oldETA = momentTimeZone.tz(this.orderDetails.Order_ETA, 'America/New_York').add(estShift, 'hours').format('MM-DD-YYYYTHH:mm');
    } else oldETA = null;
    if (eta) newETA = momentTimeZone.tz(eta, 'America/New_York').format('MM-DD-YYYYTHH:mm');
    else newETA = null;
    if (oldETA != newETA) return true;
    else return false;
  }

  openETAChangeMsgInterface(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange) {
    let disposable = this.matDialog.open(EtaChangeReasonComponent, {data:{
    }, ...{ closeByClickingOutside: false }}).afterClosed()
      .subscribe((res) => {
        if (res.state) {
          this.acceptOrderByAdmin(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, res.Message_Text, res.Order_ETA_Change_Reason_Type_ID);
        }
      });
  }

  assignOrder() {
    let data = this.getOrganizationData();
    this.userService.getAllOrgBackOfficeAgentsToOrderAssign(data).subscribe((adminData) => {
    // this.userService.getAllOrgAdminsToOrderAssign(data).subscribe((adminData) => {
    //   this.userService.getAllOrgSearchMasterToOrderAsign(data).subscribe((searchData) => {
        let disposable = this.matDialog.open(ScreenOrderComponent, {data:{
          title: 'Screen order',
          message: {
            admins: adminData['admins'],
            Search_Masters: adminData['searchers'],
            Current_Admin: this.orderDetails.Order_Admin_ID
          }
        }, ...this.config.getDialogOptions()}).afterClosed()
          .subscribe((res) => {
            if (res) {
              let msgType;
              if (res.msgText) msgType = CONSTANTS.messageType.internalNotes
              else {
                res.msgText = ""
                msgType = CONSTANTS.messageType.internalNotes;
              }

              this.orderService.screenOrder(this.orderId, res.admin, res.msgText, msgType, res.getTimeToCompl)
                .subscribe((data) => {
                  this.getOrderDetails(this.orderId);
                  this.getOrderMessages(this.orderId);
                  this.getTimeline(this.orderId);
                  if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
                }, (err) => {
                  console.log(err);
                  this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                })
            }
          });
    //   }, (err) => { console.log(err); })
    })
  }

  loadPricingFields(orderDetail) {
    if (orderDetail.Order_Custom_Price_Tax == null) this.orderDetails.Order_Custom_Price_Tax = 0
    if (document.getElementById('Orig_Total_Price')) document.getElementById('Orig_Total_Price').parentElement.classList.remove('is-empty')
    if (document.getElementById('Total_Price')) document.getElementById('Total_Price').parentElement.classList.remove('is-empty')
    if (document.getElementById('Total_Tax_Price')) document.getElementById('Total_Tax_Price').parentElement.classList.remove('is-empty')
  }

  goBack() {
    // console.log(this.routingState.getPrevUrl(),"============prev url=============");
    
    // console.log("last Loc: " + this.routingState.getPrevUrl())
    let arrayHistory = this.routingState.getHistory()
    let startingIndex = _.findIndex(arrayHistory, (x) => x.includes('orders/' + this.orderDetails.Order_ID + '/view'));
    let lastIndex = _.findLastIndex(arrayHistory, (x) => x.includes('orders/' + this.orderDetails.Order_ID + '/view'))
    arrayHistory.splice(startingIndex, lastIndex + 1)
    let lastLoc
    if (arrayHistory.length) {lastLoc = arrayHistory[arrayHistory.length - 1];}
    else {
      if (this.checkIfSearchMaster()) {return this.router.navigate(['/orders/inprocess'])}
      else {return this.router.navigate(['/orders/new'])}
    }
    // console.log(this.routingState.getPrevUrl(),"===============bbb")
    if (this.routingState.getPrevUrl() && (
      this.routingState.getPrevUrl().includes("accept") ||
      this.routingState.getPrevUrl().includes("login") ||
      this.routingState.getPrevUrl().includes("auth/success") ||
      this.routingState.getPrevUrl().includes("view") ||
      this.routingState.getPrevUrl().includes("customer/orders/new/step2") ||
      this.routingState.getPrevUrl().includes("customer/orders/new/step3") ||
      this.routingState.getPrevUrl().includes("report-builder")
    )) {
      // console.log("step1")
      if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster)return this.router.navigate(['/orders/inprocess'])
      else return this.router.navigate(['/orders/new'])
    }
    else if (lastLoc) {
      if (lastLoc.indexOf("?") > 0) {
        let lastLocation = lastLoc.slice(0, lastLoc.indexOf("?"));
        return this.router.navigate([lastLocation]);
      } else {
        if(lastLoc.includes("report-builder") || lastLoc.includes("edit")){
          // console.log("step2")
          if(this.routingState.getHistory().length<3){
           return this.router.navigate(['/index'])
          }else {return this.location.back();}
        }
       return this.router.navigate([lastLoc])
      }
    }
  }

  loadDetailFields() {
    if (document.getElementById('Order_Number')) document.getElementById('Order_Number').parentElement.classList.remove('is-empty');
    if (document.getElementById('Order_Buyer')) document.getElementById('Order_Buyer').parentElement.classList.remove('is-empty');
    if (document.getElementById('Order_Co_Buyer')) document.getElementById('Order_Co_Buyer').parentElement.classList.remove('is-empty');
    if (document.getElementById('Order_Seller')) document.getElementById('Order_Seller').parentElement.classList.remove('is-empty');
    if (document.getElementById('Order_Co_Seller')) document.getElementById('Order_Co_Seller').parentElement.classList.remove('is-empty');
    if (document.getElementById('Order_Loan')) document.getElementById('Order_Loan').parentElement.classList.remove('is-empty');
    if (document.getElementById('Order_Parcel')) document.getElementById('Order_Parcel').parentElement.classList.remove('is-empty');
    if (document.getElementById('Order_Book')) document.getElementById('Order_Book').parentElement.classList.remove('is-empty');
    if (document.getElementById('Order_Page')) document.getElementById('Order_Page').parentElement.classList.remove('is-empty');
    if (document.getElementById('Address')) document.getElementById('Address').parentElement.classList.remove('is-empty');
    if (document.getElementById('Order_Mortgage_Amount')) document.getElementById('Order_Mortgage_Amount').parentElement.classList.remove('is-empty');
    if (document.getElementById('Order_Mortgage_Date')) document.getElementById('Order_Mortgage_Date').parentElement.classList.remove('is-empty');
    if (document.getElementById('Property_Order_Notes')) document.getElementById('Property_Order_Notes').parentElement.classList.remove('is-empty');
    if (document.getElementById('Property_Order_Ins')) document.getElementById('Property_Order_Ins').parentElement.classList.remove('is-empty');
    if (document.getElementById('Company_Name')) document.getElementById('Company_Name').parentElement.classList.remove('is-empty');
    if (document.getElementById('User_First_Name')) document.getElementById('User_First_Name').parentElement.classList.remove('is-empty');

    if (document.getElementById('Email_Address')) document.getElementById('Email_Address').parentElement.classList.remove('is-empty');

    if (document.getElementById('Order_ID')) document.getElementById('Order_ID').parentElement.classList.remove('is-empty');
    if (document.getElementById('Product_Description')) document.getElementById('Product_Description').parentElement.classList.remove('is-empty');
    // if (document.getElementById('Organazation_Name')) document.getElementById('Organazation_Name').parentElement.classList.remove('is-empty');


  }

  openFileSelector() {
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
  }

  getNewPippinDouments() {
    return this.newDocs.filter((docs) => docs.type == CONSTANTS.orderDocumentType.adminDocs);
  }

  getNewInternalDouments() {
    return this.newDocs.filter((docs) => docs.type == CONSTANTS.orderDocumentType.internalDocs);
  }

  uploadDocument(event) {
    if (this.uploadType == CONSTANTS.orderDocumentType.adminDocs) this.selectedDocsTab = CONSTANTS.orderDocumentTabs.adminTab;
    else {
      if (this.checkIfNotExternalSM()) this.selectedDocsTab = CONSTANTS.orderDocumentTabs.internalTab;
      else this.selectedDocsTab = CONSTANTS.orderDocumentTabs.adminTab;
    }
    if (this.validateFilenames(event)) {
      var fileUploads = [];
      var docType = this.uploadType == CONSTANTS.orderDocumentType.adminDocs ? CONSTANTS.orderDocumentType.adminDocs : CONSTANTS.orderDocumentType.internalDocs;
      if (this.checksize(event.target.files)) {
        for (var i = 0; i < event.target.files.length; i++) {
          var tempDocId = UUID.UUID();
          this.newDocs.push({ docId: tempDocId, name: event.target.files[i].name, completed: 0, type: docType })
          // fileUploads.push(this.uploadTagDocToS3AndUpdatePath(tempDocId, event.target.files[i], docType, this.tagDocumentName, this.otherDocsTag))
          fileUploads.push(this.uploadToS3AndUpdatePath(tempDocId, event.target.files[i], docType))
        }
        Promise.all(fileUploads)
          .then((files) => {
            //this.newDocs = this.newDocs.filter(function (document) { return !_.findWhere(files, document)});
            this.removeCompletedDocs(files)
            var succesFiles = files.filter((file) => file !== undefined);
            if (succesFiles.length > 0) {
              this.getDocs(this.orderId);
              if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
            }
          })
          .catch((err) => { this.openErrorPopup(CONSTANTS.apiErrors.multiupload) })
      }
      else {
        this.openErrorPopup(CONSTANTS.apiErrors.docuploadsize);
      }
    }
    else {
      this.openErrorPopup(CONSTANTS.apiErrors.validateFile)
    }

  }

  removeCompletedDocs(files) {
    for (var i = 0, len = files.length; i < len; i++) {
      for (var j = 0, len2 = this.newDocs.length; j < len2; j++) {
        if (files[i] && files[i].docId === this.newDocs[j].docId) {
          this.newDocs.splice(j, 1);
          len2 = this.newDocs.length;
        }
      }
    }
  }

  checksize(files) {
    var largeFiles = Array.prototype.filter.call(files, (file) => file.size > this.config.getMaxFileSize() * 1024 * 1024);
    if (largeFiles.length > 0) return false;
    else return true;
  }

  validateFilenames(event) {
    console.log("selected files", event.target.files);
    var flag = 0;
    for (var i = 0; i < event.target.files.length; i++) {
      var filename = event.target.files[i].name;
      if (filename.indexOf(',') > -1) flag++;
    }
    if (flag == 0) return true;
    else return false;
  }

  getEstimationTime() {
    // console.log("ESt time");
    if (this.orderDetails && this.orderDetails.Order_ETA && (this.orderDetails.Order_Status != this.constants.orderStatus.received || this.orderDetails.Order_Status != this.constants.orderStatus.assigned)) {
      this.isDate = true;
      // this.orderDetails.Order_ETA = this._datePipe.transform(this.orderDetails.Order_ETA);
      return this.orderDetails.Order_ETA;

    }
    this.isDate = false;
    return this.orderDetails.Order_Estimated_Time;
  }

  isThreadedMessage(msg) {
    if (msg.Parent_Message_ID && this.parentMsgId == msg.Parent_Message_ID) {
      msg.replyFlag = false;
    } else {
      this.parentMsgId = msg.Parent_Message_ID;
      msg.replyFlag = true;
    }

  }

  rephraseOldMessage(msg) {
    let msgCreationMoment = momentTimeZone.tz(msg.Message_Creation_Date, 'America/New_York');
    let deployedTime = momentTimeZone.tz('2022-04-25 3:20:00', 'America/New_York');
    if(msgCreationMoment.isSameOrBefore(deployedTime)){
      if((msg.Message_Type == this.constants.messageType.internalMessage ||  msg.Message_Type == this.constants.messageType.internalNotes) && (msg.Receiver_User_ID == null))
      msg.Message_Subject = 'Order #' + msg.Order_ID + ' Comment from ' + msg.User_First_Name  + " " + msg.User_Last_Name;
    }
  }

  replyToThreadMsg(msg) {
    if (msg.Parent_Message_ID) {
      this.msgService.getAllMsgs(msg.Parent_Message_ID)
        .subscribe((allMsgs) => {
          msg.allMsg = allMsgs;
          this.openThreadMessageInterface(msg)
        }, (err) => {
          console.log(err);
        })
    } else {
      this.msgService.getMsgData(msg.Message_ID)
        .subscribe((data) => {
          this.openThreadMessageInterface(data);
        }, (err) => {
          console.log(err);
        })
    }
  }

  openThreadMessageInterface(msg) {
    let disposable = this.matDialog.open(MessageComponent, {data:{
      title: 'Send Message',
      message: msg
    }, ...{ closeByClickingOutside: false }}).afterClosed()
      .subscribe((res) => {
        if (res) {
          console.log("Ok");
          if (msg.Parent_Message_ID) this.sendMessageWithOrderId({ Message_Text: res, Customer_ID: msg.Customer_ID, Parent_Message_ID: msg.Parent_Message_ID });
          else {
            var data = {
              msgDataToSent: {
                Order_ID: this.orderDetails.Order_ID,
                ClientId: msg.Sender_User_ID,
                UserId: this.auth.getUserId(),
                Customer_ID: msg.Customer_ID,
                Message_Text: res
              },
              Message_ID: msg.Message_ID
            }
            this.updateParentId(data);
          }
        }
        console.log("Closed", res)
      });
  }

  sendMessageWithOrderId(data) {
    this.orderService.sendMessage(this.orderDetails.Order_ID, data, this.auth.getUserId())
      .subscribe((data) => {
        this.getOrderMessages(this.orderDetails.Order_ID);
      }, (err) => {
        console.log(err);
      })
  }

  updateParentId(msgData) {
    this.msgService.updateParentId(msgData)
      .subscribe((data) => {
        this.getOrderMessages(this.orderDetails.Order_ID);
      }, (err) => {
        console.log(err);
      })
  }

  openErrorPopup(msg, msgTitle = null) {
    let disposable = this.matDialog.open(ErrorDialogComponent,{data:{
      message: msg,
      title: msgTitle
    }, ...this.config.getDialogOptions()});
  }

  getAbstractorList() {
    return this.abstractorService.getAbstractorList(this.orderDetails.Order_ID, [['Abstrator_Cost', 'ASC'], ['Abstrator_Rating', 'DESC']])
  }

  openAbsModal(currentAbstractor) {
    return this.matDialog.open(AbstractorSendComponent, {data:{
      title: 'Abstractors list',
      message: { Order_ID: this.orderDetails.Order_ID, Abstractors: this.abstractors, Current_Abstractor: currentAbstractor }
    }, ...this.config.getDialogOptions()})
  }

  openSuccessModal(title, msg) {
    this.matDialog.open(SuccessComponent, {data:{
      title: title,
      message: msg
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        console.log("Closed", res)
      });
  }

 
  sendToAbstractor() {
    if (this.orderDetails.Abstrator_ID) this.getAbstractorMessages();
    else this.assignAbsAndSend();
  }

  assignAbsAndSend() {
    if (this.orderDetails.Abstrator_ID) {
      this.rateAbstractor();
    } else {
      this.getAbstractorList().subscribe((data) => {
        this.abstractors = data;
        if (!this.abstractors.length) this.createAssingnAbstractor(null, null);
        else this.triggerModel(null, null)
      })
    }
  }

  triggerModel(rating, notes) {
    if (this.orderDetails.Abstrator_ID) {
      this.abstractorService.getAbstractorPricingById(this.orderDetails.Abstrator_ID)
        .subscribe((currentAbstractor) => {
          this.openAbsModal(currentAbstractor).afterClosed()
            .subscribe((result) => {
              if (result) {
                if (result == -1) this.createAssingnAbstractor(rating, notes);
                else if (result.abstractor && result.checked) this.sendAbsData(result.abstractor, rating, notes, result.sendReminder);
                else if (result.abstractor && !result.checked) this.sendOrdToAbsWithoutMail(result.abstractor, rating, notes, result.sendReminder)
                else console.log("Cancelled");
              }
            })
        })
    }
    else {
      this.openAbsModal(null).afterClosed()
        .subscribe((result) => {
          if (result) {
            if (result == -1) this.createAssingnAbstractor(rating, notes);
            else if (result.abstractor && result.checked) this.sendAbsData(result.abstractor, rating, notes, result.sendReminder);
            else if (result.abstractor && !result.checked) this.sendOrdToAbsWithoutMail(result.abstractor, rating, notes, result.sendReminder)
            else console.log("Cancelled");
          }
        })
    }
  }

  sendOrdToAbsWithoutMail(abstractor, rating, notes, sendReminder) {
    this.abstractorService.assignOrderToAbs({
      Order_ID: this.orderDetails.Order_ID,
      Abstrator_ID: abstractor.Abstrator_ID,
      Order_Rating: rating,
      Message_Text: null,
      Abstractor_Documents: [],
      notes: notes,
      Send_Reminder: sendReminder,
      Send_Mail: false
    })
      .subscribe((respones) => {
        if (respones) {
          this.successMsgFunc(CONSTANTS.apiErrors.absAssigned)
          this.getOrderDetails(this.orderDetails.Order_ID);
          this.getOrderTags(this.orderDetails.Order_ID);
          this.getOrderMessages(this.orderDetails.Order_ID);
          if(!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderDetails.Order_ID);
        }
      }, (err) => {
        this.openErrorPopup(err);
      })
  }

  sendAbsData(abstractor, rating, notes, sendReminder) {
    var docsToAbs: any = [];
    docsToAbs = this.documents.filter((x) => x.Document_Type == CONSTANTS.orderDocumentType.clientDocs || x.Document_Type == CONSTANTS.orderDocumentType.adminDocs);
    let disposable = this.matDialog.open(AbstractorMessageComponent, {data:{
      message: {
        orderDetails: this.orderDetails, abstractor: abstractor, instructions: { Search_Instructions: this.orderNotes, Special_Instructions: this.orderIns }, documents: docsToAbs
      }
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res && res.state) {
          this.abstractorService.assignOrderToAbs({
            Order_ID: this.orderDetails.Order_ID,
            Abstrator_ID: abstractor.Abstrator_ID,
            Order_Rating: rating,
            Message_Text: res.absMessage,
            Send_Reminder: sendReminder,
            Abstractor_Documents: res.absDocuments,
            notes: notes,
            Send_Mail: true
          })
            .subscribe((data) => {
              if (data) {
                if (data.mail == false) this.openErrorPopup(CONSTANTS.apiErrors.absAssgndMailNotSent);
                else this.successMsgFunc(CONSTANTS.apiErrors.absAssignedMail)
                this.getOrderDetails(this.orderDetails.Order_ID);
                this.getOrderTags(this.orderDetails.Order_ID);
                this.getOrderMessages(this.orderDetails.Order_ID);
                if(!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderDetails.Order_ID);
              }
            }, (err) => {
              this.openErrorPopup(err);
            })
        }
      })
  }

  

  createAssingnAbstractor(rating, notes) {
    var docsToAbs: any = [];
    docsToAbs = this.documents.filter((x) => x.Document_Type == CONSTANTS.orderDocumentType.adminDocs || x.Document_Type == CONSTANTS.orderDocumentType.clientDocs);
    let disposable = this.matDialog.open(CreateAbsDialogComponent, {data:{
      message: {
        order: this.orderDetails,
        rating: rating,
        notes: notes,
        instructions: { Search_Instructions: this.orderNotes, Special_Instructions: this.orderIns },
        documents: docsToAbs
      }
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res) {
          if (res.checked && !res.mail) this.openErrorPopup(CONSTANTS.apiErrors.absAssgndMailNotSent);
          else if (!res.checked && !res.mail) this.successMsgFunc(CONSTANTS.apiErrors.newAbsAssigned);
          else if (res.checked && res.mail) this.successMsgFunc(CONSTANTS.apiErrors.newAbsMail);
          this.getOrderDetails(this.orderDetails.Order_ID);
          this.getOrderTags(this.orderDetails.Order_ID);
          this.getOrderMessages(this.orderDetails.Order_ID);
          if(!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderDetails.Order_ID);
        }
      });
  }

  rateAbstractor() {
    let disposable = this.matDialog.open(RateAbstractorComponent,{data:{
      title: 'Rate Abstractor',
      message: { Abstrator: this.assignedAbstractor }
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res && res.state) {
          this.getAbstractorList().subscribe((data) => {
            this.abstractors = data;
            if (!this.abstractors.length) this.createAssingnAbstractor(res.rating, res.notes);
            else this.triggerModel(res.rating, res.notes)
          })
        }
      });
  }

  getAbstractorData() {
    if (this.orderDetails.Abstrator_ID)
      this.abstractorService.getAbstractorDetailsByAbsId(this.orderDetails.Abstrator_ID)
        .subscribe((absData) => {
          this.assignedAbstractor = absData;
          this.getAbstractorFee(this.orderDetails.Abstrator_ID);
          this.getOrderAbstractor(this.orderDetails.Abstrator_ID)
        })
  }

  checkIfPropertyAvailable() {
    this.orderService.checkOrderProperty(this.orderDetails.Order_ID)
      .subscribe((data) => {
        if (data.status) this.isSearchAlreadyAvailable = true;
      }, (err) => {
        console.log("Error", err);
      })
  }

  getAbstractorMessages() {
    this.abstractorService.getAbstractorMessageList(this.orderDetails.Order_ID, this.orderDetails.Abstrator_ID)
      .subscribe((ordAbsMsgList) => {
        let disposable = this.matDialog.open(AbstractorMessagesComponent, {data:{
          message: { orderDetails: this.orderDetails, absMessages: ordAbsMsgList }
        }, ...this.config.getDialogOptions()})
      })
  } 

  getGrandTotal() {
    return Number((this.getOrderTotalPrice() + this.orderDetails.Order_Custom_Price_Tax).toFixed(2));
  }

  fetchUploadProgress() {
    this.s3Service.progress
      .subscribe((res) => {
        var matchedIndex = this.newDocs.findIndex((doc) => doc.name == res.name);
        if (this.newDocs[matchedIndex]) {
          this.newDocs[matchedIndex].completed = Math.round(((res.progress.loaded / res.progress.total) * 100) * 100) / 100;
          this.newDocs[matchedIndex].completed = Math.round(this.newDocs[matchedIndex].completed);
        }
      });
  }

  extractFilename(filePath) {
    var parts = filePath.split('/');
    var filename = parts[parts.length - 1];
    return filename;
  }

  uploadToS3AndUpdatePath(tempDocId, file, docType) {
    return this.s3Service.onManagedUpload(file, this.orderId)
      .then((data) => {
        return this.openDocCategory(docType, {
          list: this.documentCategories,
          file: file
        })
        .then((result) => {
          let cat = result ? result : this.getDefaultCategory();
          return this.s3Service.updateDocumentPath(this.orderId,
            this.orderDetails.Property_ID,
            file,
            data.Key,
            this.extractFilename(data.Key),
            docType,
            cat
          )
            .then(() => { return { docId: tempDocId, name: file.name, completed: 100, type: docType } })
            .catch((err) => { throw err; })
        })        
      })
      .catch((err) => { console.log(err); this.openErrorPopup(CONSTANTS.apiErrors.multiupload); })
  }

  // uploadTagDocToS3AndUpdatePath(tempDocId, file, docType, documentTagName, otherDocsTag) {
  //   let otherDocsListName
  //   if (otherDocsTag) {
  //     let otherDocTagIndex = this.documentsTag.findIndex((x) => x.Document_Tag_ID == CONSTANTS.orderDocumentsTags.otherTagDocs)
  //     otherDocsListName = this.documentsTag[otherDocTagIndex].Document_Tag_Name;
  //   }
  //   return this.s3Service.onManagedDocTagUpload(file, this.orderId, documentTagName, otherDocsTag, otherDocsListName)
  //     .then((data) => {
  //       this.otherDocsTag = false;
  //       return this.s3Service.updateDocumentTagPath(this.orderId,
  //         this.orderDetails.Property_ID, file, data.Key,
  //         this.extractFilename(data.Key), docType, documentTagName, otherDocsListName)
  //         .then(() => { return { docId: tempDocId, name: file.name, completed: 100, type: docType } })
  //         .catch((err) => { throw err; })
  //     })
  //     .catch((err) => { this.openErrorPopup(CONSTANTS.apiErrors.multiupload); })
  // }


  openDocCategory(doctype, info){
    if(doctype == this.constants.orderDocumentType.adminDocs){      
      return this.dialog.open(DocCategoryComponent, {data: info}).afterClosed()
      .toPromise()
      .then((result) => result)
    }
    else return Promise.resolve(null);
  }


  handleDocCategoryChange(doc){   
    this.dialog.open(DocCategoryComponent, { data:  {
      list: this.documentCategories,
      file: { name: doc.Document_Original_Name, doc_cat_id: doc.Doc_Cat_ID }
    }})
    .afterClosed()
    .subscribe((category) => {            
      let cat = category ? category : this.getDefaultCategory();
      if(category && cat != doc.Doc_Cat_ID){              
        this.orderService.updateDocumentCategory(doc.Order_Document_ID, cat).subscribe(() => {
          this.getDocs(this.orderDetails.Order_ID);
        }, ()=> {
          this.openErrorPopup("Error updating the document category");
        })
      }
    })   
  }

  checkStatus() {
    if ((this.orderDetails.Order_Status == this.constants.orderStatus.cancelled)) {
      this.openErrorPopup(CONSTANTS.apiErrors.docUploadFail);
    }
    else {
      if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin || Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin) this.openFileSelector();
      else {
        if (!((this.orderDetails.Order_Status == this.constants.orderStatus.completed))) this.openFileSelector()
        else this.openErrorPopup(CONSTANTS.apiErrors.docUploadFail);
      }
    }
  }

  checkStatusAndDeleteFile(filename, orderDocId) {
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin || Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin) this.deleteFile(filename, orderDocId);
    else {
      if (!((this.orderDetails.Order_Status == this.constants.orderStatus.completed)
        || (this.orderDetails.Order_Status == this.constants.orderStatus.cancelled))) this.deleteFile(filename, orderDocId)
      else this.openErrorPopup(CONSTANTS.apiErrors.docDeleteFail);
    }
  }

  checkIfSearchMaster() {
    if (Number(this.auth.getUserRole()) == CONSTANTS.userRole.searchMaster) return true;
    else return false;
  }

  isSearchManager() {
    if (this.auth.isSearchManager()) return true;
    else return false;
  }

  checkForStatus() {
    if (this.orderDetails.Order_Status == CONSTANTS.orderStatus.completed || this.orderDetails.Order_Status == CONSTANTS.orderStatus.cancelled) return true;
    else return false;
  }

  checkForRoleAdmin() {
    if ((Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) || (Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin)) return true;
    else return false;
  }

  checkForRoleAdminsOnly() {
    if ((Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) || (Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin) || (Number(this.auth.getUserRole()) == CONSTANTS.userRole.admin)) return true;
    else return false;
  }

  checkIfSearcherAssigned() {
    if (this.orderDetails) {
      if (this.orderDetails.Admin_User_Role == CONSTANTS.userRole.searchMaster) return true;
      else return false;
    }
  }

  updateOrder(ordId) {
    this.router.navigate(['/update/' + ordId + '/view']);
  }

  // changeSearchMaster() {
  //   let result = this.getOrganizationData();
  //   this.userService.getAllOrgSearchMasterToOrderAsign(result).subscribe((searchData) => {
  //     let disposable = this.matDialog.open(AssignComponent, {
  //       message: {
  //         Order_ETA: new Date(this.orderDetails.Order_ETA),
  //         Search_Masters: searchData,
  //         Current_Admin: this.orderDetails.Order_Admin_ID,
  //         admins: this.adminList,
  //       }
  //     })
  //       .subscribe((res) => {
  //         if (res) {
  //           this.orderService.updateAdminOrSearchMaster(this.orderId, res.admin, res.Order_ETA)
  //             .subscribe((data) => {
  //               console.log(data);
  //               this.getOrderDetails(this.orderId);
  //             }, (err) => {
  //               console.log(err);
  //               this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
  //             })
  //         }
  //       });
  //   })
  // }

  getOrganizationData() {
    var result = {};
    if (this.orderDetails.Organization_ID) result['Organization_ID'] = this.orderDetails.Organization_ID;
    return result;
  }

  downloadInvoice() {
    this.quickbooks.downloadOrderInvoice(this.orderDetails.Order_ID, null, 'info')
      .subscribe((blobData) => {
        if (blobData) this.composeAndDwnld(blobData, this.orderDetails.Order_ID)
      }, (err) => {
        if (err.code) this.openErrorPopup(err.msg);
        else this.openErrorPopup(CONSTANTS.apiErrors.quickbookAuth);
      })
  }

  getOrderInvoice() {
    this.quickbooks.getOrderInvoice(this.orderDetails.Order_ID)
      .subscribe((invoice) => {
        if (invoice) {
          this.invoicePresent = true;
          this.invoiceDetailsInfo = invoice;
          this.uploadInvoiceDocs(invoice);
        }
      }, (err) => {
        if (err.code) this.openErrorPopup(err.msg);
      })
  }


  sendOrdToAdmin() {
    var currentOrderStatus = CONSTANTS.orderStatus.confirmed;
    this.orderService.changeOrdAdmStatus(this.orderId, currentOrderStatus)
      .subscribe((data) => {
        this.initializeProgressBar();
        this.getOrderDetails(this.orderId);
        this.getOrderMessages(this.orderId);
        this.getTimeline(this.orderId);
      })
  }

  composeAndDwnld(blobData, orderId) {
    let file = new Blob([blobData], { type: 'application/octet-stream' });
    var fileURL = URL.createObjectURL(file);
    var link = document.createElement("a");
    link.setAttribute("href", fileURL);
    link.setAttribute("download", "Invoice_" + orderId + ".pdf");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  generateInvoice() {
    if (this.orderDetails.Order_Status == CONSTANTS.orderStatus.cancelled) {
      this.cancelOrderFlag = true;
      if (!this.cancelPayment) {
        this.openCancelmailDialog().afterClosed()
          .subscribe((res) => {
            if (res) {
              if (res.checked && res.mail_text) {
                this.mail_text = res.mail_text;
              }
              this.verifyAndGenerateInvoice();
            } else if (this.invoicePresent) this.verifyAndGenerateInvoice();
          })
      }
    }
    else {
      this.verifyAndGenerateInvoice();
    }

  }

  changeAssignee(orderDet = null) {
    if (orderDet) this.orderDetails = orderDet
    let result = this.getOrganizationData();
     // Call the appropriate API based on whether the user is a search manager
     const apiCall = this.isSearchManager() ? this.userService.getSearchManagerAndItsTeam(this.auth.getUserId()) : this.userService.getAllOrgBackOfficeAgentsToOrderAssign(result);
     // Subscribe to the API call 
     apiCall.subscribe((adminData) => {
        let disposable = this.matDialog.open(AssignComponent, {data:{
          title: 'Assign Order',
          message: {
            Order_ETA: this.orderDetails.Order_ETA,
            Search_Masters: this.isSearchManager() ? adminData : adminData['searchers'],
            Current_Admin: this.orderDetails.Order_Admin_ID,
            admins: this.isSearchManager() ? [] : adminData['admins'],
          },
          listOfHolidays:this.holidayList
        }, ...this.config.getDialogOptions()}).afterClosed()
          .subscribe((res) => {
            if (res) {
              let msgType;
              if (res.msgText) msgType = CONSTANTS.messageType.internalNotes
              else {
                res.msgText = ""
                msgType = CONSTANTS.messageType.internalNotes;
              }

              if(res.admin && res.getTimeToCompl){
              if (this.isETAChanged(res.Order_ETA) && this.orderDetails.Order_Status >= CONSTANTS.orderStatus.confirmed) {
                this.openETAChangeInterface(this.orderId, res.admin, res.Order_ETA, res.msgText, msgType, res.getTimeToCompl, res.Order_ETA_Range)
              } else {
                this.changeOrdAssignee(this.orderId, res.admin, res.Order_ETA, res.msgText, msgType, res.getTimeToCompl, res.Order_ETA_Range, '', '');
              }

            }else{

              this.orderService.unassignOrder(this.orderId,res.msgText,msgType,this.orderDetails.Order_Status).subscribe((data)=>{
                this.getOrderDetails(this.orderId);
                  this.getOrderMessages(this.orderId);
                  this.getTimeline(this.orderId);
                  if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
                }, (err) => {
                  this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
              })

            }
            }
          });
      })
  }

  openETAChangeInterface(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange) {
    let disposable = this.matDialog.open(EtaChangeReasonComponent, {data:{
    }, ...{ closeByClickingOutside: false }}).afterClosed()
      .subscribe((res) => {
        if (res.state) {
          this.changeOrdAssignee(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, res.Message_Text, res.Order_ETA_Change_Reason_Type_ID);
        }
      });
  }

  changeOrdAssignee(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, orderETAChangeReason, Order_ETA_Change_Reason_Type_ID) {
    this.orderService.changeOrderAssign(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, orderETAChangeReason, Order_ETA_Change_Reason_Type_ID)
      .subscribe((data) => {
        this.getOrderDetails(this.orderId);
        this.getOrderMessages(this.orderId);
        if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
        this.getETAChangeReasonsById(this.orderId);
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
      })
  }

  changeScreener() {
    let data = this.getOrganizationData();
    // Call the appropriate API based on whether the user is a search manager
    const apiCall = this.isSearchManager() ? this.userService.getSearchManagerAndItsTeam(this.auth.getUserId()) : this.userService.getAllOrgBackOfficeAgentsToOrderAssign(data);
    // Subscribe to the API call 
    apiCall.subscribe((adminData) => {
        let disposable = this.matDialog.open(ScreenOrderComponent, {data:{
          title: this.isSearchManager() ? 'Assign Order' : 'Screen order',
          message: {
            admins: this.isSearchManager() ? [] : adminData['admins'],
            Search_Masters: this.isSearchManager() ? adminData : adminData['searchers'],
            Current_Admin: this.orderDetails.Order_Admin_ID
          }
        }, ...this.config.getDialogOptions()}).afterClosed()
          .subscribe((res) => {
            if (res) {
              let msgType;
              if (res.msgText) msgType = CONSTANTS.messageType.internalNotes
              else {
                res.msgText = ""
                msgType = CONSTANTS.messageType.internalNotes;
              }

              if(res.admin && res.getTimeToCompl){

              this.orderService.changeScreener(this.orderId, res.admin, res.msgText, msgType, res.getTimeToCompl)
                .subscribe((data) => {
                  this.getOrderDetails(this.orderId);
                  this.getOrderMessages(this.orderId);
                  this.getTimeline(this.orderId);
                  if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
                }, (err) => {
                  console.log(err);
                  this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
                })
              }else{

                this.orderService.unassignOrder(this.orderId,res.msgText,msgType,this.orderDetails.Order_Status).subscribe((data)=>{
                  this.getOrderDetails(this.orderId);
                  this.getOrderMessages(this.orderId);
                  this.getTimeline(this.orderId);
                  if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
                }, (err) => {
                  this.openErrorPopup(CONSTANTS.apiErrors.orderassign);
              })


              }
            }
      }, (err) => { console.log(err); })
    })
  }

  modifyOrderAssignee() {
    if (this.orderDetails.Order_Status < this.constants.orderStatus.confirmed) this.changeScreener()
    else if ((this.orderDetails.Order_Status >= this.constants.orderStatus.confirmed) && (this.orderDetails.Order_Status < this.constants.orderStatus.completed)) this.changeAssignee();
    else return;
  }

  updateOrderDocsType(doc, docType) {
    if(docType == this.constants.orderDocumentType.internalDocs){
      this.dialog.open(DocCategoryComponent, { data:  {
        list: this.documentCategories,
        file: { name: doc.Document_Original_Name }
      }})
      .afterClosed()
      .subscribe((category) => {
        let cat = category ? category : this.getDefaultCategory();
        this.orderService.updateOrderDocsWithType(doc.Order_Document_ID, docType, cat)
        .subscribe((data) => {
          this.getDocs(this.orderId);
          if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
        })
      })
    }
    else {
      this.orderService.updateOrderDocsWithType(doc.Order_Document_ID, docType, null)
      .subscribe((data) => {
        this.getDocs(this.orderId);
        if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
      })
    }    
  }

  changeOrderState(event) {
    let popupTitle;
    let message = {
      Order_ETA: this.orderDetails.Order_ETA,
      Order_Status: this.orderDetails.Order_Status,
      Order_Paused_Flag: this.orderDetails.Order_Paused_Flag
    }
    if (event) {
      popupTitle = 'Pause Order';
      this.triggerActionPopup(event, popupTitle, message);
    }
    else {
      popupTitle = 'Unpause Order';
      this.orderService.getPredictedETA(this.orderDetails.Product_ID, this.orderDetails.Property_State_Abbr, this.orderDetails.Property_County)
      .subscribe((predictedData) => {
        if(predictedData && predictedData.Predicted_ETA) message.Order_ETA = predictedData.Predicted_ETA
        this.triggerActionPopup(event, popupTitle, message);
      })
    }    
  }

  triggerActionPopup(event, popupTitle, message){
    this.matDialog.open(ActionCommentComponent, {data:{
      title: popupTitle,
      message: message,
      listOfHolidays:this.holidayList
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res) {
          if (res.state) {
            if (this.isETAChanged(res.Order_ETA) && (this.orderDetails.Order_Status >= CONSTANTS.orderStatus.confirmed || this.checkOrderIsConfirmed())) {
              this.ordStateETAChangeInterface(this.orderId, event, res.comment, res.Order_ETA, res.Order_ETA_Range)
            } else {
              this.updateOrderState(this.orderId, event, res.comment, res.Order_ETA, res.Order_ETA_Range, '', '');
            }
          }
        }
      })
  }

  ordStateETAChangeInterface(orderId, orderState, comment, orderETA, orderETARange) {
    let disposable = this.matDialog.open(EtaChangeReasonComponent, {data:{
    }, ...{ closeByClickingOutside: false }}).afterClosed()
      .subscribe((res) => {
        if (res.state) {
          this.updateOrderState(orderId, orderState, comment, orderETA, orderETARange, res.Message_Text, res.Order_ETA_Change_Reason_Type_ID);
        }
      });
  }

  updateOrderState(orderId, orderState, comment, orderETA, orderETARange, orderETAChangeReason, Order_ETA_Change_Reason_Type_ID) {
    this.orderService.updateOrderState(orderId, orderState, comment, orderETA, orderETARange, orderETAChangeReason, Order_ETA_Change_Reason_Type_ID)
      .subscribe((details) => {
        this.orderDetails = details;
        this.getCustomGeneratedStatus(this.orderId);
        this.loadPricingFields(details);
        this.getOrderMessages(this.orderId);
        if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
        this.getETAChangeReasonsById(this.orderId);
        if (this.orderAbstractor) this.cancelReminderAlerts(false);
      }, (err) => {
        this.openErrorPopup('Error while updating the order state. Please try again.');
      })
  }

  constructLink() {
    this.netrOnlineLink += this.orderDetails.Property_State_Abbr + '/county/' + this.fetchCounty()
  }

  constructOrdTrackerLink(ordDetails) {
    this.ordTrackerLink = environment.clientBasePath + '/orders/'+ordDetails.Order_ID+'/status/'+ordDetails.Customer_ID ;
  }

  getDataFromEnvFile() {
    this.texasT00Link = environment.texasT00Link;
    this.runsheetLink = environment.runsheetLink;
    this.wimbaTemlLink = environment.wimbaTemplateLink;

  }

  fetchCounty() {
    if(this.orderDetails.Property_County){
      var county = this.orderDetails.Property_County.toLowerCase().split(' ').join('_');
      return county;
    }
    else return null;
  }

  documentClickedBy(event, uploadDocType) {
    if (uploadDocType == CONSTANTS.orderDocumentType.adminDocs) this.uploadType = CONSTANTS.orderDocumentType.adminDocs
    else if (uploadDocType == CONSTANTS.orderDocumentType.internalDocs) this.uploadType = CONSTANTS.orderDocumentType.internalDocs
    event.stopPropagation();
  }
  //For Tag Documents ======>Start Here====>
  // uploadDocsWithType(documentTagName, documentTagID, event) {
  //   this.tagDocumentName = documentTagName;
  //   if (documentTagID == CONSTANTS.orderDocumentsTags.otherTagDocs) {
  //     this.isOtherDocumentTag = true;
  //     this.otherDocsTag = true;
  //     this.timer.push(setTimeout(() => { this.otherDocTagInp.nativeElement.focus(); }, 0));
  //     event.stopPropagation();
  //   } else {
  //     this.isOtherDocumentTag = false;
  //     this.otherDocsTag = false;
  //     this.checkStatus();
  //   }
  // }

  // setOtherTagDocument() {
  //   this.isOtherDocumentTag = false;
  //   this.checkStatus();
  // }

  // stopEventPropagation(event) {
  //   if (this.isOtherDocumentTag) {
  //     this.timer.push(setTimeout(() => { this.otherDocTagInp.nativeElement.blur(); }, 0));
  //     event.stopPropagation();
  //   }
  // }

  // clearMenuItem() {
  //   if (this.isOtherDocumentTag) {
  //     this.otherDocsTag = false
  //     this.isOtherDocumentTag = false;
  //     this.tagDocumentName = null;
  //   }
  // }
  // Tag Document ========<End Here<=========

  uploadDocsWithType(type) {
    if (type == CONSTANTS.orderDocumentType.adminDocs) {
      this.uploadType = CONSTANTS.orderDocumentType.adminDocs
      this.checkStatus();
    } else if (type == CONSTANTS.orderDocumentType.internalDocs) {
      this.uploadType = CONSTANTS.orderDocumentType.internalDocs
      this.checkStatus();
    }
  }

  getOrderExpenses() {
    this.orderService.getOrderExpenseDetails(this.orderId)
      .subscribe((orderExpenses) => {
        this.orderExpenses = orderExpenses;
      })
  }

  getTotalExpense() {
    var total = 0;
    this.orderExpenses.forEach((expense) => {
      if (expense.Order_Expense_Status == CONSTANTS.orderExpenseStatus.active) {
        total += expense.Order_Expense_Amount;
      }
    })
    this.bills.forEach((bill) => {
      total += bill.Quickbooks_Bill_Total_Amount;
    })
    return total.toFixed(2);
  }

  getDueTotalExpense() {
    var total = 0;
    this.orderExpenses.forEach((expense) => {
      if (expense.Order_Expense_Status == CONSTANTS.orderExpenseStatus.active && expense.Order_Expense_Paid_Flag == CONSTANTS.orderExpensePaidFlag.unpaid) {
        total += expense.Order_Expense_Amount;
      }
    })
    this.bills.forEach((bill) => {
      total += bill.Quickbooks_Bill_Total_Amount;
    })
    return total.toFixed(2);
  }

  checkDaylight(date) {
    if (momentTimeZone.tz(date, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  getActiveTab() {
    let tabIndex = 0;
    if (this.routingState.getPrevUrl() == '/edit-order-expense/' + this.orderId) tabIndex = 1;
    return tabIndex;
  }

  openNewTagModal(orderId) {
    this.matMenuTrigger.closeMenu();
    this.matDialog.open(NewTagComponent, {data:{
      message: { Order_ID: orderId }
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {
      if (res) {
        this.getOrderTags(this.orderId);
        if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
      }
    });
  }

  clearSearchTag() {
    this.searchTag = '';
  }

  menuClosed() {
    this.clearSearchTag();
    this.associatedTags = [];
    this.availableTags = [];
  }

  onSelectedOptionsChange(event) {
    this.associatedTags = [];
    event.forEach((option) => this.associatedTags.push(option.value));
  }

  //   getAvailableTagLocal(){
  //     let AllTag = JSON.parse(sessionStorage.getItem('AllTagsAvailable'));
  //     if(AllTag) this.openTagMenu(AllTag)         
  //     else this.getAvailableTagList();
  // }

  //   getAvailableTagList(){
  //     this.tagService.getAllActiveTagsForListing().subscribe((tags) => { 
  //       if(tags){
  //         this.openTagMenu(tags)         
  //         sessionStorage.setItem('AllTagsAvailable',JSON.stringify(tags)); 
  //        setTimeout(function(){sessionStorage.removeItem("AllTagsAvailable");}, CONSTANTS.sessiontimeOut.timeOut);
  //       }
  //     }, (err) => {
  //         this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
  //     })
  //   }


  openTagMenu() {
    this.tagService.getAllActiveTagsForListing()
      .subscribe((activeTags) => {
        for (var i = 0, len = activeTags.rows.length; i < len; i++) {
          if (this.orderTags.findIndex((select => activeTags.rows[i].Tag_ID == select.Tag_ID)) == -1) {
            if (activeTags.rows[i].Tag_ID != this.config.getNewClientTagId() && activeTags.rows[i].Tag_ID != this.config.getPriorityClientTagId() 
            && activeTags.rows[i].Tag_ID != this.config.getFirstOrdTagId() && activeTags.rows[i].Tag_ID != this.config.getPrePaidTagId()
            && activeTags.rows[i].Tag_ID != this.config.getAwaitDlvryTagId() && activeTags.rows[i].Tag_ID != this.config.getAwaitPartDlvryTagId()) 
            this.availableTags.push(activeTags.rows[i]);
          }
        }
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  // openTagMenu(activeTags) {
  //       for (var i = 0, len = activeTags.rows.length; i < len; i++) {
  //         if (this.orderTags.findIndex((select => activeTags.rows[i].Tag_ID == select.Tag_ID)) == -1)
  //           this.availableTags.push(activeTags.rows[i]);
  //       }
  // }

  updateOrderTags(associatedTags) {
    this.orderService.updateOrderTags({ Order_ID: this.orderDetails.Order_ID, Order_Tags: associatedTags })
      .subscribe(() => {
        if (this.matMenuTrigger) this.matMenuTrigger.closeMenu();
        this.getOrderTags(this.orderId);
        this.getCustomGeneratedStatus(this.orderId);
        if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
      }, (err) => {
        console.log("err", err);
      })
  }

  checkIfAlreadyCompleted(){
    let completedOrdIndex = this.allOrderStatus.findIndex((data) => data.Status_New === CONSTANTS.orderStatus.completed)
    if(completedOrdIndex > -1) return true;
    else return false;
  }

  modifyOrderTags(event, tag, order) {
    if(tag && tag.Tag_ID ==  this.config.getPartiallyDeliveredTagId()){
      this.orderService.getAllOrderStatus(order.Order_ID)
      .subscribe((orderStatuses) => {
        this.allOrderStatus = orderStatuses;
        if(this.checkIfAlreadyCompleted()) this.modifyOrderTagsRequest(event, tag, order)
        else{
          this.openErrorPopup('This tag can only be assigned after an order has been completed at least once.', 'Invalid Tag');
          event.source.checked = false;
          this.matMenuTrigger.closeMenu();
        }
      })
    }
    else this.modifyOrderTagsRequest(event, tag, order)
  }

  modifyOrderTagsRequest(event, tag, order){
    var isTagToAdd: boolean;
    if (event.checked) isTagToAdd = true;
    else isTagToAdd = false;
    this.orderService.modifyOrderTags({ Order_ID: order.Order_ID, Order_Tag: tag, isTagToAdd: isTagToAdd })
      .subscribe(() => {
        this.getOrderTags(this.orderId);
        this.getCustomGeneratedStatus(this.orderId);
        this.getOrderMessages(this.orderId);
        if(tag && tag.Tag_ID ==  environment.commitmentTypingTagID) {
          this.getOrderProducts(this.orderId);
        }
        if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
        if(isTagToAdd) this.availableTags = this.availableTags.filter(item => (item.Tag_ID !== tag.Tag_ID))
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.orderTagging);
        this.matMenuTrigger.closeMenu();
      })
  }


  removeTag(tag) {
    if (tag.Tag_ID == this.config.getNewClientTagId()) {
      this.openErrorPopup("New Client tags are set at the client level and cannot be removed at the order level.");
    } 
    else if(tag.Tag_ID == this.config.getPriorityClientTagId()) {
      this.openErrorPopup("Priority Client tags are set at the client level and cannot be removed at the order level.");  
    } 
    else if(tag.Tag_ID == this.config.getFirstOrdTagId()) {
      this.openErrorPopup("First Order tags are set at the client level and cannot be removed at the order level.");  
    } 
    else if(tag.Tag_ID == this.config.getFirstOrdTagId()) {
      this.openErrorPopup("First Order tags are set at the client level and cannot be removed at the order level.");  
    } 
    else if(tag.Tag_ID == this.config.getPrePaidTagId()) {
      this.openErrorPopup("Prepaid tags are set at the client level and cannot be removed at the order level.");  
    } 
    else if(tag.Tag_ID == this.config.getAbsDoubleAssignTagId()) {
      this.openErrorPopup("This tag cannot be removed manually.");  
    }  
    else if(tag.Tag_ID == this.config.getQCTagId()) {
      this.openChecklist(tag, 1);  
    }  
    else if(tag.Tag_ID == this.config.getSearchingTagId()) {
      this.openChecklist(tag, 2);  
    } 
    else {
      this.matDialog.open(ConfirmComponent, {data:{
        title: 'Remove Tag',
        message: 'Are you sure you want to remove ' + tag.Tag_Name + ' tag?',
        breakWordFlag: true
      }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {
        if (res) {
          var associatedTags = this.orderTags.filter((orderTag) => orderTag.Tag_ID != tag.Tag_ID);
          associatedTags = this.handleSoftProTag(tag, associatedTags);
          this.updateOrderTags(associatedTags);
        }
      });
    }
  }

  openChecklist(tag, stage){
    let checkListObj = {
      title: stage == 1  ? 'Checklist : Quality' : 'Checklist : Searching',
      orderDetails: this.orderDetails,
      Order_Stage: stage
    }
    if (this.orderDetails && this.orderDetails.checkListData) checkListObj['checklistData'] = this.orderDetails.checkListData
    let disposable = this.matDialog.open(OrderChecklistComponent, {data:checkListObj, ...this.config.getDialogOptions()}).afterClosed()
    .subscribe((res) => {
      if (res && res.state) {
        this.orderService.postQCChecklist(
          { Order_ID: this.orderDetails.Order_ID, 
            Stage: stage,
            Order_Check_List: res.retObj.checkListObj, 
            Company_Check_List: res.retObj.checkListCmp }
          )
        .subscribe(() => {
          stage == 1  ? this.orderTags.push({
            Tag_ID: res.retObj.isPartial
              ? this.config.getAwaitPartDlvryTagId()
              : this.config.getAwaitDlvryTagId(),
          }) : this.orderTags.push({Tag_ID: this.config.getQCTagId()});
          var associatedTags = this.orderTags.filter((orderTag) => orderTag.Tag_ID != tag.Tag_ID);
          this.updateOrderTags(associatedTags);
        }, (err) => {
          // console.log("err", err);
        })
      }
    })

    
  }

  handleSoftProTag(tag, currentTags) {
    if(tag.Tag_ID == this.config.getQCTagId() && this.orderDetails.Organization_Type == 4 && 
    this.orderTags.findIndex((orderTag) => orderTag.Tag_ID == this.config.getSoftProTypingTagId()) == -1)
      currentTags.push({Tag_ID: this.config.getSoftProTypingTagId()});
    return currentTags;
  }

  applyTagChanges() {
    var changes = this.orderTags.concat(this.associatedTags);
    this.updateOrderTags(changes);
  }

  identify(index, tag) {
    return tag.Tag_ID;
  }

  getFontColor(hexColor) {
    return this.tagService.getFontColor(hexColor)
  }

  successMsgFunc(msg) {
    this.userMsg.setClearSuccessTimeout();
    this.userMsg.setSuccessMsg(msg);
  }

  onTabChange(event) {
    if (event.index == 0 && !this.isChecklist) this.getChecklistFromGsheet(this.orderId);
    else if (event.index == 2 && !this.isCountyInfo) this.getCountyInfoFromGsheet();
    else if (event.index == 3 && !this.isClientInfo) this.getClientInfoFromGsheet();
    else if (event.index == 4 && !this.isRelatedSearches) this.getRelatedSerarchOrders();
    else if (event.index == 5 && !this.isBillingInfo) this.getBillingInfoFromGsheet();
    else if (event.index == 6 && !this.isOrderHistory) if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
    else if (event.index == 7 && !this.isETAChange) this.getETAChangeReasonsById(this.orderId);
    else if (event.index == 1 && !this.isSearchInfo) this.getSearchInfo();
  }

  getChecklistFromGsheet(orderId) {
    this.loadingCheckList = true;
    this.orderService.getChecklistFromGsheet(orderId)
      .subscribe((checkList) => {
        this.isChecklist = true;
        this.gSheetChecklist = checkList;
        this.loadingCheckList = false;
      }, (err) => {
        this.isChecklist = true;
        this.gSheetChecklist = [];
        this.loadingCheckList = false;
        // this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  getETAChangeReasonsById(orderId) {
    this.loadingETAChange = true;
    this.orderService.getETAChangeReasonsById(orderId)
      .subscribe((etaChgReasons) => {
        this.isETAChange = true;
        this.etaChangeReasons = etaChgReasons;
        this.loadingETAChange = false;
      }, (err) => {
        this.isETAChange = true;
        this.etaChangeReasons = [];
        this.loadingETAChange = false;
      })
  }


  getCountyInfoFromGsheet() {
    if(this.orderDetails && (this.orderDetails.Property_State_Abbr)){
    this.loadingCountyInfo = true;
      this.orderService.getCountyInfoFromGsheet(this.orderDetails.Property_State_Abbr)
      .subscribe((countyInfo) => {
        this.isCountyInfo = true;
        this.gSheetCountyInfo = countyInfo ? countyInfo : {};
        this.loadingCountyInfo = false;
      }, (err) => {
        this.isCountyInfo = true;
        this.gSheetCountyInfo = {};
        this.loadingCountyInfo = false;
        // this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
    }else{
      this.isCountyInfo = true;
    }
  }

  getClientInfoFromGsheet() {
    if (this.orderDetails.Organization_ID) {
      this.loadingClientInfo = true;
      this.orderService.getClientInfoFromGsheet(this.orderDetails.Organization_ID)
        .subscribe((clientInfo) => {
          this.isClientInfo = true;
          this.gSheetClientInfo = clientInfo[0] ? clientInfo[0]: {};
          this.loadingClientInfo = false;
        }, (err) => {
          this.isClientInfo = true;
          this.gSheetClientInfo = {};
          this.loadingClientInfo = false;
          // this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        })
    } else this.isClientInfo = true;

  }

  getBillingInfoFromGsheet() {
    if (this.orderDetails.Organization_ID) {
      this.loadingBillingInfo = true;
      this.orderService.getBillingInfoFromGsheet(this.orderDetails.Organization_ID)
        .subscribe((billingInfo) => {
          this.isBillingInfo = true;
          this.gSheetBillingInfo = billingInfo[0] ? billingInfo[0] : {};
          this.loadingBillingInfo = false;
        }, (err) => {
          this.isBillingInfo = true;
          this.gSheetBillingInfo = {};
          this.loadingBillingInfo = false;
          // this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        })
    } else this.isBillingInfo = true;
  }

  getRelatedSerarchOrders() {
    this.loadingRelatedSearches = true;
    if (this.orderDetails.Organization_ID && this.orderDetails.Organization_ID == this.config.getQualiaOrgId()) this.orderDetails.isQualiaOrder = true;
    else this.orderDetails.isQualiaOrder = false;
    this.orderService.getRelatedSerarchOrders(this.orderDetails)
      .subscribe((relatedOrds) => {
        this.isRelatedSearches = true;
        this.ordsWithSameAddress = relatedOrds.ordsWithSameAddress;
        this.ordsWithSameParcel = relatedOrds.ordsWithSameParcel; // PT-3598
        this.ordsWithSameSubdivision = relatedOrds.ordsWithSameSubdivision; // PT-3598
        this.ordsWithSimilarCountyOrZip = relatedOrds.ordsWithSimilarCountyOrZip;
        this.ordsWithSameClient = relatedOrds.ordsWithSameClient;
        this.loadingRelatedSearches = false;
      }, (err) => {
        this.isRelatedSearches = true;
        this.ordsWithSameAddress = []; 
        this.ordsWithSameParcel = []; // PT-3598
        this.ordsWithSameSubdivision = []; // PT-3598
        this.ordsWithSimilarCountyOrZip = [];
        this.ordsWithSameClient = [];
        this.loadingRelatedSearches = false;
        // this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  getSearchInfo() {
    this.loadingSearchInfo = true;
    this.orderService.getSearchInfo({ Property_County: this.orderDetails.Property_County, Property_State_Abbr: this.orderDetails.Property_State_Abbr })
      .subscribe((info) => {
        if(info.length > 0) this.gsheetSearchInfo = info[0];
        else this.gsheetSearchInfo = {};
        this.loadingSearchInfo = false;
      }, (err) => {
        this.loadingSearchInfo = false;
      })
  }

  getDataDepthInfo() {
    this.loadingSearchInfo = true;
    this.orderService.getDataDepthInfo({Property_State_Abbr: this.orderDetails.Property_State_Abbr })
      .subscribe((info) => {
        this.dataDepthYears = info[0]["State Statute"][0]
        // if(info.length > 0) this.dataDepth = info[0];
        // else this.gsheetSearchInfo = {};
        this.loadingSearchInfo = false;
      }, (err) => {
        this.loadingSearchInfo = false;
      })
  }


  checkIfDataDepthIsEmpty(){
    return Object.keys(this.gsheetSearchInfo).length === 0 && this.gsheetSearchInfo.constructor === Object
  }

  validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?','i'); // query string
    // '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    // '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      let result = !!pattern.test(str);
      if(result) return true;
      else {
        let booleanVal = false;
        try{
          let urlObj = new URL(str);
          if(urlObj && (str.includes('https') || str.includes('http'))) {booleanVal = true;}
        }
        catch (e){}
        return booleanVal;
      }
  }

  getOrderHistoryFromDB(orderId) {
    this.loadingOrderHistory = true;
    this.orderService.getOrderHistoryFromDB(orderId)
      .subscribe((OrderHistory) => {
        this.isOrderHistory = true;
        this.orderhistory = OrderHistory;
        this.loadingOrderHistory = false;
      })
  }
  checkIfNotExternalSM() {
    if(Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.externalSM) return false;
    else return true;
  }

  openNewtab(orderId) {
    window.open(this.config.getAdminHomePath() + '/orders/' + orderId + '/view', "_blank")
  }
  openNewtabcharge(charge) {
    window.open(charge.receipt_url)
  }

  searchMasterMessage(statusMsg) {
    let clientRef = statusMsg.match(this.orderDetails.File_ID)
    var findString = this.orderDetails.File_ID;
    var regularExpString = new RegExp(findString, 'g')
    if (clientRef) return statusMsg.replace(regularExpString, this.orderDetails.Order_ID);
    else return statusMsg
  }


  moreRecordsWithSameAdd() {
    this.showAllWithSameAdd = true;
  }

  lessRecordsWithSameAdd() {
    this.showAllWithSameAdd = false;
  }

  moreRecordsWithSimilarAdd() {
    this.showAllWithSimilarAdd = true;
  }

  lessRecordsWithSimilarAdd() {
    this.showAllWithSimilarAdd = false;
  }
  moreRecordsWithSameClient() {
    this.showAllWithSameClient = true;
  }

  lessRecordsWithSameClient() {
    this.showAllWithSameClient = false;
  }

  subscribeOrder() {
    this.subscribeService.subscribeOrderToAdmin(this.orderId)
      .subscribe((subscribed) => {
        if (subscribed) {
          this.getSubscriptionDetails(this.orderId);
          let msg = "Order is watching for email notification."
          this.successMsgFunc(msg);
        }
      }, (err) => {
        console.log(err);
        this.openErrorPopup(CONSTANTS.apiErrors.subscribeOrder);
      }
      )
  }

  unSubscribeOrder() {
    if (!(_.isEmpty(this.subscribeDetails))) {
      return this.subscribeDetails.filter((x) => {
        if (this.auth.getUserId() == x.Subscribed_User_ID && x.Subscription_Status == CONSTANTS.subscriptionStatus.active) {
          this.subscribeService.unSubscribeOrderToAdmin(x.Order_Subscription_ID)
            .subscribe(() => {
              this.getSubscriptionDetails(this.orderId);
              let msg = "Order is deactivated from watch."
              this.successMsgFunc(msg);
            }, (err) => {
              console.log(err);
              this.openErrorPopup(CONSTANTS.apiErrors.unSubscribeOrder);
            })
        }
      })
    }
  }

  getSubscriptionDetails(orderId) {
    this.subscribeService.getSubscriptionDetails(orderId)
      .subscribe((details) => {
        this.subscribeDetails = details
        this.checkSubscriptionStatus();
      })
  }

  checkSubscriptionStatus() {
    if (!(_.isEmpty(this.subscribeDetails))) {
      this.subscriptionStatus = this.subscribeDetails.some((x) => (this.auth.getUserId() == x.Subscribed_User_ID && x.Subscription_Status == CONSTANTS.subscriptionStatus.active))
    } else this.subscriptionStatus = false;
  }

  subscribeAction(event) {
    if (event.checked) this.subscribeOrder();
    else this.unSubscribeOrder();
  }

  getOrderBills() {
    this.expenseService.getOrderBills(this.orderDetails.Order_ID)
      .subscribe((bills) => {
        this.bills = bills;
      })
  }

  rejectOrder(rejectOrderFlag) {
    let disposable = this.matDialog.open(InternalNotesComponent, {data:{},
      ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {
        if (res) {
          if (res.state) {
            let messageText, SMCompleteMsgType;
            if (res.msgText) messageText = res.msgText;
            else messageText = '';
            if (rejectOrderFlag) SMCompleteMsgType = CONSTANTS.SMCompleteMsgType.inactive;
            else SMCompleteMsgType = CONSTANTS.SMCompleteMsgType.active;
            this.orderService.rejectOrder(this.orderDetails.Order_ID, messageText, CONSTANTS.messageType.internalNotes, rejectOrderFlag, SMCompleteMsgType)
              .subscribe(() => {
                this.getOrderDetails(this.orderId);
                this.getOrderMessages(this.orderId)
                this.router.navigate(['/orders/inprocess']);
              })
          }
        }
      });
  }

  validateOrderAddrModal(orderDetails) {
    const addressObject = {
      Property_Address_1: orderDetails.Property_Address_1,
      Property_City: orderDetails.Property_City,
      Property_County: orderDetails.Property_County,
      Property_State_Abbr: orderDetails.Property_State_Abbr,
      Property_ZipCode: orderDetails.Property_ZipCode,
      Prop_Latitude: orderDetails.Property_Latitude,
      Prop_Longitude: orderDetails.Property_Longitude,
    }
    if (orderDetails.Property_Address_2) addressObject['Prop_Address_2'] = orderDetails.Property_Address_2;
    let disposable = this.matDialog.open(ValidateOrdAddressComponent, {data:{
      title: 'Confirm Address',
      getAddress: { Address : addressObject, Product_ID : this.orderDetails.Product_ID }
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((addressResponse) => {
        if (addressResponse) {
          if (addressResponse.selectAddressType == "2") {
            addressResponse.validateAddrssObj['Order_ID'] = orderDetails.Order_ID;
            addressResponse.validateAddrssObj['Property_ID'] = orderDetails.Property_ID;
            if (orderDetails.Order_Address_Manual_Flag) addressResponse.validateAddrssObj['Address_Entry_Flag'] = orderDetails.Order_Address_Manual_Flag;
            this.orderService.updateValidateAddress(addressResponse.validateAddrssObj)
              .subscribe(() => this.navigateToAcceptOrder());
          } else this.navigateToAcceptOrder();
        }
      })
  }

  acceptOrRejectSitusAMCOrder(orderDetails) {
    const disposable = this.matDialog.open(SitusamcAcceptReject,{data:{
      pippinOrderID: orderDetails.Order_ID,
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((SitusamcAcceptRejectModalResponse) => {
        if(SitusamcAcceptRejectModalResponse){
          this.validateOrderAddrModal(this.orderDetails);
        }
      })
  }

  navigateToAcceptOrder() {
    this.router.navigate(['/orders/' + this.orderId + '/accept'])
  }

  getUserRole() {
    return Number(this.auth.getUserRole())
  }

  deliveredByAbs() {
    if (this.orderDetails.Abstrator_ID) {
      let disposable = this.matDialog.open(RateAbstractorComponent, {data:{
        title: 'Rate Abstractor',
        isConfirmFlag: true,
        message: { Abstrator: this.assignedAbstractor }
      }, ...this.config.getDialogOptions()}).afterClosed()
        .subscribe((res) => {
          if (res && res.state) {
            this.abstractorService.setOrderDelivered(this.orderAbstractor.Order_Abstractor_ID, this.orderDetails.Order_ID, res.rating, res.notes)
              .subscribe(() => {
                this.getOrderTags(this.orderDetails.Order_ID);
                this.getOrderAbstractor(this.orderAbstractor.Abstrator_ID);
                this.getOrderMessages(this.orderDetails.Order_ID);
                if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderDetails.Order_ID);
              }, (err) => {
                this.openErrorPopup(CONSTANTS.apiErrors.orderAbsDelivery);
              })
          }
        });
    }
  }

  getOrderAbstractor(absId) {
    this.abstractorService.getOrderAbstractor(this.orderDetails.Order_ID, absId)
      .subscribe((orderAbstractor) => {
        if (orderAbstractor) this.orderAbstractor = orderAbstractor
      }, (err) => {
        console.log("Err", err);
      })
  }

  showGenerateInvoice(ordDetails) {
    if (((ordDetails.Order_Status == CONSTANTS.orderStatus.cancelled) || (ordDetails.Order_Status == CONSTANTS.orderStatus.completed)) && !this.invoicePresent) return true;
    else return false;
  }

  // reportError() {
  //   this.openErrorReportDialog()
  //     .subscribe((res) => {
  //       if (res && res.state && !res.deleteErrorFlag) {
  //         this.orderService.reportOrderError(this.orderId, res.comment, res.ordErrReportedID)
  //           .subscribe((data) => {
  //             this.getOrderHistoryFromDB(this.orderId);
  //           }, (err) => {
  //             this.openErrorPopup(CONSTANTS.apiErrors.errorReport);
  //           })
  //       } else if (res && res.state && res.deleteErrorFlag) {
  //         this.orderService.deleteOrderError(this.orderId, res.comment, res.ordErrReportedID)
  //           .subscribe((data) => {
  //             this.getOrderHistoryFromDB(this.orderId);
  //           }, (err) => {
  //             this.openErrorPopup(CONSTANTS.apiErrors.deleteReport);
  //           })
  //       }
  //     })
  // }


  navigateToGoogleForms(){
        const date = new Date()
        var estDate: any = momentTimeZone.tz(date, 'America/New_York');
        var estDay = new Date(estDate).getDate();
        var estMonth = new Date(estDate).getMonth() + 1;
        var estYear = new Date(estDate).getFullYear();
       let url =environment.googleFormLink +'viewform?usp=pp_url+ &entry.1676160_day='+estDay+'&entry.1676160_month='+estMonth+'&entry.1676160_year='+estYear+'&entry.1197922528=' + this.orderDetails.Order_ID
       window.open(url,'_blank')
  }

  cancelReminderAlerts(alertFalg = true) {
    this.abstractorService.cancelAbsAlerts(this.orderAbstractor)
      .subscribe((absRes) => {
        if (absRes && absRes.absServerDown) {
          let msgText
          if (alertFalg) {
            msgText = "Service is busy. Please try again after 5-10 mins.";
            this.openErrorPopup(msgText);
          }
          else {
            if (Number(this.orderAbstractor.Order_Abstractor_Reminder)) {
              if (Number(this.orderDetails.Order_Paused_Flag)) {
                msgText = "Order is paused successfully. Please click on cancel alerts button after 5-10 minutes to cancel the alerts.";
                this.openErrorPopup(msgText, "Note");
              }
            }
          }
        } else this.getOrderAbstractor(this.orderAbstractor.Abstrator_ID);
      }, (err) => {
        this.openErrorPopup(err.msg);
      })
  }

  generateReminderAlerts() {
    this.abstractorService.generateAbsAlerts(this.orderAbstractor)
      .subscribe((absRes) => {
        if (absRes && absRes.absServerDown) {
          let msgText = "Service is busy. Please try after 5-10 mins.";
          this.openErrorPopup(msgText);
        } else this.getOrderAbstractor(this.orderAbstractor.Abstrator_ID);
      }, (err) => {
        this.openErrorPopup(err.msg.replace(/\\/g, ","));
      })
  }

  generateNGetOrdInvoice() {
    if (this.invoicePresent) {
      this.orderService.verifyAndGenerateInvoice(this.orderId, null, 'info')
        .subscribe((data) => {
          this.getOrderInvoice();
        }, (err) => {
          this.openErrorPopup(err.msg)
        })
    }
  }

  getCancelOrdInvoice() {
    this.quickbooks.getOrderInvoice(this.orderDetails.Order_ID)
      .subscribe((invoice) => {
        if (invoice) {
          this.invoicePresent = true;
          this.invoiceDetailsInfo = invoice;
          if (this.invoicePresent && this.cancelOrderBool && !this.cancelOrderFlag) {
            this.mail_text = '';
            this.generateInvoice()
          }
          else this.getDocs(this.orderId);
        }
      }, (err) => {
        if (err.code) this.openErrorPopup(err.msg);
      })
  }

  verifyAndGenerateInvoice() {
    return this.orderService.verifyAndGenerateInvoice(this.orderId, null, 'info')
      .subscribe((data) => {
        if (data) this.getOrderInvoice();
      }, (err) => {
        this.openErrorPopup(err.msg)
      })
  }


  uploadInvoiceDocs(invoiceDet) {
    this.orderService.uploadDocumentForCancel(invoiceDet)
      .subscribe((invoiceDetails) => {
        if (this.cancelOrderFlag) {
          this.cancelOrderFlag = false;
          if (this.mail_text != '') this.sendMailCancelOrder();
        }
        this.getDocs(this.orderId);
      })
  }

  sendMailCancelOrder() {
    this.orderService.getOrderDocumentUrls(this.orderDetails.Order_ID)
      .subscribe((urls) => {
        if (urls) {
          var cancelledData = {
            Order_ID: this.orderId,
            Customer_ID: this.orderDetails.Customer_ID,
            Customer_Name: this.orderDetails.User_First_Name,
            Customer_Email: this.orderDetails.User_Login_Name,
            File_ID: this.orderDetails.File_ID,
            Mail_Body: this.mail_text,
            Invoice_Url: urls[0].Link,
            Document_Path: urls[0].Document_Path
          }
          this.orderService.canceledOrderMail(cancelledData)
            .subscribe((result) => { })
        }
      })

  }


  sendBackToScreener() {
    return this.matDialog.open(ConfirmComponent, {data:{
      title: "Send back for screening",
      message: "Do you want to send back this order for screening ?",
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((resp) => {
        if (resp) {
          this.orderService.sendBackToScreener(this.orderId, this.orderDetails.Order_Status, this.orderDetails.Order_Automated)
            .subscribe((res) => {
              this.getOrderDetails(this.orderId);
              this.getOrderMessages(this.orderId);
              this.getOrderHistoryFromDB(this.orderId)
            }, (err) => {
              this.openErrorPopup(CONSTANTS.apiErrors.backToScreener);
            })
        }
      })
  }

  checkIfTrackerProOrderAndContinue(tracker=false) {
    if (!this.orderDetails.Organization_ID) {
      this.isTrackerProOrder = false;
      if(!tracker)this.verifyToComplete()
    }
    else {
      this.organizationService.getOrganizationDetails(this.orderDetails.Organization_ID)
        .subscribe((orgData) => {
          if (orgData.Organization_Type == 2) this.isTrackerProOrder = true;
          else this.isTrackerProOrder = false;
          if(!tracker)this.verifyToComplete();
          else this.getTrackerProRequest();
        })
    }
  }


  getTrackerProRequest() {
    this.orderService.getTrackerProRequest(this.orderDetails.Order_ID)
      .subscribe((request) => {
        if (request) {
          if (request.Tracker_Pro_Request_IsFulfilled == 1 && request.Tracker_Pro_Request_isInvoice_Sent == 1) this.trackerProStatus = 1;
          else if (request.Tracker_Pro_Request_IsFulfilled == 1 && request.Tracker_Pro_Request_isInvoice_Sent == 0) this.trackerProStatus = -1;
          else this.trackerProStatus = 0;
        }
        else this.trackerProStatus = 0;
      })
  }

  submitOrdToTrackerPro() {
    this.adminDocs = this.documents.filter((x) => x.Document_Type == CONSTANTS.orderDocumentType.adminDocs).map((item) => {
      if(item.Doc_Cat_ID){
        let matchedItem =  this.documentCategories.find((cat) => item.Doc_Cat_ID == cat.Id);
        item.Category = matchedItem ? matchedItem.Category : 'Click to categorize';
      }
      else item.Category = 'Click to categorize'
      return item;
    });
    if(this.adminDocs.length > 0 ){
      this.orderService.submitTrackerProOrder({ Order_ID: this.orderDetails.Order_ID, TrackerPro_DocID: this.getDeliverableIds() })
      .subscribe((data) => {
        this.handleSubmitOrderResponse(data);
        this.getTrackerProRequest();
      }, (err) => {
        this.openErrorPopup('Failed to submit order to TrackerPro.', 'TrackerPro Error')
      }) 
    }
    else {
      this.openModal('Submit document to TrackerPro', 'Please add deliverable to submit.');
    }
  }

  getDeliverableIds(){
    if(this.isTrackerProOrder){
      let ids = [];
      this.adminDocs.forEach((doc) => { 
        if(this.invoiceDetailsInfo){
          if((this.invoiceDetailsInfo.Quickbooks_Invoice_Order_Document_ID !== doc.Order_Document_ID)) {
            ids.push(doc.Order_Document_ID)
          }
        } 
        else ids.push(doc.Order_Document_ID)  
      });
      return ids;
    }
    else return null;
  }

  handleSubmitOrderResponse(data) {
    console.log("response", data);
    let submitOrderMsg = '';
    let submitInvoice = '', completeMsg;
    if (data) {
      if (data.newDocuments.isSubmitted && data.newDocuments.data ) {
        submitOrderMsg = 'Documents synced to Trackerpro'
      }
      else submitOrderMsg = 'Order submission to TrackerPro failed';
      if (data.invoice.isSent && data.invoice.data ) {
        submitInvoice = 'TrackerPro invoice generated'
      }
      else submitInvoice = 'Invoice submission to TrackerPro failed'
      completeMsg = submitOrderMsg + ' & ' + submitInvoice + ".";
    }
    else completeMsg = "Order submission to TrackerPro failed."    
    this.matDialog.open(TrackerproResponseHandlerComponent, {data:{
      title: 'TrackerPro - Submit Order',
      message: data,
      text: completeMsg
    }, ...this.config.getDialogOptions()})
  }

  handleSubmitInvoiceResponse(data) {
    let submitInvoice = '';
    if (data) {
      if (data.SubmitInvoiceResult && (data.SubmitInvoiceResult.ClientAccountsPayableID != undefined || data.SubmitInvoiceResult.ClientAccountsPayableID != null)) {
        submitInvoice = data.SubmitInvoiceResult.Message + '.';
      }
      else submitInvoice = 'Invoice submission to TrackerPro failed.'
    }
    else submitInvoice = "Invoice submission failed."
    this.openSuccessModal('Submit Invoice', submitInvoice);
  }

  submitInvToTrackerPro() {
    this.orderService.submitTrackerProInvoice({ Order_ID: this.orderDetails.Order_ID })
      .subscribe((data) => {
        this.handleSubmitInvoiceResponse(data);
        this.getTrackerProRequest();
      }, (err) => {
        this.openErrorPopup('Failed to submit invoice to TrackerPro.', 'TrackerPro Error')
      })
  }

  markOrderAsBillingComplete(){
    this.matDialog.open(ConfirmComponent, {data:{
      title: "Billing Complete",
      message: "Are you sure you want to mark this order as billed? No charges have been attached.",
    },...this.config.getDialogOptions()}).afterClosed()
    .subscribe((resp) => {
      if(resp){
        this.orderService.markOrderBilled(this.orderDetails.Order_ID)
        .subscribe(() => {
          this.router.navigate(['/orders/billing'])
        }, () => {
          this.openErrorPopup('Failed to complete', 'Billing Complete')
        })
      }
    })
  }

  getholidaylistFromLocal(){
    let allHolidays = JSON.parse(sessionStorage.getItem('holidays'))
    if(allHolidays) this.setHoliday(allHolidays)
    else this.getholidaylist();
  }

  setHoliday(holiday){
    this.holidayList = holiday
  }

  getholidaylist(){
    this.holidayScheduling.getAllHolidays()
    .subscribe((holidays)=>{
      if(holidays){
        this.setHoliday(holidays)
        sessionStorage.setItem('holidays', JSON.stringify(holidays));
     setTimeout(function(){sessionStorage.removeItem("holidays");}, CONSTANTS.sessiontimeOut.timeOut);
      }
      // this.holidayList = holidays;
    }, (err) =>{
      console.log("Error",err)
    })
  }

  requestAbsQuote() {
    this.getAbstractorList().subscribe((data) => {
      if(data.length > 0) {
        this.openAbsQuoteModal(data).afterClosed()
          .subscribe((result) => {
            if (result) {
              if (result == -1) this.openNewAbsQuoteModal();
              else if (result.selectedAbstractors.length > 0) this.openAbsQuotePreview(result.selectedAbstractors);
              else console.log("No abs found");
            }
          })
      } else {
        this.openNewAbsQuoteModal();
      }
    }, (err) => {
      console.log(err);
      this.openErrorPopup("Error while fetching data.");
    })
  }

  openNewAbsQuoteModal() {
    return this.matDialog.open(NewAbsQuoteComponent,{data:{
      message: {
        order: this.orderDetails,
        Search_Instructions: this.orderNotes,
        Special_Instructions: this.orderIns 
      }
    }, ...this.config.getDialogOptions()}).afterClosed()
    .subscribe((result) => {
      if (result) {
        this.successMsgFunc('Request abstractor quote sent successfully.');
        this.getOrderDetails(this.orderDetails.Order_ID);
        this.getOrderTags(this.orderDetails.Order_ID);
        if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
      }
    })
  }


  openAbsQuoteModal(abstractors) {
    return this.matDialog.open(AbstractorQuoteComponent, {data:{
      title: 'Abstractors list',
      message: { Order: this.orderDetails, Abstractors: abstractors }
    }, ...this.config.getDialogOptions()})
  }

  openAbsQuotePreview(selectedAbstractors) {
    let disposable = this.matDialog.open(AbsQuoteMessageComponent, {data:{
      message: {
        orderDetails: this.orderDetails, selectedAbstractors: selectedAbstractors, instructions: { Search_Instructions: this.orderNotes, Special_Instructions: this.orderIns }
      }
    }, ...this.config.getDialogOptions()}).afterClosed()
      .subscribe((res) => {
        if (res && res.state) {
          let Product_Description = this.orderDetails.Product_Description
          if(Product_Description.includes('Plus')){
            var lastIndex = Product_Description.lastIndexOf(" ");
            Product_Description = Product_Description.substring(0, lastIndex);
          }
          this.abstractorService.requestAbsQuote({
            Order_ID: this.orderDetails.Order_ID,
            selectedAbstractors: selectedAbstractors,
            Message_Text: res.absQuoteMessage,
            Message_Subject: 'Pippin Title Order #'+ this.orderDetails.Order_ID+' - ' +Product_Description+' Quote/ETA Request - '+this.orderDetails.Property_County+' County, '+this.orderDetails.Property_State_Abbr,
            fromEmailId:res.selectedEmailId
          })
            .subscribe((respones) => {
              this.successMsgFunc('Request abstractor quote sent successfully.')
              if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
              this.getOrderDetails(this.orderDetails.Order_ID);
              this.getOrderTags(this.orderDetails.Order_ID);
            }, (err) => {
              this.openErrorPopup("Error while sending the request quote to the abstractor.");
            })
        }
      })
  }

  

  checkAdminType() {
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else return true;
  }

  getAllOrderStatus(orderId){
    this.orderService.getAllOrderStatus(orderId)
    .subscribe((orderStatusDet) => {
      this.allOrderStatus = orderStatusDet;
    })
  }
  
  checkOrderIsConfirmed() {
    let confirmedOrdIndex = this.allOrderStatus.findIndex((data) => data.Status_New === CONSTANTS.orderStatus.confirmed)
    if(confirmedOrdIndex > -1) return true;
    else return false;
  }
  checkForNameSearch(){
    if(this.orderDetails.Product_ID == environment.nameSearchProductId)return true;
    else return false;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

 
  getBorrowerDetails(orderId){
    this.orderService.getAllBorrowerDetails(orderId)
    .subscribe((borrowerData) => {
    this.borrowerDetails = borrowerData
    this.copyBorrowerDetails = borrowerData;
    this.getRowDataForBorrower(this.borrowerDetails)
  })
  }

  getRowDataForBorrower(borrowerData){
    if(this.rowData && this.rowData.length > 0) this.rowData.length = 0;
    let borrowerDataArr =[], coBorrowerDataArr = [];
    borrowerData.forEach((borrower) => {
      if(borrower.Order_Borrower_Priority == 1) borrowerDataArr.push(this.getRowObjectData(borrower))
      else coBorrowerDataArr.push(this.getRowObjectData(borrower))
    })
    this.rowData.push(borrowerDataArr, coBorrowerDataArr);
    this.rowData = _.flatten(this.rowData);
  }

  getRowObjectData(borrowerData){
    let rowDataObject = {
      Order_Borrower_ID:borrowerData.Order_Borrower_ID,
      Order_Borrower_Name:borrowerData.Order_Borrower_Name,
      Property_Address:this.getCompleteAddress(borrowerData.Property),
      Order_Borrower_SSN:borrowerData.Order_Borrower_SSN,
      Order_Borrower_Finding:borrowerData.Order_Borrower_Finding
    }
    return rowDataObject;
  }
  getCompleteAddress(propertyData){
    if(propertyData == null) return '-';
    else{
      let propertyAddress = '';
      if (propertyData.Property_Address_1) propertyAddress += propertyData.Property_Address_1 += ','
      if (propertyData.Property_Address_2) {
        if (propertyAddress) propertyAddress += '\n'
        propertyAddress += propertyData.Property_Address_2
      }
      if (propertyData.Property_City) {
        if (propertyAddress) propertyAddress += '\n'
        propertyAddress += propertyData.Property_City
      }
      if (propertyData.Property_State_Abbr) {
        if (propertyAddress) propertyAddress += ', '
        propertyAddress += propertyData.Property_State_Abbr
      }
      if (propertyData.Property_ZipCode) {
        if (propertyAddress) propertyAddress += ' ,'
        propertyAddress += propertyData.Property_ZipCode
      }
      return propertyAddress;
    }
  }
  cellValueChanged(event){
    if(this.checkIfValueRSame(event.newValue,event.data.Order_Borrower_ID)){
      this.orderService.updateBorrowerFindings(event.data.Order_Borrower_ID,event.newValue)
      .subscribe(()=>{
        this.getBorrowerDetails(this.orderId)
      })
    } 
  }

  checkIfValueRSame(newValue, borrowerId){
   return this.copyBorrowerDetails.some((borrower)=> borrower.Order_Borrower_ID === borrowerId && borrower.Order_Borrower_Finding !==newValue)
  }

  tooltipRenderer = function(params)
  {
      return '<span title="' + params.value + '">'+params.value+'</span>';
  };


  createBorrowerCol() {
    return [
      {
        headerName: 'BorrowerId',
        field: 'Order_Borrower_ID',
        hide:true
      },
      {
        headerName: 'Name',
        field: 'Order_Borrower_Name',
        width: 300,
      },
      {
        headerName: 'Address',
        field: 'Property_Address',
        width: 450,
      },
      {
        headerName: 'Last 4 SSN',
        field: 'Order_Borrower_SSN',
        width: 150
      },
      {
        headerName: 'Findings',
        field: 'Order_Borrower_Finding',
        editable: true,
        width: 850,
        flex: 1,
        cellEditor: 'agLargeTextCellEditor',
         cellRendererFramework: AgGridTooltipComponent,
         cellRendererParams: (params: ICellRendererParams) => this.formatToolTip(params.data),
        cellEditorParams: {
          maxLength: 65535,
          cols: '110',
          rows: '5',
      },
      }
      ]
  }
 
  formatToolTip(params: any) {
    const first = params.Order_Borrower_Finding
    const toolTipArray = [first]
    return { toolTipArray }
  }

  checkForMenuActive(){
    if(this.config.getMySidebar()) return true;
    else return false;
  }
  isSuperAdmin(){
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else if(this.auth.getUserId() == environment.superAdminId) return true;
    else if(Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin) return true;
    else return false;
  }

  getOrderTotalPrice(){
    let orderTotal = 0;
    this.orderProducts.forEach((orderProduct) => {
      if(orderProduct.Order_Product_Status > 0){
        orderTotal += Number(orderProduct.Order_Product_Price);
      }
    })
    return orderTotal;
  }

  goToReportBuilder(){
    this.router.navigate(['/orders/'+ this.orderDetails.Order_ID +'/report-builder']);
  }

  createXML() {
    this.orderService.createXML(this.orderDetails)
      .subscribe((blobData) => {
        if (blobData) this.composeAndDownload(blobData, this.orderDetails.Order_ID);
      }, (err) => {
        this.openErrorPopup('Error while fetching data.');
      })
  }

  composeAndDownload(blobData, orderId) {
     let file = new Blob([blobData], { type: 'application/octet-stream' });
    var fileUrl = URL.createObjectURL(file);
    var link = document.createElement("a");
    link.setAttribute("href", fileUrl);
    link.setAttribute("download", "Report_" + orderId + ".xml");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } 

  completeReswareOrder(){
    if(this.orderDetails.Source_Type_ID){
      let matchedSource = this.orderSources.find((source) => source.Source_Type_ID == this.orderDetails.Source_Type_ID)
      if(matchedSource && (matchedSource.Source_Type_Name && matchedSource.Source_Type_Name.toLowerCase() == 'resware')){
        this.userMsg.setQueueMsg('Search Data submission to Resware is in progress...');      
        this.orderService.completeReswareOrder({Order_ID : this.orderDetails.Order_ID, Admin_ID: this.auth.getUserId() })
          .subscribe((data) => {
            this.getOrderHistoryFromDB(this.orderDetails.Order_ID);
            // this.openSuccessModal('Resware order submit response', data && data.Message ? data.Message : 'Search Data received successfully on remote file');
          }, (err) => {
            // this.openErrorPopup(err.msg);
            this.getOrderHistoryFromDB(this.orderDetails.Order_ID);
          })
      }
      else this.userMsg.setErrorMsg("Order is not linked to Resware. Please check if the order was manually created.")
    }    
  }

  completeQualiaOrder(){
    if(!this.orderDetails.Order_Qualia_Internal_ID) this.userMsg.setErrorMsg(`Order is not linked to Qualia. Please add the Qualia Internal ID by going to Edit Order to the order details before trying again.`);
    else {
      this.orderService.getQualiaCommitmentTypes(this.orderDetails.Order_Qualia_Internal_ID)
      .subscribe((commitmentData) => {                
        this.userMsg.setQueueMsg('Title Commitment submission request placed on Qualia file');
        this.orderService.completeQualiaOrder({ Order_ID : this.orderDetails.Order_ID, Admin_ID: this.auth.getUserId() })
          .subscribe((data) => {
            this.getOrderHistoryFromDB(this.orderDetails.Order_ID);
            // this.openSuccessModal('Resware order submit response', data && data.Message ? data.Message : 'Search Data received successfully on remote file');
          }, (err) => {
            this.getOrderHistoryFromDB(this.orderDetails.Order_ID);
            // this.openErrorPopup(err.msg);
          })
      },() => {
        this.userMsg.setErrorMsg(`Qualia Internal ID is invalid. Please verify and update the Qualia Internal ID by going to Edit Order to the order details before trying again.`);
      })
    }    
  }

  searchReportPrefillWarning(orderId, event){
    event.stopPropagation();
    if (this.orderDetails.Report_Version) {
      this.reportBuiderService
        .getAllVersions(this.orderDetails.Order_ID)
        .subscribe((data) => this.checkReportVersions(data, orderId));
    } else this.processPrefillReq(orderId);
  }

  checkReportVersions(versions, orderId){    
    if(versions.length > 1){
      this.matDialog.open(SuccessComponent, {data:{
        title: 'Prefill Report Data',
        message: 'This order already has multiple versions of the report!'
      }, ...this.config.getDialogOptions()})

    }
    else if(versions.length == 1){            
      this.matDialog.open(ConfirmComponent, {data:{
        title: 'Prefill Report Data',
        message: 'This WILL OVERWRITE all contents entered into the report builder. Would you like to proceed?'
      }, ...this.config.getDialogOptions()}).afterClosed()
        .subscribe((res) => {
          if (res) this.processPrefillReq(orderId);      
      })
    }      
    else this.processPrefillReq(orderId);  
  }

  processPrefillReq(orderId){
    this.reportBuiderService.prefillReportBuilder(orderId, this.orderDetails)
      .subscribe((data) => {
        this.goToReportBuilder();
      }, (err) => {
        this.openErrorPopup(err.msg);
      })    
  }

  loadOrderEscalationData(data) {
    if (data.Order_Escalated == CONSTANTS.orderPriority.level2) {
      this.isEscalatedOrder = true;
    } else {
      this.isEscalatedOrder = false;
    }
  }

  handleEscalation(event) {
    this.orderService.setEscalationForRushOrders(this.orderId, this.isEscalatedOrder)
      .subscribe((details) => {
        if (!this.checkIfSearchMaster()) this.getOrderHistoryFromDB(this.orderId);
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.orderEscalation);
        this.isEscalatedOrder = !this.isEscalatedOrder;
      })
  }

  getOrderSourceTypes(){
    this.orderService.getOrderSourceList()
    .subscribe((list) => {
      this.orderSources = list;
    }, (err) => {
      this.openErrorPopup(err.msg);
    })
  }

  getOrderSource(){
    this.orderService.getOrderSource(this.orderId)
    .subscribe((type) => {
      this.orderDetails['Source_Type_ID'] = type ? type.Source_Type_ID : null;
    }, (err) => {
      this.openErrorPopup(err.msg);
    })
  }

  isNumeric(value) {
    return !isNaN(value);
  }  

  getDefaultCategory(){
    let defItem = this.documentCategories.find((item) => item.Category.toLowerCase() == 'misc')
    return defItem ? defItem.Id: null;
  }

  copyURL(document){
    this.s3Service.observePresignedURL(document.Document_Path, document.Document_Original_Name);
  }

  validDate(str){
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    return isoDatePattern.test(str)
  }
  
}
