import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UntypedFormGroup, Validators, UntypedFormBuilder } from "@angular/forms";
import {
  ProgressBarService,
  AuthService,
  NewOrderService,
  OrdersService,
  UsersService,
  PricingService,
  ConfigService,
  OrganizationService,
} from "../../../services";

import { DialogService } from "ng2-bootstrap-modal";
import { Location } from "@angular/common";
import { CONSTANTS } from "../../../app.constants";
import { ErrorDialogComponent } from "../../../dialog/error-dialog/error-dialog.component";
import * as _ from "underscore";
import { MatSelectSearchComponent } from "app/common/mat-select-search/mat-select-search.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-new-order1",
  templateUrl: "./new-order1.component.html",
  styleUrls: ["./new-order1.component.scss"],
})
export class NewOrder1Component implements OnInit {
  @ViewChild("productsDropDown") productsDropDown: ElementRef;
  @ViewChild("myInputCustomer") myInputCustomer: ElementRef;
  @ViewChild("myInputProduct") myInputProduct: ElementRef;

  newOrderForm: UntypedFormGroup;
  newOrderModel: any = {
    Customer_ID: null,
    Organization_ID: null,
  };
  error: String = "";
  states = [];
  counties = [];
  googlePlaceState = "";
  errors: any = {};
  primaryColor = "#0F9D58";
  latitude: any;
  longitude: any;
  customers = [];
  fieldValidate: boolean = false;
  products = [];
  input = "";
  constants = CONSTANTS;
  selectedCustomers = [];
  selectedProducts = [];
  timer: any = [];
  getUSDate = new Date("01/02/1500").toLocaleString("en-US", {
    timeZone: "America/New_York",
  });
  min_Date = new Date(this.getUSDate);
  isQualiaOrder: boolean = true;
  constructor(
    private frmBuilder: UntypedFormBuilder,
    private progressServ: ProgressBarService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private auth: AuthService,
    private newOrder: NewOrderService,
    private orderService: OrdersService,
    private route: ActivatedRoute,
    private matDialog:MatDialog,
    private pricingService: PricingService,
    private userService: UsersService,
    private location: Location,
    private config: ConfigService,
    private organizationService: OrganizationService
  ) {
    this.newOrderForm = frmBuilder.group({
      Property_Order_Number: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(255)]),
      ],
      Order_Qualia_Internal_ID: [
        null,
        Validators.compose([Validators.maxLength(127)]),
      ],

      Order_Type: [null, Validators.required],

      Order_Mortgage_Amount: [
        null,
        Validators.compose([Validators.maxLength(14)]),
      ],
      Order_Mortgage_Date: [{ disabled: false, value: "" }],
      Order_Product_Custom_Name: [null],
      Customer_ID: [null, Validators.required],
      Property_Cust_File_Number: [
        null,
        Validators.compose([Validators.maxLength(127)]),
      ],
      Property_Order_Purpose: [
        null,
        Validators.compose([Validators.maxLength(127)]),
      ],
    });
    this.newOrderForm.valueChanges.subscribe((data) => {
      console.log({ formValues: data });
      this.error = "";
    });
  }

  ngOnInit() {
    this.checkForReset();

    this.progressServ.setPrimaryColor(this.primaryColor);
    this.progressServ.setHeight(2);
    this.progressServ.setStage(0);
    this.progressServ.setTotalStages(2);
    this.progressServ.setProgressState((0 / 2) * 100);
    this.progressServ.setBarType(1);
    this.newOrderModel.Order_Source_Type_ID = 5;
    this.pricingService.getAvailableStateList().subscribe((states) => {
      if (states) this.states = states;
    });
    const step1data = this.newOrder.getStep1Data();
    if (step1data) {
      this.newOrderModel = Object.assign({}, step1data);
      this.selectedProducts = step1data.allProducts ? step1data.allProducts: step1data.Order_Products;
      if (this.newOrderModel.Property_Latitude)
        this.latitude = this.newOrderModel.Property_Latitude;
      if (this.newOrderModel.Property_Longitude)
        this.longitude = this.newOrderModel.Property_Longitude;
      if (this.newOrderModel.Order_Product_Custom_Name)
        this.longitude = this.newOrderModel.Order_Product_Custom_Name;
    }

    this.timer.push(
      setTimeout(() => {
        this.config.setSideBarNode(0);
      })
    );
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    this.timer.forEach((time) => {
      clearTimeout(time);
    });
  }

  checkForReset() {
    if (!this.route.snapshot.params["back"]) {
      this.newOrder.resetAll();
    }
  }

  getUserRole() {
    return Number(this.auth.getUserRole());
  }

  validateDateFn(event) {
    var DATE_REGEXP =
      /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/; //for m/d/yyyy and mm/dd/yyyy
    if (
      event.targetElement.value &&
      !DATE_REGEXP.test(event.targetElement.value)
    )
      this.errors.Order_Mortgage_Date = true;
    else this.errors = {};
  }

  getIfErrors() {
    return Object.keys(this.errors).length;
  }

  getCountyList(event) {
    this.clearForms(null);
    if (event) {
      this.pricingService
        .getAvailableCountyList(this.newOrderModel.Property_State_Abbr)
        .subscribe((counties) => {
          this.counties = counties;
          this.ref.detectChanges();
        });
    }
  }

  isCustomSearch() {
    if (this.newOrderModel.BaseProduct == 999) return true;
    else return false;
  }

  display() {
    console.log("prodct", this.newOrderModel.BaseProduct);
    if (this.newOrderModel.BaseProduct == 999) {
      this.newOrderForm.controls["Order_Product_Custom_Name"].reset();
      this.newOrderForm.controls["Order_Product_Custom_Name"].setValidators([
        Validators.required,
        Validators.maxLength(255),
      ]);
      this.newOrderForm.controls[
        "Order_Product_Custom_Name"
      ].updateValueAndValidity();
    } else {
      this.newOrderModel.Order_Product_Custom_Name = "";
      this.newOrderForm.controls["Order_Product_Custom_Name"].clearValidators();
      this.newOrderForm.controls[
        "Order_Product_Custom_Name"
      ].updateValueAndValidity();
    }
  }

  validateForm() {
    this.errors = {};
    if (!this.newOrderModel.Property_Order_Number)
      this.errors["Property_Order_Number"] = true;
    if (Object.keys(this.errors).length == 0) {
      console.log("All are ok..");
      this.newOrder
        .checkIfQualaiUser(this.newOrderModel.Customer_ID)
        .subscribe((data) => {
          this.newOrderModel.Qualia_Fee_Add = data && data.status ? 1 : 0;
          this.goToStep2();
        });
    } else {
      console.log("error keys", Object.keys(this.errors));
      this.errors["Minimun_Mandatory_Fields"] = true;
    }
    console.log("errors", this.errors);
  }

  resetOrderData(event) {
    console.log("event", event);
    if (event) {
      let indexComp = event.split(":");
      this.newOrderModel.Customer_ID = indexComp[0];
      this.newOrderModel.Organization_ID =
        indexComp[1] == "null" ? null : indexComp[1];
      this.getOrderProductsForClient(
        this.newOrderModel.Customer_ID,
        this.newOrderModel.Organization_ID
      );
      this.checkForQualiaClient(
        this.newOrderModel.Customer_ID,
        this.newOrderModel.Organization_ID
      );
    }
  }

  resetOrderDataV2(event) {
    if (event) {
      this.newOrderModel.Customer_ID = event.User_ID;
      this.newOrderModel.Organization_ID = event.Organization_ID;
      this.newOrderModel.customerInfo = event;
      this.newOrder.setStep1data(this.newOrderModel);
      let customerId = this.newOrderModel.Customer_ID;
      if(this.newOrderModel.Organization_ID) customerId = this.newOrderModel.Organization_ID;
      this.orderService
        .getOrderProductsForClient(customerId)
        .subscribe((products) => {
          this.products = products;
          this.selectedProducts = this.products;
          this.newOrderModel.allProducts = products
          if (this.newOrderModel.BaseProduct) {
            if (
              !this.selectedProducts.some(
                (product) =>
                  product.Product_ID == this.newOrderModel.BaseProduct
              )
            )
              this.newOrderModel.BaseProduct = null;
          }
        });

      if (event.Organization_ID) {
        this.organizationService
          .getOrganizationDetails(event.Organization_ID)
          .subscribe(
            (orgDetails) => {
              if (orgDetails && orgDetails.Organization_Type == 1)
                this.isQualiaOrder = true;
              else this.isQualiaOrder = false;
            },
            (err) => {
              console.log("Error fetching org details");
            }
          );
      }
    }
  }

  getOrderProductsForClient(customerId, orgId) {
    let customerIndex = this.selectedCustomers.findIndex(
      (customer) =>
        customer.User_ID == customerId && customer.Organization_ID == orgId
    );
    if (customerIndex > -1) {
      let userId;
      if (this.customers[customerIndex].Organization_ID) {
        userId = this.selectedCustomers[customerIndex].Organization_ID;
        this.newOrderModel.Organization_ID =
          this.selectedCustomers[customerIndex].Organization_ID;
      } else {
        userId = this.selectedCustomers[customerIndex].User_ID;
        this.newOrderModel.Organization_ID = null;
      }
      this.orderService
        .getOrderProductsForClient(userId)
        .subscribe((products) => {
          this.products = products;
          this.selectedProducts = this.products;
          if (this.newOrderModel.BaseProduct) {
            if (
              !this.selectedProducts.some(
                (product) =>
                  product.Product_ID == this.newOrderModel.BaseProduct
              )
            )
              this.newOrderModel.BaseProduct = null;
          }
        });
    }
  }

  checkForQualiaClient(customerId, orgId) {
    let customerIndex = this.selectedCustomers.findIndex(
      (customer) =>
        customer.User_ID == customerId && customer.Organization_ID == orgId
    );
    if (customerIndex > -1) {
      let orgId = this.selectedCustomers[customerIndex].Organization_ID;
      if (orgId) {
        this.organizationService.getOrganizationDetails(orgId).subscribe(
          (orgDetails) => {
            if (orgDetails && orgDetails.Organization_Type == 1)
              this.isQualiaOrder = true;
            else this.isQualiaOrder = false;
          },
          (err) => {
            console.log("Error fetching org details");
          }
        );
      }
    }
  }

  goToStep2() {
    this.newOrderModel.Property_Latitude = this.latitude;
    this.newOrderModel.Property_Longitude = this.longitude;
    if (
      !(
        this.newOrderModel.Order_Products &&
        this.newOrderModel.Order_Products[0].Order_Product_ID
      )
    ) {
      var orderProd = {};
      orderProd["Product_ID"] = this.newOrderModel.BaseProduct;
      orderProd["Order_Product_Custom_Name"] =
        this.newOrderModel.Order_Product_Custom_Name;
      orderProd["Order_Product_Status"] = 1;
      orderProd["Cost_Amount"] = "";
      orderProd["Final_Cost"] = "";
      let selectedProdFindIndex = this.selectedProducts.findIndex(
        (product) => product.Product_ID === this.newOrderModel.BaseProduct
      );
      orderProd["Product_Description"] =
        this.selectedProducts[selectedProdFindIndex].Product_Description;
      orderProd["Product_Name"] =
        this.selectedProducts[selectedProdFindIndex].Product_Name;
      this.newOrderModel.Order_Products = [];
      this.newOrderModel.Order_Products.push(orderProd);
    } else {
      this.newOrderModel.Order_Products[0]["Product_ID"] =
        this.newOrderModel.BaseProduct;
      this.newOrderModel.Order_Products[0]["Order_Product_Custom_Name"] =
        this.newOrderModel.Order_Product_Custom_Name;
    }

    console.log("Step 1", this.newOrderModel);
    this.newOrder.setStep1data(this.newOrderModel);
    this.router.navigate(["/customer/orders/new/step2"]);
  }

  getCustomers() {
    this.userService.getAllCustomers().subscribe(
      (result) => {
        if (result) {
          this.customers = this.attachCompKeytoCustomerList(result);
          this.selectedCustomers = this.customers;
          if (
            this.newOrderModel.Customer_ID &&
            this.newOrderModel.hasOwnProperty("Organization_ID")
          )
            this.getOrderProductsForClient(
              this.newOrderModel.Customer_ID,
              this.newOrderModel.Organization_ID
            );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getAllCustomerForSearcher() {
    this.userService.getAllCustomerForSearcher().subscribe(
      (result) => {
        if (result) {
          this.customers = this.attachCompKeytoCustomerList(result);
          this.selectedCustomers = this.customers;
          if (
            this.newOrderModel.Customer_ID &&
            this.newOrderModel.hasOwnProperty("Organization_ID")
          )
            this.getOrderProductsForClient(
              this.newOrderModel.Customer_ID,
              this.newOrderModel.Organization_ID
            );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  attachCompKeytoCustomerList(list) {
    let newList = list.map((user) => {
      user.Index = `${user.User_ID}:${user.Organization_ID}`;
      return user;
    });
    return newList;
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog
      .open(
        ErrorDialogComponent,
        {data:{
          message: msg,
        },
        ...this.config.getDialogOptions()}
      )
  }

  clearForms(event) {
    if (event) {
      this.getCountyList(event);
      if (event != this.googlePlaceState) {
        // this.newOrderModel.Property_County=''
      }
    }
    this.errors = {};
  }

  goBack() {
    if (this.checkIfSearchMaster()) this.router.navigate(["/orders/new"]);
    else this.router.navigate(["/orders/inprocess"]);
  }

  checkIfSearchMaster() {
    if (Number(this.auth.getUserRole()) != CONSTANTS.userRole.searchMaster)
      return true;
    else return false;
  }

  getAmountFormat(e, newOrder) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if (this.input.length == 0 && e.key == "0") e.preventDefault();
    else {
      if (
        (e.keyCode == 8 || e.keyCode == 46) &&
        (this.input.length > 0 ||
          this.input.length < this.config.getLimitPriceMaxLength())
      ) {
        this.input = this.input.slice(0, this.input.length - 1);
        e.target.value = this.numberFormat(this.input);
        newOrder.Order_Mortgage_Amount = e.target.value;
      } else if (this.input.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          this.input = this.input + key;
          e.target.value = this.numberFormat(this.input);
          newOrder.Order_Mortgage_Amount = e.target.value;
        }
      }
      return false;
    }
  }

  numberFormat(val) {
    if (isNaN(parseFloat(val))) return null;
    var num = parseFloat(val);
    return (num / 100).toFixed(2);
  }

  keyValue(keyCode) {
    if (keyCode > 57) keyCode -= 48;
    if (keyCode >= 48 && keyCode <= 57) return String.fromCharCode(keyCode);
  }

  searchOptions(query, type: string, id: string) {
    if (query.keyCode == 32 && query.type == "keydown") query.stopPropagation();
    switch (type) {
      case "customer":
        this.selectedCustomers = this.select(
          query.target.value,
          this.customers,
          ["Organization_Name", "User_Full_Name", "Email_Address"],
          id
        );
        break;
      case "product":
        this.selectedProducts = this.select(
          query.target.value,
          this.products,
          "Product_Description",
          id
        );
        break;
      default:
        console.log("Invalid");
    }
  }

  select(query: string, entities, key, id): string[] {
    let result: string[] = [];
    if (_.isArray(key)) {
      for (let a of entities) {
        var matched = null;
        for (let k of key) {
          if (
            a[k] &&
            a[k].toLowerCase().indexOf(query.toLowerCase().trim()) > -1
          ) {
            if (matched == null || (matched && matched[id] != a[id]))
              matched = a;
          }
        }
        if (matched) result.push(matched);
      }
    } else if (_.isString(key)) {
      for (let a of entities) {
        if (a[key].toLowerCase().indexOf(query.toLowerCase().trim()) > -1) {
          result.push(a);
        }
      }
    } else console.log("Invalid search key");
    return result;
  }

  clearValue(myInput, key) {
    if (myInput.nativeElement) myInput.nativeElement.value = "";
    else myInput.value = "";
    if (key == "customer") this.selectedCustomers = this.customers;
    else if (key == "product") this.selectedProducts = this.products;
    else console.log("Invalid");
  }

  handleCustDrpDwnPanelEvents(event) {
    if (event)
      this.timer.push(
        setTimeout(() => this.myInputCustomer.nativeElement.focus(), 0)
      );
    else this.clearValue(this.myInputCustomer, "customer");
  }

  handleProductDrpDwnPanelEvents(event) {
    if (event)
      this.timer.push(
        setTimeout(() => this.myInputProduct.nativeElement.focus(), 0)
      );
    else this.clearValue(this.myInputProduct, "product");
  }

  getPippinDate(event) {
    if (event && event.Pippin_Date) {
      this.newOrderModel["Order_Mortgage_Date"] = event.Pippin_Date;
    } else {
      this.newOrderModel["Order_Mortgage_Date"] = null;
    }
  }
}
