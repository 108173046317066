import { pairwise, filter } from "rxjs/operators";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import {
  ReactiveFormsModule,
  FormsModule,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
} from "@angular/forms";
import {
  ProgressBarService,
  AuthService,
  NewOrderService,
  PricingService,
  ConfigService,
  UsersService,
  OrganizationService
} from "../../../../services";
import { AutogrowDirective } from "../../../../directives";
import { CONSTANTS } from "../../../../app.constants";
import { DialogService } from "ng2-bootstrap-modal";
import { MessageComponent } from "../../../../dialog/message/message.component";
import { SuccessComponent } from "../../../../dialog/success/success.component";
import { Observable } from "rxjs";
import { ErrorDialogComponent } from "../../../../dialog/error-dialog/error-dialog.component";
import { Location } from "@angular/common";
import { FileService } from "../../../../services";
import { UUID } from "angular2-uuid";
import * as _ from "underscore";
import { MatSelect } from "@angular/material/select";
import { MatDialog } from "@angular/material/dialog";

declare var google: any;

@Component({
  selector: "app-quote-request1",
  templateUrl: "./quote-request1.component.html",
  styleUrls: ["./quote-request1.component.scss"],
})
export class QuoteRequest1Component implements OnInit {
  @ViewChild("firstFocus") firstFocus: MatSelect;
  @ViewChild("fileInput") fileInput: ElementRef;
  @ViewChild("myInputState") myInputState: ElementRef;
  @ViewChild("myInputState1") myInputState1: ElementRef;
  @ViewChild("myInputState2") myInputState2: ElementRef;
  @ViewChild("myInputCounty") myInputCounty: ElementRef;
  @ViewChild("myInputCounty1") myInputCounty1: ElementRef;
  @ViewChild("myInputCounty2") myInputCounty2: ElementRef;

  newQuoteForm: UntypedFormGroup;
  quoteModel: any = {
    Customer_ID: null,
    Organization_ID: null,
  };
  error: String = "";
  errors: any = {};
  fieldError = [];
  searchError: boolean = false;
  states = [];
  counties = [];
  selectedStates = [];
  selectedCounties = [];
  primaryColor = "#0F9D58";
  googlePlaceState = "";
  latitude: any;
  longitude: any;
  documents = [];
  selectedCriteria: any;
  selectedOption: string;
  resetScreenData = true;
  input = "";
  checkAvlCounty: any;
  timer: any = [];
  selectedCustomers = [];
  customers = [];
  showCityInCounty: boolean = false;
  allIndepdentCities: any;
  isQualiaOrder: boolean = false;
  constructor(
    private frmBuilder: UntypedFormBuilder,
    private progressServ: ProgressBarService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private auth: AuthService,
    private newOrder: NewOrderService,
    private pricingService: PricingService,
    private route: ActivatedRoute,
    private matDialog:MatDialog,
    private location: Location,
    private fileService: FileService,
    private config: ConfigService,
    private userService: UsersService,
    private organizationService: OrganizationService
  ) {
    this.newQuoteForm = frmBuilder.group({
      Order_Co_Buyer: [null, Validators.compose([Validators.maxLength(127)])],
      Order_Buyer: [null, Validators.compose([Validators.maxLength(127)])],
      Property_Address_1: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(127)]),
      ],
      Property_Address_2: [null, Validators.maxLength(127)],
      Property_City: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(127)]),
      ],
      Property_State_Abbr: new UntypedFormControl(
        { value: null, disabled: false },
        Validators.required
      ),
      Property_County: new UntypedFormControl(
        { value: null, disabled: false },
        Validators.required
      ),
      Property_ZipCode: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(11)]),
      ],
      Property_First_Name: [null, Validators.maxLength(127)],
      Property_Order_Number: [null, Validators.maxLength(255)],
      searchValue: [null],
      radioBtn: [null],
      Order_Type: [null, Validators.required],
      Order_Parcel: [null, Validators.compose([Validators.maxLength(255)])],
      Order_Loan: [null, Validators.compose([Validators.maxLength(255)])],
      Order_Book: [null, Validators.compose([Validators.maxLength(255)])],
      Order_Page: [null, Validators.compose([Validators.maxLength(255)])],
      Customer_ID: [null, Validators.required],
      Order_Qualia_Internal_ID: [
        null,
        Validators.compose([Validators.maxLength(127)]),
      ],
      Property_Cust_File_Number: [
        null,
        Validators.compose([Validators.maxLength(127)]),
      ],
      Property_Order_Purpose: [
        null,
        Validators.compose([Validators.maxLength(127)]),
      ],
      Order_Mortgage_Amount: [
        null,
        Validators.compose([Validators.maxLength(14)]),
      ],
      Order_Mortgage_Date: [{ disabled: false, value: "" }],
      Order_Subdivision: [null, Validators.compose([Validators.maxLength(255)])],
    });
    this.newQuoteForm.valueChanges.subscribe((data) => {
      this.error = "";
    });
    if (Number(this.auth.getUserCode()) == CONSTANTS.userStatus.deActivated) {
      this.router.navigate(["/orders/new"]);
    }
  }

  ngOnInit() {
    this.checkForReset();
    this.getCustomers();
    this.getCountyForIndependCity();
    this.progressServ.setPrimaryColor(this.primaryColor);
    this.progressServ.setHeight(2);
    this.progressServ.setStage(0);
    this.progressServ.setTotalStages(3);
    this.progressServ.setProgressState((0 / 3) * 100);
    this.progressServ.setBarType(1);
    this.pricingService.getStateList().subscribe((states) => {
      if (states) {
        var tempStates = [];
        states.forEach((state) =>
          tempStates.push({ State_Short_Name: state[0].toUpperCase() })
        );
        this.states = tempStates;
        this.selectedStates = tempStates;
      }
    });
    this.quoteModel.Property_State_Abbr = "";
    this.quoteModel.orderDocs = [];
    this.quoteModel.orderDocId = [];
    if (this.newOrder.getselectedOptionQReq())
      this.selectedOption = this.newOrder.getselectedOptionQReq();
    else this.selectedOption = "Auto Search";

    this.selectedCriteria = this.newOrder.getSelectedCriteriaQReq();

    if (this.newOrder.getquoteData1()) {
      this.resetScreenData = false;
      this.quoteModel = Object.assign({}, this.newOrder.getquoteData1());
      this.latitude = this.quoteModel.Property_Latitude;
      this.longitude = this.quoteModel.Property_Longitude;
    }
    this.timer = setTimeout(() => this.config.setSideBarNode(0), 0);
  }

  checkForReset() {
    console.log(
      "looking for url param back",
      this.route.snapshot.params["back"]
    );
    if (!this.route.snapshot.params["back"]) {
      this.newOrder.resetAllQReq();
    }
  }

  ngAfterViewInit() {
    let searchBox: any = document.getElementById("search-box");
    let options = {
      types: [
        // return only geocoding results, rather than business results.
        "geocode",
      ],
    };
    var autocomplete = new google.maps.places.Autocomplete(searchBox, options);
    // Add listener to the place changed event
    autocomplete.addListener("place_changed", () => {
      this.getPlaceDetails(autocomplete.getPlace());
    });
  }

  validateForm() {
    console.log("order MODEL: ", this.quoteModel);
    console.log("order type: ", this.selectedCriteria);
    this.errors = {};
    if (!this.quoteModel.Property_State_Abbr) this.errors["State"] = true;
    if (!this.quoteModel.Property_County) this.errors["County"] = true;
    if (!this.quoteModel.Customer_ID) this.errors["Customer"] = true;
    if (this.selectedCriteria == 1) {
      if (!this.quoteModel.Property_Address_1)
        this.errors["Property Address"] = true;
      if (!this.quoteModel.Property_City) this.errors["City"] = true;
      if (!this.quoteModel.Property_ZipCode) this.errors["ZipCode"] = true;
    } else if (this.selectedCriteria == 2) {
      if (!this.quoteModel.Order_Buyer) this.errors["Buyer"] = true;
    } else if (this.selectedCriteria == 3) {
      if (!this.quoteModel.Order_Parcel) this.errors["Parcel"] = true;
      if (!this.quoteModel.Order_Loan) this.errors["Loan"] = true;
    }

    if (Object.keys(this.errors).length == 0) {
      this.goToStep2();
    } else {
      this.fieldError = Object.keys(this.errors);
    }
  }

  getPlaceDetails(place: any) {
    this.quoteModel.searchValue = "";
    console.log("place.geometry: ", place);
    this.clearErrors();
    if (place.geometry == undefined) {
      this.quoteModel.Property_City = "";
      this.quoteModel.Property_State_Abbr = "";
      this.quoteModel.Property_ZipCode = "";
      this.checkAvlCounty = "";
      this.searchError = true;
      return;
    } else {
      this.searchError = false;
      this.latitude = place.geometry.location.lat();
      this.longitude = place.geometry.location.lng();

      this.quoteModel.Property_Address_1 = "";
      this.quoteModel.Property_City = "";
      this.quoteModel.Property_State_Abbr = "";
      this.quoteModel.Property_ZipCode = "";
      this.quoteModel.Property_County = "";
      this.checkAvlCounty = "";
      for (let i = place.address_components.length - 1; i >= 0; i--) {
        switch (place.address_components[i].types[0]) {
          case "premise":
            this.quoteModel.Property_Address_1 =
              place.address_components[i].long_name +
              " " +
              this.quoteModel.Property_Address_1;
            document
              .getElementById("Property_Address_1")
              .parentElement.classList.remove("is-empty");
            break;
          case "street_number":
            this.quoteModel.Property_Address_1 =
              place.address_components[i].long_name +
              " " +
              this.quoteModel.Property_Address_1;
            document
              .getElementById("Property_Address_1")
              .parentElement.classList.remove("is-empty");
            break;
          case "route":
            this.quoteModel.Property_Address_1 =
              place.address_components[i].long_name;
            document
              .getElementById("Property_Address_1")
              .parentElement.classList.remove("is-empty");
            break;
          case "sublocality_level_1":
            if (this.quoteModel.Property_City.length == 0)
              this.quoteModel.Property_City =
                place.address_components[i].long_name;
            document
              .getElementById("Property_City")
              .parentElement.classList.remove("is-empty");
            break;
          case "sublocality":
            if (this.quoteModel.Property_City.length == 0)
              this.quoteModel.Property_City =
                place.address_components[i].long_name;
            document
              .getElementById("Property_City")
              .parentElement.classList.remove("is-empty");
            break;
          case "locality":
            if (this.quoteModel.Property_City.length == 0)
              this.quoteModel.Property_City =
                place.address_components[i].long_name;
            document
              .getElementById("Property_City")
              .parentElement.classList.remove("is-empty");
            break;
          case "administrative_area_level_2": {
            if (this.checkSaint(place.address_components[i].long_name))
              this.checkCountyNReplace(place, i);
            else this.checkCountyNReplace(place, i);
            this.checkAvlCounty =
              place.address_components[i].long_name.toUpperCase();
            // place.address_components[i].long_name = place.address_components[i].long_name.replace('St.', 'saint');
            //if (this.checkCounty(place.address_components[i].long_name))
            // console.log("Switch Case",this.newOrderModel.Property_County, this.newOrderModel.Property_County);
            // document.getElementById('Property_County').parentElement.classList.remove('is-empty');
            break;
          }
          case "administrative_area_level_1": {
            this.newQuoteForm.controls["Property_State_Abbr"].setValue(
              place.address_components[i].short_name
            );
            this.googlePlaceState = place.address_components[i].short_name;
            if (place.address_components[i].short_name == "DC")
              this.checkAvlCounty = "WASHINGTON";
            break;
          }
          case "postal_code":
            this.quoteModel.Property_ZipCode =
              place.address_components[i].short_name;
            break;
          case "country":
            this.quoteModel.Country = place.address_components[i].short_name;
            break;
          default:
            break;
        }
      }
      if (
        this.checkIfState(
          this.quoteModel.Property_State_Abbr,
          this.quoteModel.Country
        )
      ) {
        if (!this.checkAvlCounty) {
          this.checkIfCityIsIndependent();
          this.showCityInCounty = true;
        } else this.showCityInCounty = false;
      } else {
        this.quoteModel.Property_State_Abbr = "";
        this.quoteModel.Property_County = "";
        this.quoteModel.Country = "";
        this.errors["State"] = true;
        this.errors["County"] = true;
      }
      this.ref.detectChanges();
      console.log("errors", this.errors);
      console.log("this.newQuoteForm", this.newQuoteForm);
    }
  }

  checkSaint(county) {
    var lowecaseCounties = CONSTANTS.saintArray.map((value) =>
      value.toLowerCase()
    );
    if (
      county.toLowerCase().startsWith("st.") &&
      lowecaseCounties.indexOf(county.toLowerCase()) == -1
    )
      return true;
    else return false;
  }

  checkCounty(county) {
    var lowecaseCounties = CONSTANTS.countyArray.map((value) =>
      value.toLowerCase()
    );
    if (
      county.toLowerCase().endsWith(" county") &&
      lowecaseCounties.indexOf(county.toLowerCase()) == -1
    )
      return true;
    else return false;
  }

  clearFormsEvent(event) {
    if (event) {
      this.getCountyList(event);
      if (event != this.googlePlaceState) {
      }
    }
    if (this.quoteModel.Property_State_Abbr) delete this.errors.State;
  }

  detectManualChange() {
    this.quoteModel.Property_County = null;
  }
  changeCriteria() {
    if (this.selectedCriteria == 1 || this.selectedCriteria == 3) {
      this.newQuoteForm.controls["Order_Buyer"].clearValidators();
      this.newQuoteForm.controls["Order_Buyer"].setValidators([
        Validators.maxLength(127),
      ]);
      this.newQuoteForm.controls["Order_Buyer"].updateValueAndValidity();
    } else {
      this.newQuoteForm.controls["Order_Buyer"].setValidators([
        Validators.required,
        Validators.maxLength(127),
      ]);
      this.newQuoteForm.controls["Order_Buyer"].updateValueAndValidity();
    }
    this.showCityInCounty = false;
  }

  clearForms() {
    if (this.resetScreenData) {
      this.errors = {};
      this.counties = [];
      this.checkAvlCounty = null;
      this.newQuoteForm.controls["Property_First_Name"].reset();
      this.newQuoteForm.controls["Property_Address_1"].reset();
      this.newQuoteForm.controls["Property_Address_2"].reset();
      this.newQuoteForm.controls["Property_City"].reset();
      this.newQuoteForm.controls["Property_State_Abbr"].reset();
      this.newQuoteForm.controls["Property_County"].reset();
      this.newQuoteForm.controls["Property_ZipCode"].reset();
      this.newQuoteForm.controls["Order_Buyer"].reset();
      this.newQuoteForm.controls["Order_Co_Buyer"].reset();
      this.newQuoteForm.controls["Order_Parcel"].reset();
      this.newQuoteForm.controls["Order_Loan"].reset();
      this.newQuoteForm.controls["Order_Book"].reset();
      this.newQuoteForm.controls["Order_Page"].reset();
      this.newQuoteForm.controls["Property_Cust_File_Number"].reset();
      this.newQuoteForm.controls["Property_Order_Purpose"].reset();
      this.newQuoteForm.controls["Order_Mortgage_Amount"].reset();
      this.newQuoteForm.controls["Order_Mortgage_Date"].reset();
      this.newQuoteForm.controls["Order_Subdivision"].reset();
      this.fieldError = [];
    } else this.resetScreenData = true;
  }

  clearErrors() {
    this.errors = {};
    this.fieldError = [];
  }

  validateDateFn(event) {
    var DATE_REGEXP =
      /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/; //for m/d/yyyy and mm/dd/yyyy
    if (
      event.targetElement.value &&
      !DATE_REGEXP.test(event.targetElement.value)
    )
      this.errors.Order_Mortgage_Date = true;
    else this.errors = {};
  }

  getIfErrors() {
    if (Object.keys(this.errors).length) return true;
    else return false;
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog
      .open(
        ErrorDialogComponent,
        {data:{
          message: msg,
        },
        ...this.config.getDialogOptions()}
      )
  }

  openSuccessDialog() {
    let disposable = this.matDialog
      .open(
        SuccessComponent,
        {data:{
          title: "Success",
          message:
            "We have received your request. We will get back to you as soon as possible.",
        },
        ...this.config.getDialogOptions()}
      ).afterClosed()
      .subscribe((res) => {
        console.log("Closed", res);
      });
  }

  getCountyList(event) {
    if (event) {
      if (this.checkAvlCounty || this.checkAvlCounty == "") {
        if (this.quoteModel.Property_County)
          this.quoteModel.Property_County = null;
      }
      this.pricingService
        .getCountyList(this.quoteModel.Property_State_Abbr)
        .subscribe(
          (counties) => {
            this.counties = counties;
            this.selectedCounties = counties;
            let avlCountyName = [];
            this.counties.forEach((county) =>
              avlCountyName.push(county.County_Name.toUpperCase())
            );
            if (this.showCityInCounty)
              this.quoteModel.Property_County = this.checkAvlCounty;
            else {
              if (avlCountyName.indexOf(this.checkAvlCounty) > -1)
                this.quoteModel.Property_County = this.checkAvlCounty;
            }
            this.ngAfterViewInit();
            this.ref.detectChanges();
          },
          (err) => {
            console.log("Fetching county list failed");
          }
        );
    }
  }

  goToStep2() {
    this.quoteModel.Property_Latitude = this.latitude;
    this.quoteModel.Property_Longitude = this.longitude;
    this.quoteModel.independent_City = this.showCityInCounty;
    this.newOrder.setquoteData1(this.quoteModel);
    console.log("Step 2...", this.quoteModel);
    this.newOrder.setSelectedCriteriaQReq(this.selectedCriteria);
    this.newOrder.setSelectedOptionQReq(this.selectedOption);
    this.router.navigate(["/quote/request/step2"]);
  }

  focusFunction(event) {
    event.srcElement.type = "date";
  }

  goBack() {
    this.router.navigate(["/orders/new"]);
  }

  getFormGrpvalidation() {
    var flag = false;
    switch (this.selectedCriteria) {
      case 1:
        if (this.newQuoteForm.controls["Property_Address_1"].invalid)
          flag = true;
        else if (this.newQuoteForm.controls["Property_Address_2"].invalid)
          flag = true;
        else if (this.newQuoteForm.controls["Property_State_Abbr"].invalid)
          flag = true;
        else if (this.newQuoteForm.controls["Property_County"].invalid)
          flag = true;
        else if (this.newQuoteForm.controls["Property_City"].invalid)
          flag = true;
        else if (this.newQuoteForm.controls["Property_ZipCode"].invalid)
          flag = true;
        else if (this.newQuoteForm.controls["Property_First_Name"].invalid)
          flag = true;
        else if (this.newQuoteForm.controls["Customer_ID"].invalid) flag = true;
        break;
      case 2:
        if (this.newQuoteForm.controls["Order_Buyer"].invalid) flag = true;
        else if (this.newQuoteForm.controls["Order_Co_Buyer"].invalid)
          flag = true;
        else if (this.newQuoteForm.controls["Property_State_Abbr"].invalid)
          flag = true;
        else if (this.newQuoteForm.controls["Property_County"].invalid)
          flag = true;
        else if (this.newQuoteForm.controls["Customer_ID"].invalid) flag = true;
        break;
      case 3:
        if (this.newQuoteForm.controls["Order_Book"].invalid) flag = true;
        else if (this.newQuoteForm.controls["Order_Page"].invalid) flag = true;
        else if (this.newQuoteForm.controls["Order_Parcel"].invalid)
          flag = true;
        else if (this.newQuoteForm.controls["Order_Loan"].invalid) flag = true;
        else if (this.newQuoteForm.controls["Property_State_Abbr"].invalid)
          flag = true;
        else if (this.newQuoteForm.controls["Property_County"].invalid)
          flag = true;
        else if (this.newQuoteForm.controls["Customer_ID"].invalid) flag = true;
        break;
      default:
        flag = false;
        break;
    }
    return flag;
  }

  clearCountyError() {
    if (this.quoteModel.Property_County) delete this.errors.County;
  }

  checkIfState(stateName, country) {
    if (
      country == "US" &&
      this.states.findIndex(
        (state) =>
          state.State_Short_Name.toLowerCase() == stateName.toLowerCase()
      ) > -1
    )
      return true;
    else return false;
  }

  getLoanFormat(e, newOrder) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if (this.input.length == 0 && e.key == "0") e.preventDefault();
    else {
      if (
        (e.keyCode == 8 || e.keyCode == 46) &&
        (this.input.length > 0 ||
          this.input.length < this.config.getLimitPriceMaxLength())
      ) {
        this.input = this.input.slice(0, this.input.length - 1);
        e.target.value = this.numberFormat(this.input);
        // if (e.target.value == '') newOrder.Order_Loan = null;
        newOrder.Order_Loan = e.target.value;
      } else if (this.input.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          this.input = this.input + key;
          e.target.value = this.numberFormat(this.input);
          newOrder.Order_Loan = e.target.value;
        }
      }
      return false;
    }
  }

  numberFormat(val) {
    if (isNaN(parseFloat(val))) return null;
    var num = parseFloat(val);
    return (num / 100).toFixed(2);
  }

  keyValue(keyCode) {
    if (keyCode > 57) keyCode -= 48;
    if (keyCode >= 48 && keyCode <= 57) return String.fromCharCode(keyCode);
  }

  clearValid(control) {
    control.markAsUntouched();
  }

  detectTypeChange(event) {
    this.counties = [];
    this.checkAvlCounty = null;
    this.newQuoteForm.controls["searchValue"].reset();
    this.newQuoteForm.controls["Property_Address_1"].reset();
    this.newQuoteForm.controls["Property_Address_2"].reset();
    this.newQuoteForm.controls["Property_City"].reset();
    this.newQuoteForm.controls["Property_State_Abbr"].reset();
    this.newQuoteForm.controls["Property_County"].reset();
    this.newQuoteForm.controls["Property_ZipCode"].reset();
  }

  searchOptions(query, type: string, id: string) {
    if (query.keyCode == 32 && query.type == "keydown") query.stopPropagation();
    switch (type) {
      case "state":
        this.selectedStates = this.select(
          query.target.value,
          this.states,
          [0],
          id
        );
        break;
      case "county":
        this.selectedCounties = this.select(
          query.target.value,
          this.counties,
          ["County_Name"],
          id
        );
        break;
      default:
        console.log("Invalid");
    }
  }

  select(query: string, entities, key, id): string[] {
    console.log("entities", entities);
    let result: string[] = [];
    if (_.isArray(key)) {
      for (let a of entities) {
        var matched = null;
        for (let k of key) {
          if (
            a[k] &&
            a[k].toLowerCase().indexOf(query.toLowerCase().trim()) > -1
          ) {
            if (matched == null || (matched && matched[id] != a[id]))
              matched = a;
          }
        }
        if (matched) result.push(matched);
      }
    } else if (_.isString(key)) {
      for (let a of entities) {
        if (a[key].toLowerCase().indexOf(query.toLowerCase().trim()) > -1) {
          result.push(a);
        }
      }
    } else console.log("Invalid search key");
    return result;
  }

  clearValue(myInput, key) {
    if (myInput.nativeElement) myInput.nativeElement.value = "";
    else myInput.value = "";
    if (key == "state") this.selectedStates = this.states;
    else if (key == "county") this.selectedCounties = this.counties;
    else console.log("Invalid");
  }

  clickedState(event) {
    if (event)
      this.timer.push(
        setTimeout(() => this.myInputState.nativeElement.focus(), 0)
      );
    else this.clearValue(this.myInputState, "state");
  }

  clickedState1(event) {
    if (event)
      this.timer.push(
        setTimeout(() => this.myInputState1.nativeElement.focus(), 0)
      );
    else this.clearValue(this.myInputState1, "state");
  }

  clickedState2(event) {
    if (event)
      this.timer.push(
        setTimeout(() => this.myInputState2.nativeElement.focus(), 0)
      );
    else this.clearValue(this.myInputState2, "state");
  }

  clickedCounty(event) {
    if (event)
      this.timer.push(
        setTimeout(() => this.myInputCounty.nativeElement.focus(), 0)
      );
    else this.clearValue(this.myInputCounty, "county");
  }

  clickedCounty1(event) {
    if (event)
      this.timer.push(
        setTimeout(() => this.myInputCounty1.nativeElement.focus(), 0)
      );
    else this.clearValue(this.myInputCounty1, "county");
  }

  clickedCounty2(event) {
    if (event)
      this.timer.push(
        setTimeout(() => this.myInputCounty2.nativeElement.focus(), 0)
      );
    else this.clearValue(this.myInputCounty2, "county");
  }

  getCustomers() {
    this.userService.getAllCustomers().subscribe(
      (result) => {
        if (result) {
          this.customers = this.attachCompKeytoCustomerList(result);
          this.selectedCustomers = this.customers;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  attachCompKeytoCustomerList(list) {
    let newList = list.map((user) => {
      user.Index = `${user.User_ID}:${user.Organization_ID}`;
      return user;
    });
    return newList;
  }

  handleSelectionChange(event) {
    if (event) {
      let indexComp = event.split(":");
      this.quoteModel.Customer_ID = indexComp[0];
      this.quoteModel.Organization_ID =
        indexComp[1] == "null" ? null : indexComp[1];
    }
  }

  handleCustomerSelectionChange(event) {
    console.log({ handleCustomerSelectionChange_event: event });
    if (event) {
      this.quoteModel.Customer_ID = event.User_ID;
      this.quoteModel.Organization_ID =
        event.Organization_ID && event.Organization_ID !== "null"
          ? event.Organization_ID
          : null;
      this.quoteModel.customerInfo = event;

      if (event.Organization_ID) {
        this.organizationService
          .getOrganizationDetails(event.Organization_ID)
          .subscribe(
            (orgDetails) => {
              if (orgDetails && orgDetails.Organization_Type == 1)
                this.isQualiaOrder = true;
              else this.isQualiaOrder = false;
            },
            (err) => {
              console.log("Error fetching org details");
            }
          );
      }
    }
  }

  checkCountyNReplace(place, i) {
    place.address_components[i].long_name = place.address_components[
      i
    ].long_name.replace(" County", "");
  }

  getCountyForIndependCity = (state?: string) => {
    this.pricingService.getIndpendentCities().subscribe((cities) => {
      this.allIndepdentCities = cities;
    });
  };

  checkIfCityIsIndependent() {
    let findCityIndex = this.allIndepdentCities.findIndex((city) => {
      if (city)
        return (
          this.quoteModel.Property_City.toLowerCase() ===
          city.System_Independent_City.toLowerCase()
        );
      else return false;
    });
    if (findCityIndex > -1) {
      if (
        this.allIndepdentCities[findCityIndex]
          .System_Independent_City_Custom_Name
      )
        this.checkAvlCounty =
          this.allIndepdentCities[
            findCityIndex
          ].System_Independent_City_Custom_Name.toUpperCase();
      else this.checkAvlCounty = this.quoteModel.Property_City.toUpperCase();
    } else this.checkAvlCounty = this.quoteModel.Property_City.toUpperCase();
  }

  getAmountFormat(e, newOrder) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if (this.input.length == 0 && e.key == "0") e.preventDefault();
    else {
      if (
        (e.keyCode == 8 || e.keyCode == 46) &&
        (this.input.length > 0 ||
          this.input.length < this.config.getLimitPriceMaxLength())
      ) {
        this.input = this.input.slice(0, this.input.length - 1);
        e.target.value = this.numberFormat(this.input);
        newOrder.Order_Mortgage_Amount = e.target.value;
      } else if (this.input.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          this.input = this.input + key;
          e.target.value = this.numberFormat(this.input);
          newOrder.Order_Mortgage_Amount = e.target.value;
        }
      }
      return false;
    }
  }

  getPippinDate(event) {
    if (event && event.Pippin_Date) {
      this.quoteModel["Order_Mortgage_Date"] = event.Pippin_Date;
    } else {
      this.quoteModel["Order_Mortgage_Date"] = null;
    }
  }

}
