import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders,HttpBackend } from '@angular/common/http';
import { AuthService } from '../authentication/auth.service'
import {throwError as observableThrowError,  Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { CONSTANTS } from 'app/app.constants';


@Injectable()
export class QuoterequestService {
  recvdQuoteCnt = 0;
  direct: HttpClient;
 
  constructor(
    private config: ConfigService,
    private router: Router,
    private http: HttpClient,
    private handler: HttpBackend,

    private auth: AuthService,
  ) {
    this.direct = new HttpClient(this.handler);
   }



  getQuoteRequestData(pageNum,sortBy,orderBy,searchStr,filterBy,selectedTags, isDemoFlag, pageSize = this.config.getNumOrdRecordsPerPage()){
    var data = {
      filterBy: filterBy,
      Selected_Tags: selectedTags,
      isDemo : isDemoFlag
    }
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/quotes/admin/' + this.auth.getUserId() + '/requestedQuotes';
    url = url + '/page/' + pageNum + '/size/' + pageSize;
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }


  getBgQuoteRequestData(pageNum,sortBy,orderBy,searchStr,filterBy,selectedTags, pageSize = this.config.getNumOrdRecordsPerPage()){
    var data = {
      filterBy: filterBy,
      Selected_Tags: selectedTags,
    }
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/quotes/admin/' + this.auth.getUserId() + '/requestedQuotes';
    url = url + '/page/' + pageNum + '/size/' + pageSize;
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.direct.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createQuoteRequestExcelReport(filterBy, searchStr, sortField, orderBy, selectedTags, isDemoFlag) {
    var data = {
      filterBy: filterBy,
      searchStr: searchStr,
      Admin_User_ID: this.auth.getUserId(),
      Selected_Tags: selectedTags,
      sortField: sortField,
      orderBy: orderBy,
      Admin_User_Role: this.auth.getUserRole(),
      isDemo : isDemoFlag
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType: 'blob' as 'json',
    }
    let url = this.config.getBasePath() + '/reports/quoterequests/create/excel';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getAllReceivedQuoteCount() {
    let data = {
      Admin_User_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/quotes/receivedquotecount';
    return this.direct.post(url, data, httpOptions).pipe(
      map((response: any) => {
        this.recvdQuoteCnt = response;
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }
    
  getQuoteDetails(quoteId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/quotes/quote/' + quoteId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  cancelQuote(quoteId, comment, customerId) {
    let cancelData = {
      Order_ID:quoteId,
      Order_Status:CONSTANTS.orderStatus.received,
      comment:comment,
      Customer_ID:customerId
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/quotes/admin/'+ this.auth.getUserId() +'/cancelquote';
    return this.http.put(url,cancelData, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  completeQuoteReq(data) {
    data['Admin_User_ID'] = this.auth.getUserId();
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/quotes/complete';
    return this.http.put(url,data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  updateQuote(payload) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/quotes/admin/'+ this.auth.getUserId() +'/update/quote';
    return this.http.post(url, payload, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }
  changeQuoteStatus(quoteId, quoteStatus){
    var payload={
      Admin_User_ID: this.auth.getUserId(),
      Quote_Status: quoteStatus
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/quotes/changestatus/' + quoteId;
    return this.http.put(url, payload, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }
}
