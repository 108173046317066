
import { forkJoin as observableForkJoin, Observable, Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { UsersService, ConfigService, PagerService, StateService, AuthService, ScrollService } from '../../services'
import { CONSTANTS } from '../../app.constants';
import { PreloaderService } from '../../services/preloader/preloader.service'
import { DialogService } from "ng2-bootstrap-modal";
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  users = [];
  usersToDisplay: any;
  totalCount: number;
  pager: any = {};
  listingType = 'all'
  searchString: string;
  searchInProgress: boolean = false;
  pageNum: number;
  scrollEnable: boolean = true;
  constants = CONSTANTS
  srollDistance = this.config.setScrollDistance();
  srollThrottle = this.config.setScrollThrottle();
  isErrorModal: boolean = true;
  searchQuery = new Subject<string>();
  sortField:string;
  orderBy: string;
  timer: any = [];
  constructor(
    private router: Router,
    private pagerService: PagerService,
    private auth: AuthService,
    private config: ConfigService,
    private userService: UsersService,
    private preloaderService: PreloaderService,
    private stateService: StateService,
    private matDialog:MatDialog,
    private scrollService: ScrollService
  ) {
    this.pager.pages = [];
    if (this.pageNum == undefined)
      this.pageNum = 1;
    this.searchString = '';
    this.orderBy = 'DESC';
    this.sortField = 'Admin_InProgress_Count';
    this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
  }

  ngOnInit() {
    if (this.stateService.getUsers()) {
      this.initializeComponentData(this.stateService.getUsers())
    } else this.getInititalUsers();
    this.timer.push(setTimeout(() => this.config.setSideBarNode(4), 0));
  }

  detectTypeChange(event) {
    this.pageNum = 1;
    document.documentElement.scrollTop = 0;
    var x = document.documentElement.scrollTop;
    this.scrollService.setScrollPosUser(x);
    this.getInititalUsers();
  }

ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  // setPage(page: number) {
  //   console.log("Inside set page", page)
  //   if (page < 1 || page > this.pager.totalPages) {
  //     return;
  //   }
  //   this.pageNum = page;
  //   this.setDisplayUsers();
  //   this.getUsers();
  //   this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
  //   console.log("Pager", this.pager)
  // }



  setDisplayUsers() {
    let start = ((this.pageNum - 1) * this.config.getNumRecordsPerPage());
    this.usersToDisplay = this.users.slice(start, this.totalCount);
    if (this.usersToDisplay.length > this.config.getNumRecordsPerPage()) {
      console.log("Slicing")
      this.usersToDisplay = this.usersToDisplay.slice(0, this.config.getNumRecordsPerPage());
    }
  }

  getUsers() {
    this.pageNum = 1;
    this.stateService.setUsers(this);
    this.userService.getAllUsers(this.getSearchString(), this.pageNum, this.listingType, this.orderBy, this.sortField)
      .subscribe((result) => {
        if (result) {
          this.users = result.rows;
          this.totalCount = result.count;
          console.log("Total users: ", this.totalCount)
          this.setDisplayUsers();
          console.log("Now displaying", this.usersToDisplay)
          this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
          this.searchInProgress = false;
        }
      }, () => {
        this.searchInProgress = false;
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
        // this.auth.logout();
        // this.router.navigate(['/login']);
      })
  }

  getInititalUsers() {
    if (this.pageNum == 1) {
      this.stateService.setUsers(this);
      this.userService.getAllUsers(this.getSearchString(), this.pageNum, this.listingType, this.orderBy, this.sortField)
        .subscribe((result1) => {
          if (result1.count > this.config.getNumRecordsPerPage()) {
            this.pageNum++;
            this.userService.getAllUsers(this.getSearchString(), this.pageNum, this.listingType, this.orderBy, this.sortField)
              .subscribe((result2) => {
                var result = {
                  rows: result1.rows.concat(result2.rows),
                  count: result1.count
                }
                this.users = result.rows;
                this.totalCount = result.count;
                this.getUsersData(this.totalCount)
                this.getScrollPosData()
              })
          }
          else {
            this.users = result1.rows;
            this.totalCount = result1.count;
            this.getUsersData(this.totalCount);
            this.getScrollPosData()
          }

        }
          , () => {
            this.searchInProgress = false;
            this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
            // this.auth.logout();
            // this.router.navigate(['/login']);
          })
    } else {
      this.getRetainData()
    }
  }

  getRetainData() {
    var ObsCollection = [];
    for (var i = 1; i <= this.pageNum; i++) {
      ObsCollection.push(this.userService.getAllUsers(this.getSearchString(), i, this.listingType, this.orderBy, this.sortField))
    }

    observableForkJoin(ObsCollection)
      .subscribe((retainedAdmin) => {
        if (retainedAdmin) {
          var totalOrders = [];
          for (var i = 0; i < retainedAdmin.length; i++) {
            this.stateService.setUsers(this);
            totalOrders = totalOrders.concat(retainedAdmin[i]['rows']);
          }
          this.users = totalOrders;
          this.totalCount = retainedAdmin[0]['count'];
          this.getUsersData(this.totalCount);
          this.getScrollPosData()
        }
      })
  }

  getSearchString() {
    var format = /[!#$%^&*()_+\=\[\]{};':"\\|,<>\/?]/;
    if (format.test(this.searchString)) {
      return '';
    } else return this.searchString;
  }

  findUsers() {
    this.pageNum = 1;
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.getInititalUsers();
  }

  initializeComponentData(data) {
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.searchString = data.searchString;
    this.totalCount = data.totalCount;
    this.usersToDisplay = data.usersToDisplay;
    this.searchInProgress = data.searchInProgress;
    this.listingType = data.listingType;
    this.sortField = data.sortField;
    this.orderBy = data.orderBy;
    this.getInititalUsers();
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }

  handleEvent(event) {
    if (event == false) {
      if (this.totalCount > this.config.getNumRecordsPerPage()) {
        if (window.scrollY > this.config.getScrollYThreshold()) this.scrollEnable = false;
        else this.scrollEnable = true;
      }
    } else
      this.scrollEnable = true;
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      this.userService.getAllUsers(this.getSearchString(), this.pageNum, this.listingType, this.orderBy, this.sortField)
        .subscribe((result) => {
          if (result) {
            this.config.resetShowFlag()
            this.totalCount = result.count;
            if (this.totalCount > this.users.length) {
              this.users = this.users.concat(result.rows);
              this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
            }
          }
        })
    }
  }

  getUsersData(result) {
    this.config.resetShowFlag();
    this.config.setClearTimeout();
    this.setDisplayUsers();
    this.pager = this.pagerService.getPager(result, this.pageNum, this.config.getNumRecordsPerPage());
    this.searchInProgress = false;
    this.config.setDelayRecords();
  }

  goToUserDetails(userId) {
    var getPos = document.documentElement.scrollTop;
    this.scrollService.setScrollPosUser(getPos);
    this.router.navigate(['/user/' + userId])
  }

  getScrollPosData() {
    if (this.scrollService.getScrollPosUser()) {
      var x = this.scrollService.getScrollPosUser()
      this.timer.push(setTimeout(function () {
        window.scrollTo(0, x)
      }));
    }
  }

  showOrderSearch(event, adminDetails, flag) {
    event.stopPropagation();
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "Admin_User_ID": JSON.stringify([adminDetails.User_ID]),
        "Completed_Type": flag
      },
    };
    this.router.navigate(['/orders/search'], navigationExtras);
  }

  searchField(search) {
    this.scrollService.setScrollPosUser(0);
    var format = /[!#$%^&*()_+\=\[\]{};':"\\|,<>\/?]/;
    search = search.trim();
    this.searchString = search;
    if (this.searchString) {
      let dotIndex = this.searchString.indexOf(".")
      if (dotIndex == 0 && this.isErrorModal) {
        this.isErrorModal = false;
        this.openErrorPopup("First character cannot be dot(.) in search field.");
      } else if (dotIndex != 0) {
        if (!format.test(this.searchString) && this.searchString==" "){
          this.searchString='';
          this.getInititalUsers();
        }else this.findUsers();
        this.isErrorModal = true;
      }
    } else {
      this.findUsers();
      this.isErrorModal = true;
    }
  }

  setSort(field: string) {
    this.pageNum = 1;
    console.log("setSort", field);
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'ASC';
    }
    this.getInititalUsers();
  }

  setSortDefaultAsDec(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'DESC';
    }
    this.getInititalUsers();
  }

}
