// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-avatar {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  width: 50%;
  height: 10em;
}
.form-avatar img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
.form-avatar .mng-btns {
  position: absolute;
  top: 0.25em;
  right: 0.15em;
}
.form-avatar .img-edit {
  border-radius: 0.2em;
  color: white;
  background-color: #60cc70;
  border-color: #0F9D58;
  padding: 4px 16px;
}
.form-avatar .img-del {
  border-radius: 0.2em;
  color: white;
  background-color: #f44336;
  border-color: #f32112;
  padding: 4px 16px;
  margin: 0px 2px;
}

.form-avatar:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.save-btn {
  top: 16px;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/app/organizations/edit-organization/edit-organization.component.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,wBAAA;EACA,4BAAA;EACA,2BAAA;EACA,sBAAA;EACA,gBAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA;AACF;AACE;EACE,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AAEE;EACE,kBAAA;EACA,WAAA;EACA,aAAA;AAAJ;AAGE;EACE,oBAAA;EACA,YAAA;EACA,yBAAA;EACA,qBAAA;EACA,iBAAA;AADJ;AAIE;EACE,oBAAA;EACA,YAAA;EACA,yBAAA;EACA,qBAAA;EACA,iBAAA;EACA,eAAA;AAFJ;;AAkBA;EACE,WAAA;EACA,cAAA;EACA,oBAAA;AAfF;;AAkBA;EACE,SAAA;EACA,kBAAA;AAfF","sourcesContent":[".form-avatar {\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-color: #fff;\n  overflow: hidden;\n  position: relative;\n  width: 50%;\n  height: 10em;\n\n  img {\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n    object-position: center;\n  }\n\n  .mng-btns{\n    position: absolute;\n    top: 0.25em;\n    right: 0.15em;\n  }\n\n  .img-edit{\n    border-radius: 0.2em;\n    color: white;\n    background-color: #60cc70;\n    border-color: #0F9D58;\n    padding: 4px 16px;\n  }\n\n  .img-del{\n    border-radius: 0.2em;\n    color: white;\n    background-color: #f44336;\n    border-color: #f32112;\n    padding: 4px 16px;\n    margin: 0px 2px;\n  }\n\n  // span {\n  //   position: absolute;\n  //   top: 0.25em;\n  //   right: 0.25em;\n  //   padding: 4px 32px;\n  //   border-radius: 0.75em;\n  //   color: white;\n  //   background-color: #60cc70;\n  //   border-color: #0F9D58;\n  //   border-radius: 2px;\n  // }\n}\n\n.form-avatar:after {\n  content: \"\";\n  display: block;\n  padding-bottom: 100%;\n}\n\n.save-btn{\n  top:16px;\n  position: relative;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
