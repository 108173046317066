import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from "@angular/router";
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder,
} from "@angular/forms";
import {
  AuthService,
  PricingService,
  ConfigService,
  UsersService,
  QuoterequestService,
  OrdersService,
  FileService,
  SchedulingService,
} from "../../services";
import { CONSTANTS } from "../../app.constants";
import { DialogService } from "ng2-bootstrap-modal";
import { SuccessComponent } from "../../dialog/success/success.component";
import { ErrorDialogComponent } from "../../dialog/error-dialog/error-dialog.component";
import * as _ from "underscore";
import { MatSelect } from "@angular/material/select";
import { OrderChangeReasonComponent } from "app/dialog/order-change-reason/order-change-reason.component";
import { forkJoin } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import * as momentTimeZone from 'moment-timezone';
import * as moment from 'moment';
import { environment } from "environments/environment";
import { EtaChangeReasonComponent } from "app/dialog/eta-change-reason/eta-change-reason.component";
import { ConfirmComponent } from "app/dialog/confirm/confirm.component";

declare var google: any

@Component({
  selector: "app-edit-quote-details",
  templateUrl: "./edit-quote-details.component.html",
  styleUrls: ["./edit-quote-details.component.scss"],
})
export class EditQuoteDetailsComponent implements OnInit {
  @ViewChild("firstFocus") firstFocus: MatSelect;

  editQuoteForm: UntypedFormGroup;
  quoteModel: any = {};
  origQuoteModel: any = null;
  error: String = "";
  errors: any = {};
  fieldError = [];
  searchError: boolean = false;
  states = [];
  counties = [];
  selectedStates = [];
  selectedCounties = [];
  documents = [];
  selectedCriteria: any;
  resetScreenData = true;
  loanInput = "";
  mortgageInput = "";
  pricingDetailsObj : any = {};
  tempStates = [];
  checkAvlCounty: any;
  timer: any = [];
  showCityInCounty: boolean = false;
  allIndepdentCities: any;
  order_Requested_Date: any;
  requested_Time :string = "17:00" ;
  isDueRangeErr: boolean = false;
  orderReqtime = new UntypedFormControl({ value: '', disabled: true }, [Validators.required]);
  dueDateRangeOption: Number;
  dueDateFrmObj: any;
  dueDate_Range: any;
  dueDateTimeObj: any;
  dataDepthYears:any;
  weekendHolidayName: String;
  holidayList: any;
  quote_ETA_Date: any;
  quoteETAtime = new UntypedFormControl({ value: '', disabled: true }, [Validators.required]);
  quote_ETA_Time: string = "17:00";
  constructor(
    private frmBuilder: UntypedFormBuilder,
    private ref: ChangeDetectorRef,
    private router: Router,
    private location: Location,
    private auth: AuthService,
    private pricingService: PricingService,
    private QuoterequestService: QuoterequestService,
    private orderService: OrdersService,
    private route: ActivatedRoute,
    private matDialog:MatDialog,
    private config: ConfigService,
    private userService: UsersService,
    private holidayScheduling: SchedulingService,
  ) {
    this.editQuoteForm = this.frmBuilder.group({
      Order_Co_Buyer: [null, Validators.compose([Validators.maxLength(127)])],
      Order_Buyer: [null, Validators.compose([Validators.maxLength(127)])],
      Property_Address_1: [null, Validators.compose([Validators.maxLength(127)])],
      Property_Address_2: [null, Validators.compose([Validators.maxLength(127)])],
      Property_City: [null, Validators.compose([Validators.maxLength(127)])],
      Property_State_Abbr: new UntypedFormControl(
        { value: null, disabled: false },
        Validators.required
      ),
      Property_County: [null],
      Property_ZipCode: [null, Validators.compose([Validators.maxLength(11)])],
      Property_First_Name: [null, Validators.maxLength(127)],
      Property_Order_Number: [null, Validators.maxLength(255)],
      Order_Type: [null],
      Order_Parcel: [null, Validators.compose([Validators.maxLength(255)])],
      Order_Loan: [null, Validators.compose([Validators.maxLength(255)])],
      Order_Book: [null, Validators.compose([Validators.maxLength(255)])],
      Order_Page: [null, Validators.compose([Validators.maxLength(255)])],
      Order_Qualia_Internal_ID: [null, Validators.compose([Validators.maxLength(127)])],
      Order_Requested_Date: [{ disabled: false, value: '' }],
      Order_Buyer_SSN: [null, Validators.compose([Validators.maxLength(4), Validators.minLength(4)])],
      Order_Co_Buyer_SSN: [null, Validators.compose([Validators.maxLength(4), Validators.minLength(4)])],
      Loan_ID: [null, Validators.compose([Validators.maxLength(50)])],
      Order_Subdivision: [null, Validators.compose([Validators.maxLength(255)])],
      Order_Customer_File_Number: [null, Validators.compose([Validators.maxLength(127)])],
      Order_Purpose: [null, Validators.compose([Validators.maxLength(127)])],
      Order_Seller: [null, Validators.compose([Validators.maxLength(512)])],
      Order_Co_Seller: [null, Validators.compose([Validators.maxLength(512)])],
      Order_Mortgage_Amount: [null, Validators.compose([Validators.maxLength(14)])],
      Order_Mortgage_Date: [{ disabled: false, value: '' }],
      Property_Order_Notes: [null],
      searchValue: [null],
      Property_Order_Instructions: [null],
      Co_Borrower_Property_Address_1: [null, Validators.compose([Validators.maxLength(127)])],
      Co_Borrower_Property_State_Abbr: [null, Validators.compose([Validators.maxLength(2)])],
      Co_Borrower_Property_ZipCode: [null, Validators.compose([Validators.maxLength(11)])],
      Years_Required : [null, Validators.compose([Validators.maxLength(255)])],
      Quote_ETA: [{ disabled: false, value: '' }],
    });
    this.editQuoteForm.valueChanges.subscribe((data) => {
      this.error = "";
    });
    if (Number(this.auth.getUserCode()) == CONSTANTS.userStatus.deActivated) {
      this.router.navigate(["/quote/request/step1"]);
    }
  }

  ngOnInit() {
    this.getholidaylistFromLocal();
    forkJoin(
      this.QuoterequestService.getQuoteDetails(this.route.snapshot.params["quoteId"]),
      this.orderService.getOrderNotes(this.route.snapshot.params["quoteId"])
    ).subscribe((data) => {
        this.fillQuoteDetails(data[0]);
        this.fillNotes(data[1]);
      });
    this.getCountyForIndependCity();
    this.pricingService.getStateList().subscribe((states) => {
      if (states) {
        states.forEach((state) =>
          this.tempStates.push({ State_Short_Name: state[0].toUpperCase() })
        );
        this.states = this.tempStates;
        this.selectedStates = this.tempStates;
      }
    });
    this.quoteModel.Property_State_Abbr = "";
    this.timer = setTimeout(() => this.config.setSideBarNode(0), 0);
  }

  ngAfterViewInit() {
    let searchBox: any = document.getElementById('search-box');
    let options = {
      types: [
        // return only geocoding results, rather than business results.
        'geocode',
      ]
    };
    var autocomplete = new google.maps.places.Autocomplete(searchBox, options);
    console.log('searchBox', searchBox);
    // Add listener to the place changed event
    autocomplete.addListener('place_changed', () => {
      this.getPlaceDetails(autocomplete.getPlace());

    });
  }

  getPlaceDetails(place: any) {
    this.quoteModel.searchValue = '';
    console.log("place: ", place);
    console.log("place.geometry: ", place.geometry);
    if (place.geometry == undefined) { 
      this.quoteModel.Property_Address_1 = '';
      this.quoteModel.Property_City = '';
      this.quoteModel.Property_State_Abbr = '';
      this.quoteModel.Property_ZipCode = '';
      this.checkAvlCounty = '';
      document.getElementById('search-box').parentElement.classList.add('is-empty');
      document.getElementById('Property_City').parentElement.classList.add('is-empty');
      document.getElementById('Property_State_Abbr').parentElement.classList.add('is-empty');
      document.getElementById('Property_ZipCode').parentElement.classList.add('is-empty');
      return;
    } else {
      this.quoteModel.Property_Latitude = place.geometry.location.lat();
      this.quoteModel.Property_Longitude = place.geometry.location.lng();

      this.quoteModel.Property_Address_1 = '';
      this.quoteModel.Property_City = '';
      this.quoteModel.Property_State_Abbr = '';
      this.quoteModel.Property_ZipCode = '';
      // this.newOrderModel.Property_County = '';
      this.checkAvlCounty = '';
      // for (let i = 0; i < place.address_components.length; i++) {
      for (let i = place.address_components.length - 1; i >= 0; i--) {
        switch (place.address_components[i].types[0]) {
          case 'street_number': this.quoteModel.Property_Address_1 = place.address_components[i].long_name + ' ' + this.quoteModel.Property_Address_1;
            document.getElementById('Editable_Property_Address_1').parentElement.classList.remove('is-empty');
            break;
          case 'route': this.quoteModel.Property_Address_1 = place.address_components[i].long_name;
            document.getElementById('Editable_Property_Address_1').parentElement.classList.remove('is-empty');
            break;
          case 'sublocality_level_1': if (this.quoteModel.Property_City.length == 0) this.quoteModel.Property_City = place.address_components[i].long_name;
            //document.getElementById('Property_City').parentElement.classList.remove('is-empty');
            break;
          case 'sublocality': if (this.quoteModel.Property_City.length == 0) this.quoteModel.Property_City = place.address_components[i].long_name;
            //document.getElementById('Property_City').parentElement.classList.remove('is-empty');
            break;
          case 'locality': if (this.quoteModel.Property_City.length == 0) this.quoteModel.Property_City = place.address_components[i].long_name;
            //document.getElementById('Property_City').parentElement.classList.remove('is-empty');
            break;
          case 'administrative_area_level_2': {
            if (this.checkSaint(place.address_components[i].long_name)) this.checkCountyNReplace(place, i)
            else this.checkCountyNReplace(place, i)
            this.checkAvlCounty = place.address_components[i].long_name.toUpperCase();
            this.checkCountyAvl()
            break;
          }
          case 'administrative_area_level_1':
            if (this.checkIfState(place.address_components[i].short_name)) {
              this.quoteModel.Property_State_Abbr = place.address_components[i].short_name;
              if (this.quoteModel.Property_State_Abbr == 'DC') {
                this.checkAvlCounty = "WASHINGTON"
                this.checkCountyAvl();
              }
              //document.getElementById('Property_State_Abbr').parentElement.classList.remove('is-empty');
            }
            break;
          case 'postal_code': this.quoteModel.Property_ZipCode = place.address_components[i].short_name;
            //document.getElementById('Property_ZipCode').parentElement.classList.remove('is-empty');
            break;
          default: break;
        }
      }
      this.ref.detectChanges();
    if(this.checkIfState(this.quoteModel.Property_State_Abbr)){
      if(!this.checkAvlCounty){
        this.checkIfCityIsIndependent();
        this.checkCountyAvl();
        this.showCityInCounty = true;
    }else this.showCityInCounty = false;
    }else {
        this.quoteModel.Property_State_Abbr = '';
        this.quoteModel.Property_County = '';
        this.quoteModel.Country = '';
      }
    }

  }

  checkForNameSearch(){
    if(this.quoteModel.Product_ID == environment.nameSearchProductId)return true;
    else return false;
  }

  checkSaint(county) {
    //console.log("saint check",county);
    var lowecaseCounties = CONSTANTS.saintArray.map((value) => value.toLowerCase())
    if (county.toLowerCase().startsWith('st.') && lowecaseCounties.indexOf(county.toLowerCase()) == -1) return true;
    else return false;
  }

  checkCountyNReplace(place, i){
    place.address_components[i].long_name = place.address_components[i].long_name.replace(' County', '')
  } 

  checkIfState(state) {
    var states = _.flatten(this.tempStates);
    let stateBoolean;
    if(states) {
      stateBoolean = states.some((stateList) => {      
        if(stateList.State_Short_Name.includes(state)) return true;
        else return false
      })
    }   
    return stateBoolean;
  }

  checkCountyAvl() {
    let avlCountyName = [];
    this.counties.forEach((county) => avlCountyName.push(county.County_Name.toUpperCase()))
    if (avlCountyName.indexOf(this.checkAvlCounty)) this.quoteModel.Property_County = this.checkAvlCounty
    if(this.quoteModel.Product_ID != this.origQuoteModel.Product_ID) this.getProductPricing(this.quoteModel.Product_ID)
  }

  checkIfCityIsIndependent() {
    let findCityIndex = this.allIndepdentCities.findIndex((city) => {
      if(city) return this.quoteModel.Property_City.toLowerCase() === city.System_Independent_City.toLowerCase()
      else return false;
    })
    if(findCityIndex > -1 ) {
      if(this.allIndepdentCities[findCityIndex].System_Independent_City_Custom_Name)  this.checkAvlCounty = this.allIndepdentCities[findCityIndex].System_Independent_City_Custom_Name.toUpperCase();
      else this.checkAvlCounty = this.quoteModel.Property_City.toUpperCase();;
    }else this.checkAvlCounty = this.quoteModel.Property_City.toUpperCase();;
    }

    getProductPricing(productId, productDescription = null){
      let customerId = this.quoteModel.Organization_ID ? this.quoteModel.Organization_ID : this.quoteModel.Customer_ID;
      if (this.quoteModel.Property_County && this.quoteModel.Property_State_Abbr) {
        this.pricingService.getCustomAndOrginalPriceDetails(this.quoteModel.Property_State_Abbr,this.quoteModel.Property_County, productId, customerId)
        .subscribe((data) => {
          this.pricingDetailsObj.New_Price = null;
          this.pricingDetailsObj.Base_Price = null;
          if(data.Original_Cost) {
            this.pricingDetailsObj.New_Price = data.Original_Cost.Cost_Amount; 
            this.pricingDetailsObj.Base_Price = data.Original_Cost.Cost_Amount; 
            if(data.Custom_Cost)this.pricingDetailsObj.New_Price = data.Custom_Cost.Custom_Cost_Amount;
          }
          if(!this.pricingDetailsObj.New_Price) this.pricingDetailsObj.New_Price = 0;
          if(!this.pricingDetailsObj.Base_Price) this.pricingDetailsObj.Base_Price = 0;
        })
        // if(productDescription) this.updateProductDescription(productId, productDescription)
      }
    }

    // updateProductDescription(productId, productDescription) {
    //   if(this.origQuoteModel.Product_Description !== productDescription) {
    //     let productFindIndex = this.products.findIndex((product) => product.Product_ID === productId);
    //     this.quoteModel.Product_ID = productId;
    //     this.quoteModel.Product_Description = productDescription;
    //     this.quoteModel.Product_Name = this.products[productFindIndex].Product_Name;
    //   } else {
    //     let productFindIndex = this.products.findIndex((product) => product.Product_ID === productId);
    //     this.quoteModel.Product_Description = this.origQuoteModel.Product_Description;
    //     this.quoteModel.Product_Name = this.products[productFindIndex].Product_Name;
    //   }
    // }


  fillQuoteDetails(data) {
    // this.origQuoteModel = data;
    this.origQuoteModel = Object.assign({},data);
    this.quoteModel = data;
    if (data.Order_Requested_Date) this.getAvlOrderReqTime()
    if(data.Quote_ETA) this.getAvlQuoteETAnTime();
    // if (this.origQuoteModel.Property_Address_1) this.selectedCriteria = 1;
    // else if (this.origQuoteModel.Order_Parcel) this.selectedCriteria = 3;
    // else this.selectedCriteria = 2;
    this.selectedCriteria = 1;
    this.quoteModel.selectedCriteria = this.selectedCriteria;
    this.quoteModel.Order_ID = this.origQuoteModel.Order_ID;
    this.quoteModel.Property_Order_Number = this.origQuoteModel.File_ID;
    this.checkAvlCounty = this.capitalizeCounty(
      this.origQuoteModel.Property_County
    );
    this.getDataDepthInfo();
    // this.loadScreenData();
  }
  getAvlOrderReqTime() {
    let etaFullDate = new Date(momentTimeZone.tz(this.quoteModel.Order_Requested_Date, 'America/New_York').format('MM/DD/YYYY HH:mm'));
    let date = new Date(etaFullDate.toDateString());
    this.quoteModel.Order_Requested_Date = date;
    let etaHours = etaFullDate.getHours().toString();
    let etaMinutes = etaFullDate.getMinutes().toString();
    if (etaHours.length == 1) etaHours = "0" + etaHours;
    if (etaMinutes.length == 1) etaMinutes = "0" + etaMinutes;
    this.requested_Time = etaHours + ":" + etaMinutes;
    if (this.quoteModel.Order_Requested_Date) this.orderReqtime.enable();
  }

  getPippinDate(event) {
    if(event && event.Pippin_Date ) {
      this.quoteModel['Order_Mortgage_Date'] = event.Pippin_Date;
    }else this.quoteModel['Order_Mortgage_Date'] = null;
  }

  getAmountFormat(e, editOrder) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if ((this.mortgageInput.length == 0 && e.key == "0")) e.preventDefault();
    else {
      if ((e.keyCode == 8 || e.keyCode == 46) && (this.mortgageInput.length > 0 || this.mortgageInput.length < this.config.getLimitPriceMaxLength())) {
        this.mortgageInput = this.mortgageInput.slice(0, this.mortgageInput.length - 1);
        e.target.value = this.numberFormat(this.mortgageInput);
        if (e.target.value == '') editOrder.Order_Mortgage_Amount = null;
        else editOrder.Order_Mortgage_Amount = e.target.value;
      }
      else if (this.mortgageInput.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          this.mortgageInput = this.mortgageInput + key;
          e.target.value = this.numberFormat(this.mortgageInput);
          editOrder.Order_Mortgage_Amount = e.target.value;
        }
      }
      return false;
    }
  }

  fillNotes(notes) {
    notes.forEach((note) => {
      if (note.Instruction_Type == CONSTANTS.instructionType.orderIns) {
        this.quoteModel.Order_Instructions = note.Instruction_Text;
        this.origQuoteModel.Order_Instructions = note.Instruction_Text;
      }
      if(note.Instruction_Type == CONSTANTS.instructionType.orderNotes) {
        this.quoteModel.Property_Order_Notes = note.Instruction_Text;
        this.origQuoteModel.Property_Order_Notes = note.Instruction_Text;
      }
    });
  }

  validateForm() {
    this.errors = {};
    if (!this.quoteModel.Property_State_Abbr) this.errors["State"] = true;
    if (!this.quoteModel.Property_County) this.errors["County"] = true;
    if (this.isDueRangeErr) {
      this.quote_ETA_Time = "17:00";
      if(this.quoteModel.Quote_ETA) this.quoteModel.Quote_ETA = null;
      if(this.quote_ETA_Date) this.quote_ETA_Date = null;
      if(this.dueDate_Range){
        this.quote_ETA_Date = this.setQuoteETATime(this.dueDate_Range);
        var estShift = momentTimeZone.tz(this.quote_ETA_Date, 'America/New_York').isDST() ? 4 : 5;
        var etaInEstTimezone = moment(this.quote_ETA_Date).utcOffset(moment(this.quote_ETA_Date).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DDTHH:mm');
        this.quoteModel.Quote_ETA_EST = etaInEstTimezone;
      }else if(this.quoteModel.Quote_ETA_Range) this.quoteModel.Quote_ETA_Range = null;
    }else {
      if(this.quoteModel.Quote_ETA_Range) this.quoteModel.Quote_ETA_Range = null;
      if(this.quote_ETA_Date) this.quote_ETA_Date = null;
      if (this.quoteModel.Quote_ETA) {
        this.quote_ETA_Date = this.setQuoteETATime(this.quoteModel.Quote_ETA);
        var estShift = momentTimeZone.tz(this.quote_ETA_Date, 'America/New_York').isDST() ? 4 : 5;
        var etaInEstTimezone = moment(this.quote_ETA_Date).utcOffset(moment(this.quote_ETA_Date).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DDTHH:mm');
        this.quoteModel.Quote_ETA_EST = etaInEstTimezone;
      }
    }
    console.log("this.quoteModel.Quote_ETA_EST", this.quoteModel.Quote_ETA_EST);
    if (this.quoteModel.Order_Mortgage_Date) {
      this.quoteModel.Order_Mortgage_Date_EST = this.quoteModel.Order_Mortgage_Date;
    }
    if(this.quoteModel.Order_Requested_Date) {
     this.order_Requested_Date =  this.setOrderRequestDate(this.quoteModel.Order_Requested_Date)
     var estShift = momentTimeZone.tz(this.order_Requested_Date, 'America/New_York').isDST() ? 4 : 5;
      var etaInEstTimezone = moment(this.order_Requested_Date).utcOffset(moment(this.order_Requested_Date).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DDTHH:mm');
      this.quoteModel.Order_Requested_Date_EST = etaInEstTimezone;
    }

    if (Object.keys(this.errors).length == 0) {          
      this.checkAndUpdateQuote(this.quoteModel);
    } else {
      this.fieldError = Object.keys(this.errors);
    }
  }

  checkAndUpdateQuote(payload){
    if(this.isQuoteChanged()){
      let disposable = this.matDialog.open(OrderChangeReasonComponent, {data:{
        title: 'Send Message',
        msgSendType: CONSTANTS.messageSendStatus.orderMessage
      }, ...{ closeByClickingOutside: false }}).afterClosed()
        .subscribe((res) => {
        if (res && res.state) {
          payload.quoteDetChangeReason = res.Message_Text;
          // this.updateQuote(payload);
          this.checkForSelectedETA(payload)
        }
        });
    } else {
      this.checkForSelectedETA(payload)
    };
  }
  
  updateQuote(payload) {
		this.QuoterequestService.updateQuote({ property : payload }).subscribe(
			() => this.goBack(),
			err => {
				if (err.code == 'PTOR02') this.openErrorDialog('Failed', err.msg);
				if (err.msg) this.error = err.msg;
			},
		);
  }
  
  openErrorDialog(title, msg) {
		let disposable = this.matDialog
			.open(SuccessComponent, {data:{
				title: title,
				message: msg,
			}})
  }

  checkCounty(county) {
    var lowecaseCounties = CONSTANTS.countyArray.map((value) =>
      value.toLowerCase()
    );
    if (
      county.toLowerCase().endsWith(" county") &&
      lowecaseCounties.indexOf(county.toLowerCase()) == -1
    )
      return true;
    else return false;
  }

  clearFormsEvent(event) {
    if (event) {
      this.getCountyList(event);
    }
    if (this.quoteModel.Property_State_Abbr) delete this.errors.State;
  }

  detectManualChange() {
    this.quoteModel.Property_County = null;
    if (
      this.quoteModel.Property_State_Abbr ==
      this.origQuoteModel.Property_State_Abbr
    )
      this.quoteModel.Property_County = this.capitalizeCounty(
        this.origQuoteModel.Property_County
      );
  }

  capitalizeCounty(str) {
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
    }
    const str2 = arr.join(" ");
    return str2;
  }

  changeCriteria() {
    if (this.selectedCriteria == 1 || this.selectedCriteria == 3) {
      this.editQuoteForm.controls["Order_Buyer"].clearValidators();
      this.editQuoteForm.controls["Order_Buyer"].setValidators([
        Validators.maxLength(127),
      ]);
      this.editQuoteForm.controls["Order_Buyer"].updateValueAndValidity();
    } else {
      this.editQuoteForm.controls["Order_Buyer"].setValidators([
        Validators.required,
        Validators.maxLength(127),
      ]);
      this.editQuoteForm.controls["Order_Buyer"].updateValueAndValidity();
    }
    this.showCityInCounty = false;
  }

  clearForms() {
    if (this.resetScreenData) {
      this.errors = {};
      this.counties = [];
      this.checkAvlCounty = null;
      this.editQuoteForm.controls["Property_First_Name"].reset();
      this.editQuoteForm.controls["Property_Address_1"].reset();
      this.editQuoteForm.controls["Property_Address_2"].reset();
      this.editQuoteForm.controls["Property_City"].reset();
      this.editQuoteForm.controls["Property_State_Abbr"].reset();
      this.editQuoteForm.controls["Property_County"].reset();
      this.editQuoteForm.controls["Property_ZipCode"].reset();
      this.editQuoteForm.controls["Order_Buyer"].reset();
      this.editQuoteForm.controls["Order_Co_Buyer"].reset();
      this.editQuoteForm.controls["Order_Parcel"].reset();
      this.editQuoteForm.controls["Order_Loan"].reset();
      this.editQuoteForm.controls["Order_Book"].reset();
      this.editQuoteForm.controls["Order_Page"].reset();
      this.fieldError = [];
      this.loadScreenData();
    } else this.resetScreenData = true;
  }

  loadScreenData() {
    if (this.origQuoteModel) {
      this.checkAvlCounty = this.capitalizeCounty(
        this.origQuoteModel.Property_County
      );
      this.quoteModel.Property_Order_Number = this.origQuoteModel.File_ID;
      if (this.selectedCriteria == 1) {
        this.quoteModel.Property_First_Name = this.origQuoteModel.Property_First_Name;
        this.quoteModel.Order_Buyer = this.origQuoteModel.Order_Buyer;
        this.quoteModel.Order_Co_Buyer = this.origQuoteModel.Order_Co_Buyer;
        this.quoteModel.Property_Address_1 = this.origQuoteModel.Property_Address_1;
        this.quoteModel.Property_Address_2 = this.origQuoteModel.Property_Address_2;
        this.quoteModel.Property_City = this.origQuoteModel.Property_City;
        this.quoteModel.Property_State_Abbr = this.origQuoteModel.Property_State_Abbr;
        this.quoteModel.Property_County = this.checkAvlCounty;
        this.quoteModel.Property_ZipCode = this.origQuoteModel.Property_ZipCode;
      } else if (this.selectedCriteria == 2) {
        this.quoteModel.Order_Buyer = this.origQuoteModel.Order_Buyer;
        this.quoteModel.Order_Co_Buyer = this.origQuoteModel.Order_Co_Buyer;
        this.quoteModel.Property_State_Abbr = this.origQuoteModel.Property_State_Abbr;
        this.quoteModel.Property_County = this.checkAvlCounty;
      } else {
        this.quoteModel.Order_Buyer = this.origQuoteModel.Order_Buyer;
        this.quoteModel.Order_Co_Buyer = this.origQuoteModel.Order_Co_Buyer;
        this.quoteModel.Property_State_Abbr = this.origQuoteModel.Property_State_Abbr;
        this.quoteModel.Property_County = this.checkAvlCounty;
        this.quoteModel.OrderOrder_Parcel_Buyer = this.origQuoteModel.Order_Parcel;
        this.quoteModel.Order_Loan = this.origQuoteModel.Order_Loan;
        this.quoteModel.Order_Book = this.origQuoteModel.Order_Book;
        this.quoteModel.Order_Page = this.origQuoteModel.Order_Page;
      }
      this.ref.detectChanges();
    }
  }

  clearErrors() {
    this.errors = {};
    this.fieldError = [];
  }

  validateDateFn(event) {
    var DATE_REGEXP =
      /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/[12][0-9]{3}$/; //for m/d/yyyy and mm/dd/yyyy
    if (
      event.targetElement.value &&
      !DATE_REGEXP.test(event.targetElement.value)
    )
      this.errors.Order_Mortgage_Date = true;
    else this.errors = {};
  }

  getIfErrors() {
    if (Object.keys(this.errors).length) return true;
    else return false;
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog
      .open(
        ErrorDialogComponent,
        {data:{
          message: msg,
        },
        ...this.config.getDialogOptions()}
      )
  }

  openSuccessDialog() {
    let disposable = this.matDialog
      .open(
        SuccessComponent,
        {data:{
          title: "Success",
          message:
            "We have received your request. We will get back to you as soon as possible.",
        },
        ...this.config.getDialogOptions()}
      )
  }

  getCountyList(event) {
    if (event) {
      if (this.checkAvlCounty || this.checkAvlCounty == "") {
        if (this.quoteModel.Property_County)
          this.quoteModel.Property_County = null;
      }
      this.pricingService
        .getCountyList(this.quoteModel.Property_State_Abbr)
        .subscribe(
          (counties) => {
            this.counties = counties;
            this.selectedCounties = counties;
            let avlCountyName = [];
            this.counties.forEach((county) =>
              avlCountyName.push(county.County_Name.toUpperCase())
            );
            if (this.showCityInCounty)
              this.quoteModel.Property_County = this.checkAvlCounty;
            else {
              if (avlCountyName.indexOf(this.checkAvlCounty.toUpperCase()) > -1)
                this.quoteModel.Property_County = this.checkAvlCounty;
            }
            this.ref.detectChanges();
          },
          (err) => {
            console.log("Fetching county list failed");
          }
        );
    }
  }

  focusFunction(event) {
    event.srcElement.type = "date";
  }

  goBack() {
    // this.router.navigate(["/orders/new"]);
    this.location.back();
  }

  getFormGrpvalidation() {
    var flag = false;
    switch (this.selectedCriteria) {
      case 1:
        if (this.editQuoteForm.controls["Property_Address_1"].invalid)
          flag = true;
        else if (this.editQuoteForm.controls["Property_Address_2"].invalid)
          flag = true;
        else if (this.editQuoteForm.controls["Property_State_Abbr"].invalid)
          flag = true;
        else if (this.editQuoteForm.controls["Property_County"].invalid)
          flag = true;
        else if (this.editQuoteForm.controls["Property_City"].invalid)
          flag = true;
        else if (this.editQuoteForm.controls["Property_ZipCode"].invalid)
          flag = true;
        else if (this.editQuoteForm.controls["Property_First_Name"].invalid)
          flag = true;
        break;
      case 2:
        if (this.editQuoteForm.controls["Order_Buyer"].invalid) flag = true;
        else if (this.editQuoteForm.controls["Order_Co_Buyer"].invalid)
          flag = true;
        else if (this.editQuoteForm.controls["Property_State_Abbr"].invalid)
          flag = true;
        else if (this.editQuoteForm.controls["Property_County"].invalid)
          flag = true;
        break;
      case 3:
        if (this.editQuoteForm.controls["Order_Book"].invalid) flag = true;
        else if (this.editQuoteForm.controls["Order_Page"].invalid) flag = true;
        else if (this.editQuoteForm.controls["Order_Parcel"].invalid)
          flag = true;
        else if (this.editQuoteForm.controls["Order_Loan"].invalid) flag = true;
        else if (this.editQuoteForm.controls["Property_State_Abbr"].invalid)
          flag = true;
        else if (this.editQuoteForm.controls["Property_County"].invalid)
          flag = true;
        break;
      default:
        flag = false;
        break;
    }
    return flag;
  }

  clearCountyError() {
    if (this.quoteModel.Property_County) delete this.errors.County;
  }

  getLoanFormat(e, newOrder) {
    if (e.keyCode == 37 || e.keyCode == 39 || e.keyCode == 9) return true;
    if (this.loanInput.length == 0 && e.key == "0") e.preventDefault();
    else {
      if (
        (e.keyCode == 8 || e.keyCode == 46) &&
        (this.loanInput.length > 0 ||
          this.loanInput.length < this.config.getLimitPriceMaxLength())
      ) {
        this.loanInput = this.loanInput.slice(0, this.loanInput.length - 1);
        e.target.value = this.numberFormat(this.loanInput);
        newOrder.Order_Loan = e.target.value;
      } else if (this.loanInput.length < this.config.getLimitPriceMaxLength()) {
        var key = this.keyValue(e.keyCode);
        if (key) {
          this.loanInput = this.loanInput + key;
          e.target.value = this.numberFormat(this.loanInput);
          newOrder.Order_Loan = e.target.value;
        }
      }
      return false;
    }
  }

  numberFormat(val) {
    if (isNaN(parseFloat(val))) return null;
    var num = parseFloat(val);
    return (num / 100).toFixed(2);
  }

  keyValue(keyCode) {
    if (keyCode > 57) keyCode -= 48;
    if (keyCode >= 48 && keyCode <= 57) return String.fromCharCode(keyCode);
  }

  clearValid(control) {
    control.markAsUntouched();
  }

  detectTypeChange(event) {
    this.counties = [];
    this.checkAvlCounty = null;
    this.editQuoteForm.controls["searchValue"].reset();
    this.editQuoteForm.controls["Property_Address_1"].reset();
    this.editQuoteForm.controls["Property_Address_2"].reset();
    this.editQuoteForm.controls["Property_City"].reset();
    this.editQuoteForm.controls["Property_State_Abbr"].reset();
    this.editQuoteForm.controls["Property_County"].reset();
    this.editQuoteForm.controls["Property_ZipCode"].reset();
  }

  searchOptions(query, type: string, id: string) {
    if (query.keyCode == 32 && query.type == "keydown") query.stopPropagation();
    switch (type) {
      case "state":
        this.selectedStates = this.select(
          query.target.value,
          this.states,
          [0],
          id
        );
        break;
      case "county":
        this.selectedCounties = this.select(
          query.target.value,
          this.counties,
          ["County_Name"],
          id
        );
        break;
      default:
        console.log("Invalid");
    }
  }

  select(query: string, entities, key, id): string[] {
    let result: string[] = [];
    if (_.isArray(key)) {
      for (let a of entities) {
        var matched = null;
        for (let k of key) {
          if (
            a[k] &&
            a[k].toLowerCase().indexOf(query.toLowerCase().trim()) > -1
          ) {
            if (matched == null || (matched && matched[id] != a[id]))
              matched = a;
          }
        }
        if (matched) result.push(matched);
      }
    } else if (_.isString(key)) {
      for (let a of entities) {
        if (a[key].toLowerCase().indexOf(query.toLowerCase().trim()) > -1) {
          result.push(a);
        }
      }
    } else console.log("Invalid search key");
    return result;
  }

  clearValue(myInput, key) {
    if (myInput.nativeElement) myInput.nativeElement.value = "";
    else myInput.value = "";
    if (key == "state") this.selectedStates = this.states;
    else if (key == "county") this.selectedCounties = this.counties;
    else console.log("Invalid");
  }

  getCountyForIndependCity = (state?: string) => {
    this.pricingService.getIndpendentCities().subscribe((cities) => {
      this.allIndepdentCities = cities;
    });
  };


  isQuoteChanged = () => {
    if(this.quoteModel.Property_Order_Number !== this.origQuoteModel.File_ID || 
      this.quoteModel.Order_Instructions !== this.origQuoteModel.Order_Instructions || this.quoteModel.Property_Order_Notes !== this.origQuoteModel.Property_Order_Notes) return true;
      if(this.quoteModel.Property_First_Name !== this.origQuoteModel.Property_First_Name ||
        this.quoteModel.Order_Buyer !== this.origQuoteModel.Order_Buyer ||
        this.quoteModel.Order_Co_Buyer !== this.origQuoteModel.Order_Co_Buyer ||
        this.quoteModel.Property_Address_1 !== this.origQuoteModel.Property_Address_1 ||
        this.quoteModel.Property_Address_2 !== this.origQuoteModel.Property_Address_2 ||
        this.quoteModel.Property_City !== this.origQuoteModel.Property_City ||
        this.quoteModel.Property_State_Abbr !== this.origQuoteModel.Property_State_Abbr ||
        this.quoteModel.Property_County !== this.checkAvlCounty ||
        this.quoteModel.Property_ZipCode !== this.origQuoteModel.Property_ZipCode ||
        this.quoteModel.Order_Buyer !== this.origQuoteModel.Order_Buyer ||
        this.quoteModel.Order_Co_Buyer !== this.origQuoteModel.Order_Co_Buyer ||
        this.quoteModel.Property_State_Abbr !== this.origQuoteModel.Property_State_Abbr ||
        this.quoteModel.Property_County !== this.checkAvlCounty ||
        this.quoteModel.Order_Buyer !== this.origQuoteModel.Order_Buyer ||
        this.quoteModel.Order_Co_Buyer !== this.origQuoteModel.Order_Co_Buyer ||
        this.quoteModel.Property_State_Abbr !== this.origQuoteModel.Property_State_Abbr ||
        this.quoteModel.Property_County !== this.checkAvlCounty ||
        this.quoteModel.Order_Buyer !== this.origQuoteModel.Order_Buyer ||
        this.quoteModel.Order_Co_Buyer !== this.origQuoteModel.Order_Co_Buyer ||
        this.quoteModel.Property_State_Abbr !== this.origQuoteModel.Property_State_Abbr ||
        this.quoteModel.Property_County !== this.checkAvlCounty ||
        this.quoteModel.Order_Parcel !== this.origQuoteModel.Order_Parcel ||
        this.quoteModel.Order_Loan !== this.origQuoteModel.Order_Loan ||
        this.quoteModel.Order_Book !== this.origQuoteModel.Order_Book ||
        this.quoteModel.Order_Page !== this.origQuoteModel.Order_Page ||

        this.quoteModel.Order_Qualia_Internal_ID !== this.origQuoteModel.Order_Qualia_Internal_ID ||
        this.quoteModel.Borrower_SSN !== this.origQuoteModel.Borrower_SSN ||
        this.quoteModel.Co_Borrower_SSN !== this.origQuoteModel.Co_Borrower_SSN ||
        this.quoteModel.Loan_ID !== this.origQuoteModel.Loan_ID ||
        this.quoteModel.Order_Subdivision !== this.origQuoteModel.Order_Subdivision ||
        this.quoteModel.Order_Customer_File_Number !== this.origQuoteModel.Order_Customer_File_Number ||
        this.quoteModel.Order_Purpose !== this.origQuoteModel.Order_Purpose ||
        this.quoteModel.Order_Seller !== this.origQuoteModel.Order_Seller ||
        this.quoteModel.Order_Co_Seller !== this.origQuoteModel.Order_Co_Seller ||
        this.quoteModel.Order_Mortgage_Amount !== this.origQuoteModel.Order_Mortgage_Amount || 
        this.quoteModel.Co_Borrower_Property_Address_1 != this.origQuoteModel.Co_Borrower_Property_Address_1 ||
        this.quoteModel.Co_Borrower_Property_State_Abbr != this.origQuoteModel.Co_Borrower_Property_State_Abbr ||
        this.quoteModel.Co_Borrower_Property_ZipCode != this.origQuoteModel.Co_Borrower_Property_ZipCode ||
        this.quoteModel.Years_Required != this.origQuoteModel.Years_Required ||
        this.isMortgageChanged() ||  this.isRequestedDateChanged() 
        ) return true;
        else return false;
  }

  isMortgageChanged() {   
    var oldDate;
    var newDate;
    if(this.origQuoteModel.Order_Mortgage_Date) {
      oldDate = this.origQuoteModel.Order_Mortgage_Date;
    } else oldDate = null;
    if(this.quoteModel.Order_Mortgage_Date_EST) newDate = this.quoteModel.Order_Mortgage_Date_EST;
    else newDate = null;
    if(oldDate != newDate) return true;
    else return false;
  }

  isRequestedDateChanged() {   
    var oldDate;
    var newDate;
    if(this.origQuoteModel.Order_Requested_Date) {
      let estShift = momentTimeZone.tz(this.origQuoteModel.Order_Requested_Date, 'America/New_York').isDST() ? 4 : 5;
      oldDate = momentTimeZone.tz(this.origQuoteModel.Order_Requested_Date,'America/New_York').add(estShift, 'hours').format('MM-DD-YYYY');
    } else oldDate = null;
    if(this.quoteModel.Order_Requested_Date_EST) newDate = momentTimeZone.tz(this.quoteModel.Order_Requested_Date_EST, 'America/New_York').format('MM-DD-YYYY');
    else newDate = null;
    if(oldDate != newDate) return true;
    else return false;
  }

  getDueDateData(event) {
    this.dueDateRangeOption = Number(event.dueDateType);
    if (Number(event.dueDateType)) {
      if (event.dueDateFrmCtrl) {
        this.isDueRangeErr = true;
        this.dueDateFrmObj = event.dueDateFrmCtrl;
        if (this.dueDateFrmObj) {
          if (this.dueDateFrmObj.value.fromDayCtrl && this.dueDateFrmObj.value.toDayCtrl) {
            let getDueDateRange = this.dueDateFrmObj.value.fromDayCtrl + "-" + this.dueDateFrmObj.value.toDayCtrl;
            let addDays = momentTimeZone.tz('America/New_York').add(Number(this.dueDateFrmObj.value.toDayCtrl), 'days').format()
            let getDateObj =  this.Convert_UTCDate_To_LocalDate(addDays, 'America/New_York');
            this.dueDate_Range = getDateObj; 
            this.quoteModel.Quote_ETA_Range = getDueDateRange;
          } else if (this.quoteModel.Order_Status >= CONSTANTS.orderStatus.received) {
          } else {
            this.dueDate_Range = null;
            this.quoteModel.Quote_ETA_Range = null;
          }
        }
      }
    } else {
      this.isDueRangeErr = false;
      if (event.dueDateTimeCtrl) {
        this.dueDateTimeObj = event.dueDateTimeCtrl;
        if (event.dueDateTimeCtrl.valid) this.checkForDueDateEST(event);
      }
      else this.checkForDueDateEST(event);
    }
  }

  Convert_UTCDate_To_LocalDate(utcTime: any, localTimeZone: string) {
    var utcMoment = momentTimeZone(utcTime);
    var localDateTime = utcMoment.tz(localTimeZone);
    return new Date(localDateTime.format('lll'));
  }

  checkForDueDateEST(event) {
    if (event.Due_Date_EST || event.Due_TIme_EST) {
      this.quoteModel.Quote_ETA = event.Due_Date_EST;
      this.quote_ETA_Time = event.Due_TIme_EST;
    }    
    if(event.req_Date_EST || event.req_Time_EST){
      this.quoteModel.Order_Requested_Date = event.req_Date_EST;
      this.requested_Time = event.req_Time_EST;
    }
    this.ref.detectChanges();
  }
  setOrderRequestDate(RequestDate){
    if(RequestDate && RequestDate._d){
    let getOrderEtaHours = this.requested_Time.split(":")[0];
    let getOrderEtaMinutes = this.requested_Time.split(":")[1];
    return RequestDate._d.setHours(Number(getOrderEtaHours), Number(getOrderEtaMinutes));
  }else if(RequestDate && !RequestDate._d){
    let getOrderEtaHours = this.requested_Time.split(":")[0];
    let getOrderEtaMinutes = this.requested_Time.split(":")[1];
    return RequestDate.setHours(Number(getOrderEtaHours), Number(getOrderEtaMinutes));
  }else return null;
  }

  getDataDepthInfo() {
    this.orderService.getDataDepthInfo({Property_State_Abbr: this.quoteModel.Property_State_Abbr })
      .subscribe((info) => {
        this.dataDepthYears = info[0]["State Statute"][0]
        // if(info.length > 0) this.dataDepth = info[0];
        // else this.gsheetSearchInfo = {};
        // if(this.quoteModel.Years_Required == null) this.quoteModel.Years_Required = info[0]["State Statute"][0];
      }, (err) => {
      })
  }

  getAvlQuoteETAnTime() {
    let etaFullDate = new Date(momentTimeZone.tz(this.quoteModel.Quote_ETA, 'America/New_York').format('MM/DD/YYYY HH:mm'));
    let date = new Date(etaFullDate.toDateString());
    this.quoteModel.Quote_ETA = date;
    let etaHours = etaFullDate.getHours().toString();
    let etaMinutes = etaFullDate.getMinutes().toString();
    if (etaHours.length == 1) etaHours = "0" + etaHours;
    if (etaMinutes.length == 1) etaMinutes = "0" + etaMinutes;
    this.quote_ETA_Time = etaHours + ":" + etaMinutes;
    if (this.quoteModel.Quote_ETA) this.quoteETAtime.enable();
  }

  setQuoteETATime(orderETA) {
    if(orderETA && orderETA._d){
    let getOrderEtaHours = this.quote_ETA_Time.split(":")[0];
    let getOrderEtaMinutes = this.quote_ETA_Time.split(":")[1];
    return orderETA._d.setHours(Number(getOrderEtaHours), Number(getOrderEtaMinutes));
    }
    else if(orderETA && !orderETA._d){
      let getOrderEtaHours = this.quote_ETA_Time.split(":")[0];
      let getOrderEtaMinutes = this.quote_ETA_Time.split(":")[1];
      return orderETA.setHours(Number(getOrderEtaHours), Number(getOrderEtaMinutes));
    }
    else return null;
  }

  checkForSelectedETA(data){
    if(this.isETAChanged()){
      let orderETADate = moment(data.Quote_ETA).format("YYYY-MM-DD");
      let checkETAIsHoliday = this.holidayList.findIndex((holiday) => {
        if(holiday && moment(holiday.Holiday_Date, "YYYY-MM-DD").isSame(orderETADate)) return true;
        else return false
      })     
      console.log("checkETAIsHoliday", checkETAIsHoliday);
      console.log("Is on weekend", this.checkETALieOnWeekend(data.Quote_ETA));
      if(checkETAIsHoliday > -1 ) this.showWarningPopup(this.holidayList[checkETAIsHoliday].Holiday_Name, data);
      else if(this.checkETALieOnWeekend(data.Quote_ETA)) this.showWarningPopup(this.weekendHolidayName, data)
      else this.checkForETAChanged(data);
    }else this.checkForETAChanged(data);
  }

  isETAChanged() {   
    var oldETA;
    var newETA;
    if(this.origQuoteModel.Quote_ETA) {
      let estShift = momentTimeZone.tz(this.origQuoteModel.Quote_ETA, 'America/New_York').isDST() ? 4 : 5;
      oldETA = momentTimeZone.tz(this.origQuoteModel.Quote_ETA,'America/New_York').add(estShift, 'hours').format('MM-DD-YYYYTHH:mm'); //UTC =>EST
    } else oldETA = null;
    if(this.quoteModel.Quote_ETA_EST) {
      newETA = momentTimeZone.tz(this.quoteModel.Quote_ETA_EST, 'America/New_York').format('MM-DD-YYYYTHH:mm'); //EST
    }
    else newETA = null;
    if(oldETA != newETA) return true;
    else return false;
  }

  showWarningPopup(holidayName, data){
    let disposable = this.matDialog.open(ConfirmComponent, {data:{
      title:'ETA Note',
      message:"The ETA on this quote is a US holiday ("+holidayName+"). Would you like to continue?"
    }, ...this.config.getDialogOptions()}).afterClosed()
    .subscribe((res) => { 
      if(res) this.checkForETAChanged(data);
    });
  }

  checkETALieOnWeekend(orderETA) {
    if(moment(orderETA).isoWeekday() == 7){ // iso Weekday for sunday is 7
      this.weekendHolidayName = "Sunday";
      return true;
    }
    else if(moment(orderETA).isoWeekday() == 6){ // iso Weekday for saturday is 6
      this.weekendHolidayName = "Saturday";
      return true;
    }else return false;
  }

  getholidaylistFromLocal(){
    let allHolidays = JSON.parse(sessionStorage.getItem('holidays'))
    if(allHolidays) this.setHoliday(allHolidays)
    else this.getholidaylist();
  }

  setHoliday(holiday){
    this.holidayList = holiday
  }

  getholidaylist(){
    this.holidayScheduling.getAllHolidays()
    .subscribe((holidays)=>{
      if(holidays){
        this.setHoliday(holidays)
        sessionStorage.setItem('holidays', JSON.stringify(holidays));
        setTimeout(function(){sessionStorage.removeItem("holidays");}, CONSTANTS.sessiontimeOut.timeOut);      
      }
    }, (err) =>{
      console.log("Error",err)
    })
  }

  checkForETAChanged(data) {
    if(this.isETAChanged()) this.openETAChangeMsgInterface(data);
    else {
      data.quoteETAChangeReason = '';
      data.Quote_ETA_Change_Reason_Type_ID = ''
      this.updateQuote(data);
    }
  }

  openETAChangeMsgInterface(data) {
    let disposable = this.matDialog.open(EtaChangeReasonComponent, {data:{
      label: 'Quote'
    }, ...{ closeByClickingOutside: false }}).afterClosed()
      .subscribe((res) => {
        if(res.state) { 
          data.quoteETAChangeReason = res.Message_Text;
          data.Quote_ETA_Change_Reason_Type_ID = res.Order_ETA_Change_Reason_Type_ID;
          this.updateQuote(data);
        }
      });
  }

}
