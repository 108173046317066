
import {forkJoin as observableForkJoin,  Observable, Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { AuthService, PagerService, ConfigService, ClientsService, StateService, SocketService, OrganizationService, ScrollService, UsermessageService, UsersService } from '../../services';
import { CONSTANTS } from '../../app.constants';
import { Router } from '@angular/router';
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from '../../dialog/confirm/confirm.component';
import { ApproveClientComponent } from '../../dialog/approve-client/approve-client.component';
import { MessageComponent } from '../../dialog/message/message.component';
import { SuccessComponent } from '../../dialog/success/success.component';
import { PreloaderService } from '../../services/preloader/preloader.service';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as momentTimeZone from 'moment-timezone';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {
  constants = CONSTANTS
  listingType = 'live'
  clients: any = [];
  clientsToDisplay: any;
  totalCount: number;
  pager: any = {};
  searchString: string;
  searchInProgress: boolean = false;
  sortField: string;
  orderBy: string;
  pageNum: number;
  currentClient = '';
  organizations = [];
  selectedOrgId = '';
  scrollEnable: boolean = true;
  srollDistance = this.config.setScrollDistance();
  srollThrottle = this.config.setScrollThrottle();
  isErrorModal:boolean = true;
  timer: any = [];
  searchQuery = new Subject<string>();
  constructor(
    public auth: AuthService,
    private router: Router,
    private pagerService: PagerService,
    private config: ConfigService,
    private clientService: ClientsService,
    private matDialog:MatDialog,
    private preloaderService: PreloaderService,
    private stateService: StateService,
    private socket: SocketService,
    private organizationService: OrganizationService,
    private scrollService: ScrollService,
    private userMsg: UsermessageService,
    private usersService: UsersService,
  ) {
    this.pager.pages = [];
    this.pageNum = 1;
    this.orderBy = 'ASC';
    // this.sortField = 'User_First_Name';
    this.sortField = 'User_Status';
    this.searchString = '';
    this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
  }

  ngOnInit() {
    if (this.stateService.getClients()) {
      this.initializeComponentData(this.stateService.getClients())
    } else this.getClients();
    this.socket.syncMessage("client").subscribe((result) => {
      console.log("CAUGHT EVENT EMITTED ON CREATE............")
      this.getBackgroundClients();
    })
    this.getOrganizationList();
    this.timer.push(setTimeout(() => this.config.setSideBarNode(3), 0));
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  detectTypeChange(event) {
    this.pageNum = 1;
    this.setScrollResetPos();
    this.getClients();
  }

  // setPage(page: number) {
  //   console.log("Inside set page", page)
  //   if (page < 1 || page > this.pager.totalPages) {
  //     return;
  //   }
  //   this.pageNum = page;
  //   this.setDisplayClients();
  //   this.getClients();
  //   this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
  //   console.log("Pager", this.pager)
  // }

  getOrganizationList() {
    // this.organizationService.getAllOrganizations()
    //   .subscribe((result) => {
    //     if (result) {
    //       console.log("result", result);
    //       this.organizations = result;
    //       console.log("this.organizations", this.organizations);
    //     }
    //   }, (err) => {
    //     console.log("err", err);
    //     // this.errorMsg = err;
    //   })
    this.organizationService.addedOrganizationList(this.auth.getUserId())
      .subscribe((result) => {
        if (result) {
          console.log("result", result);
          this.organizations = result;
          console.log("this.organizations", this.organizations);
        }
      }, (err) => {
        console.log("err", err);
        // this.errorMsg = err;
      })
  }

  goToEditClientPage(event, clientDetails) {
    if(this.checkAdminType()){
      var getPos = document.documentElement.scrollTop;
      this.scrollService.setScrollPosClients(getPos)
      if (clientDetails.User_Status != CONSTANTS.userStatus.new) {
        this.router.navigate(['/client/' + clientDetails.User_ID]);
      } else {
        console.log("else");
        this.approveOrDecline('approve', clientDetails)
        // this.userAction(event, clientDetails.User_ID, clientDetails.User_First_Name, clientDetails.User_Last_Name)
      }
    }
  }


  setSort(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'ASC';
    }
    this.getClients();
  }

  setSortDefaultAsDec(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'DESC';
    }
    this.getClients();
  }

  checkDaylight(etaDate) {
    if (momentTimeZone.tz(etaDate, 'America/New_York').isDST()) return 'EDT';
    else return 'EST'
  }

  // getClientList() {
  //   return this.clients;
  // }

  setDisplayClients() {
    let start = ((this.pageNum - 1) * this.config.getNumRecordsPerPage());
    this.clientsToDisplay = this.clients.slice(start, this.totalCount);
    if (this.clientsToDisplay.length > this.config.getNumRecordsPerPage()) this.clientsToDisplay = this.clientsToDisplay.slice(0, this.config.getNumRecordsPerPage());
  }

  getClients() {
    if (this.pageNum == 1) {
      this.stateService.setClients(this);
      var filterBy = [];
      if (this.selectedOrgId && (this.selectedOrgId != "9999" ) && this.selectedOrgId != "all") {
        // filterBy['Organization_ID'] = this.selectedOrgId
        filterBy.push({ Organization_ID: this.selectedOrgId });
      }

      this.clientService.getClients(this.listingType, this.pageNum, this.sortField, this.orderBy, this.getSearchString(), filterBy)
        .subscribe((result) => {
          this.clients = result.rows;
          this.totalCount = result.count;
          this.getClientsData(this.totalCount)
          this.getScrollPosData()
        }, (err) => {
          this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
          // this.auth.logout();
          // this.router.navigate(['/login']);
        })
    } else {
      this.getRetainData();
    }
  }

  getRetainData() {
    var ObsCollection = [];
    var filterBy = [];
    if (this.selectedOrgId && (this.selectedOrgId != "9999") && this.selectedOrgId != "all") {
      // filterBy['Organization_ID'] = this.selectedOrgId
      filterBy.push({ Organization_ID: this.selectedOrgId });
    }
    for (var i = 1; i <= this.pageNum; i++) {
      ObsCollection.push(this.clientService.getClients(this.listingType, i, this.sortField, this.orderBy, this.getSearchString(), filterBy))
    }

    observableForkJoin(ObsCollection)
      .subscribe((retainedClient) => {
        if (retainedClient)
          this.getRetainedClient(retainedClient);
      })
  }

  getBackgroundClients() {
    if (this.pageNum == 1) {
      this.stateService.setClients(this);
      var filterBy = [];
      if (this.selectedOrgId && (this.selectedOrgId != "9999") && this.selectedOrgId != "all") {
        // filterBy['Organization_ID'] = this.selectedOrgId
        filterBy.push({ Organization_ID: this.selectedOrgId });
      }
      console.log("getBackgroundClients", filterBy)
      this.stateService.setClients(this);
      this.clientService.getClientsOnBg(this.listingType, this.pageNum, this.sortField, this.orderBy, this.getSearchString(), filterBy)
        .subscribe((result) => {
          this.clients = result.rows;
          this.totalCount = result.count;
          this.getClientsData(this.totalCount)
        })
    } else {
      this.getRetainDataBg();
    }
  }

  getRetainDataBg() {
    var ObsCollection = [];
    var filterBy = [];
    if (this.selectedOrgId && (this.selectedOrgId != "9999") && this.selectedOrgId != "all") {
      // filterBy['Organization_ID'] = this.selectedOrgId
      filterBy.push({ Organization_ID: this.selectedOrgId });
    }
    for (var i = 1; i <= this.pageNum; i++) {
      ObsCollection.push(this.clientService.getClientsOnBg(this.listingType, i, this.sortField, this.orderBy, this.getSearchString(), filterBy))
    }

    observableForkJoin(ObsCollection)
      .subscribe((retainedClient) => {
        if (retainedClient)
          this.getRetainedClient(retainedClient);
      })
  }

  applyFilter(){
    this.setScrollResetPos();
    this.pageNum = 1;
    this.getClients()
  }

  getSearchString() {
    var format = /[!#$%^&*()_+\=\[\]{};':"\\|,<>\/?]/;
    if (format.test(this.searchString)) {
      return '';
    } else return this.searchString;
  }



  findClients() {
    this.pageNum = 1;
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.getClients();
  }

  userAction(event, clientData) {
    event.stopPropagation();
    if (event.target.className == "fa fa-check") this.approveOrDecline('approve', clientData)
    else if (event.target.className == "fa fa-times fa-sm") this.declineClient('decline', clientData)
    else return;
  }

  declineClient(action, clientData) {
    this.openDeclineDialog(action, clientData.User_First_Name, clientData.User_Last_Name).afterClosed()
      .subscribe((res) => {
        if (res) {
          this.clientService.userAction(action, clientData.User_ID)
            .subscribe((result) => {
              console.log("Action success");
              this.getClientCount();
              this.getClients();
            }, (err) => {
              console.log("Error in action", err);
              this.openErrorPopup(CONSTANTS.apiErrors.reqAction);
            })
        } else {
          console.log("Action cancelled");
        }
      })

  }



  approveOrDecline(action, clientData) {
    console.log("approve client")
    this.openDialog(action, clientData).afterClosed()
      .subscribe((res) => {
        if (res) {
        this.usersService.approveUser(res)
          .subscribe(resp => {
            let msg= "Registration approved successfully.";
            this.getRegSuccessMsg(msg);
            this.getClientCount();
            this.getClients();
          },(err)=>{
            this.openErrorPopup(CONSTANTS.apiErrors.reqAction);            
          });        
        } else {
          console.log("Action cancelled");
        }
      }, (err) => {
        this.openErrorPopup(CONSTANTS.apiErrors.reqAction);
      })

  }

  getRegSuccessMsg(msg){
    this.userMsg.setClearSuccessTimeout();
    this.userMsg.setSuccessMsg(msg)
  }

  openDialog(action, clientData) {
    console.log("open dialog")
    return this.matDialog.open(ApproveClientComponent, {data:{
      title: action[0].toUpperCase() + action.substr(1),
      message: clientData,
    }, ...this.config.getDialogOptions()})
  }

  openDeclineDialog(action, firstName, lastname) {
    return this.matDialog.open(ConfirmComponent, {data:{
      title: action[0].toUpperCase() + action.substr(1),
      message: 'Would you like to ' + action + ' the registration request from ' + firstName + ' ' + lastname + ' ?',
    }, ...this.config.getDialogOptions()})
  }


  openMessageInterface($event, clientId) {
    this.currentClient = clientId;
    let disposable = this.matDialog.open(MessageComponent, {data:{
      title: 'Send Message',
      msgSendType: CONSTANTS.messageSendStatus.clientSendMessage
      // message: clientId
    }, ...{ closeByClickingOutside : false}}).afterClosed()
      .subscribe((res) => {
        if (res) this.sendMessage(res.messageText)
        console.log("Closed", res)
      });

    if ($event.stopPropagation) $event.stopPropagation();
    if ($event.preventDefault) $event.preventDefault();
    $event.cancelBubble = true;
    $event.returnValue = false;
  }

  sendMessage(text) {
    var data = {
      Message_Text: text
    }
    this.clientService.sendMessage(data, this.currentClient)
      .subscribe((data) => {
        console.log(data);
        let msg = "Message sent successfully."
        this.successMsgFunc(msg);
        // let disposable = this.matDialog.open(SuccessComponent, {
        //   title: 'Success',
        //   message: 'Message sent successfully.',
        // }, this.config.getDialogOptions())
        //   .subscribe((data) => {
        //     console.log("closed")
        //     this.currentClient = '';
        //   })
      }, (err) => {
        console.log(err);
        this.openErrorPopup(CONSTANTS.apiErrors.sendmessage);
      })
  }

  initializeComponentData(data) {
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.searchString = data.searchString;
    this.orderBy = data.orderBy;
    this.sortField = data.sortField;
    this.totalCount = data.totalCount;
    this.clientsToDisplay = data.clientsToDisplay;
    this.searchInProgress = data.searchInProgress;
    this.listingType = data.listingType;
    this.selectedOrgId = data.selectedOrgId;
    this.getClients();
  }

  getClientCount() {
    this.clientService.getNewClientCountOnBg()
      .subscribe((data) => {
        console.log("data", data)
      }, (err) => {
        console.log("err", err)
      })
  }


  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  handleEvent(event) {
    if (event == false) {
      if (this.totalCount > this.config.getNumRecordsPerPage()) {
        if (window.scrollY > this.config.getScrollYThreshold()) this.scrollEnable = false;
        else this.scrollEnable = true;
      }
    } else
      this.scrollEnable = true;
  }


  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      var filterBy = [];
      if (this.selectedOrgId && (this.selectedOrgId != "9999") && this.selectedOrgId != "all") {
        // filterBy['Organization_ID'] = this.selectedOrgId
        filterBy.push({ Organization_ID: this.selectedOrgId });
      }
      this.clientService.getClients(this.listingType, this.pageNum, this.sortField, this.orderBy, this.getSearchString(), filterBy)
        .subscribe((result) => {
          if (result) {
            this.config.resetShowFlag();
            this.totalCount = result.count;
            if (this.totalCount > this.clients.length) {
              this.clients = this.clients.concat(result.rows);
              this.pager = this.pagerService.getPager(this.totalCount, this.pageNum);
            }
          }
        })
    }
  }

  getClientsData(result) {
    this.config.resetShowFlag();
    this.config.setClearTimeout();
    this.setDisplayClients();
    this.pager = this.pagerService.getPager(result, this.pageNum);
    this.searchInProgress = false;
    this.config.setDelayRecords();
  }

  getScrollPosData() {
    if (this.scrollService.getScrollPosClients()) {
      var x = this.scrollService.getScrollPosClients()
      this.timer.push(setTimeout(function () {
        window.scrollTo(0, x)
      }));
    }
  }

  getRetainedClient(retainedClient) {
    var totalOrders = [];
    for (var i = 0; i < retainedClient.length; i++) {
      this.stateService.setClients(this);
      totalOrders = totalOrders.concat(retainedClient[i]['rows']);
    }
    this.clients = totalOrders;
    this.totalCount = retainedClient[0]['count'];
    this.getClientsData(this.totalCount)
    this.getScrollPosData()
  }

  setScrollResetPos(){
    document.documentElement.scrollTop =0;
    var x = document.documentElement.scrollTop;
    this.scrollService.setScrollPosClients(x);
  }

  searchField(search){
    this.scrollService.setScrollPosClients(0);
    var format = /[!#$%^&*()_+\=\[\]{};':"\\|,<>\/?]/;
    search = search.trim();
    this.searchString = search;
    if (this.searchString) {
      let dotIndex = this.searchString.indexOf(".")
      if (dotIndex == 0 && this.isErrorModal) {
        this.isErrorModal = false;
        this.openErrorPopup("First character cannot be dot(.) in search field.");
      } else if (dotIndex != 0) {
        if (!format.test(this.searchString)&& this.searchString==" "){
          this.searchString='';
          this.getClients();
        }else this.findClients();
        this.isErrorModal = true;
      }
    } else {
      this.findClients();
      this.isErrorModal = true;
    }
  }

  successMsgFunc(msg){
    this.userMsg.setSuccessMsg(msg);
    this.currentClient = '';
  }

  checkAdminType() {
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else return true;
  }
}
