import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ReactiveFormsModule, FormsModule, FormControl, Validators } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService, ClientsService, OrganizationService, UsersService, ConfigService, RoutingStateService, UsermessageService, GroupsService } from '../../services';
import { DialogService } from "ng2-bootstrap-modal";
import { SuccessComponent } from '../../dialog/success/success.component';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-new-client',
  templateUrl: './new-client.component.html',
  styleUrls: ['./new-client.component.scss']
})
export class NewClientComponent implements OnInit {
  @ViewChild('myFirstFocus') myFirstFocus: ElementRef;
  registerFrm: UntypedFormGroup;
  error: string = '';
  errorMsg: any = {};
  errorType: string = '';
  registerModel: any = {};
  domain: string = '';
  timer: any = [];
  marketingGroupMembers = [];  
  selectedSalesPerson = null;

  constructor(
    private auth: AuthService,
    private config: ConfigService,
    private fb: UntypedFormBuilder,
    private clientsService: ClientsService,
    private organizationService: OrganizationService,
    private usersService: UsersService,
    private matDialog:MatDialog,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private routingState: RoutingStateService,
    private userMsg: UsermessageService,
    public groupsService: GroupsService
  ) {
    this.registerFrm = fb.group({
      'User_First_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      'User_Last_Name': [null, Validators.compose([Validators.required, Validators.maxLength(127)])],
      // 'Company_Name':  [null, Validators.compose([Validators.required, Validators.maxLength(127)])],      
      'Company_Name':  [null, Validators.maxLength(127)],
      'Email_Address': [null, Validators.compose([Validators.required, Validators.maxLength(255)])],
      'Confirm_Email_Address': [null, Validators.required],
    })
    this.registerFrm.valueChanges.subscribe((data) => {
      this.error = '';
    });

  }

  ngOnInit() {
    this.getSalesMarketingGrpMembers();
    if (this.route.snapshot.params['orgid'] != 'individual') {
      this.registerModel.Organization_ID = this.route.snapshot.params['orgid'];
      this.getDomain();
    }
    this.timer.push(setTimeout(() => {
      this.config.setSideBarNode(0);
      this.myFirstFocus.nativeElement.focus();
    }, 0));
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  getDomain() {
    this.organizationService.getOrganizationDetails(this.route.snapshot.params['orgid'])
      .subscribe((res) => this.domain = res.Organization_Domain, (err) => console.log(err));
  }

  clearError() {
    this.errorMsg.msg = '';
  }

  validateAndCreateClient() {
    // this.usersService.getDomainName(this.registerModel['Email_Address']).then((res) => {
      // if (this.domain && this.domain != '' && res != this.domain)
      //   this.errorMsg.msg = "eMail address domain not matching with company domain<" + this.domain + ">";
      // else
      //  {
        if (this.registerModel.Organization_ID) this.registerModel.Organization_Domain = this.domain;
        this.createClient();
      // }
    // });
  }

  createClient() {
    this.registerModel.Admin_User_ID = this.auth.getUserId();
    this.registerModel.User_Sales_Person_ID = this.selectedSalesPerson;
    this.clientsService.createClient(this.registerModel)
      .subscribe((res) => {
        let msg = "Client created successfully. Registration link has been sent to the client's email id."
        this.userMsg.setSuccessMsg(msg);
        if (this.routingState.getPrevUrl() && this.routingState.getPrevUrl().includes("organization")) this.location.back();
        else this.router.navigate(['/clients']);
        // this.openModal();
      }, (err) => {
        console.log("err:", err);
        this.errorMsg = err;
      });
  }

  openModal() {
    let disposable = this.matDialog.open(SuccessComponent, {data:{
      title: "Success",
      message: "Client created successfully. Registration link has been sent to the client's email id."
    }, ...this.config.getDialogOptions()}).afterClosed().subscribe((res) => {      
        if (this.routingState.getPrevUrl() && this.routingState.getPrevUrl().includes("organization")) this.location.back();
        else this.router.navigate(['/clients']);
    });
  }

  goBack() {
    // this.location.back();    
    if (this.routingState.getPrevUrl() && this.routingState.getPrevUrl().includes("organization")) this.location.back();
    else this.router.navigate(['/clients']);
  }

  getSalesMarketingGrpMembers () {
    this.groupsService.getGroupMembers(environment.salesMarketingGrpId)
      .subscribe((users) => {
        this.marketingGroupMembers = users;
      }, (err) => {
        console.log("Error while fetching group members data:",err);
      })
  }

}