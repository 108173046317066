
import {forkJoin as observableForkJoin,  Observable, Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { SearchMasterService, ConfigService, PagerService, StateService, ScrollService, AuthService } from '../../services'
import { CONSTANTS } from '../../app.constants';
import { PreloaderService } from '../../services/preloader/preloader.service'
import { DialogService } from "ng2-bootstrap-modal";
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-list-search-master',
  templateUrl: './list-search-master.component.html',
  styleUrls: ['./list-search-master.component.scss']
})
export class ListSearchMasterComponent implements OnInit {
  masters = [];
  mastersToDisplay: any;
  totalCount: number;
  pager: any = {};
  searchString: string;
  searchInProgress: boolean = false;
  pageNum: number;
  scrollEnable: boolean = true;
  constants = CONSTANTS
  srollDistance = this.config.setScrollDistance();
  srollThrottle = this.config.setScrollThrottle();
  isErrorModal:boolean = true;
  sortField:string;
  orderBy: string;
  searchQuery = new Subject<string>();
  filterBy = [];
  Is_Manager:boolean = true;
  internalSM:boolean = true;
  externalSM:boolean = true;
  timer: any = [];
  constructor(
    private router: Router,
    private pagerService: PagerService,
    private config: ConfigService,
    private masterService: SearchMasterService,
    private preloaderService: PreloaderService,
    private stateService: StateService,
    private matDialog:MatDialog,
    private scrollService: ScrollService,
    private auth: AuthService
  ) {
    this.pager.pages = [];
    if (this.pageNum == undefined)
      this.pageNum = 1;
    this.searchString = '';
    this.orderBy = 'DESC';
    this.sortField = 'Search_Master_InProgress_Count';
    this.searchQuery.pipe(
      debounceTime(this.config.getDebounceTime()),
      distinctUntilChanged())
      .subscribe(value => this.searchField(value));
  }

  ngOnInit() {
    if (this.stateService.getSearchMasters()) {
      this.initializeComponentData(this.stateService.getSearchMasters())
    } else this.getInititalMasters();
    this.timer.push(setTimeout(() => this.config.setSideBarNode(18), 0));
  }

  ngOnDestroy(){
    this.timer.forEach((time)=> {
      clearTimeout(time)
    });
  }

  setDisplayMasters() {
    let start = ((this.pageNum - 1) * this.config.getNumRecordsPerPage());
    this.mastersToDisplay = this.masters.slice(start, this.totalCount);
    if (this.mastersToDisplay.length > this.config.getNumRecordsPerPage()) {
      this.mastersToDisplay = this.mastersToDisplay.slice(0, this.config.getNumRecordsPerPage());
    }
  }

  getMasters() {
    this.pageNum = 1;
    this.stateService.setSearchMasters(this);
    this.masterService.getAllMasters(this.getSearchString(), this.pageNum, this.sortField, this.orderBy, this.internalSM, this.externalSM, this.Is_Manager)
      .subscribe((result) => {
        if (result) {
          this.masters = result.rows;
          this.totalCount = result.count;
          this.setDisplayMasters();
          this.pager = this.pagerService.getPagerforSearcher(this.totalCount, this.pageNum);
          this.searchInProgress = false;
        }
      }, () => {
        this.searchInProgress = false;
        this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
      })
  }

  getInititalMasters() {
    if (this.pageNum == 1) {
      this.stateService.setSearchMasters(this);
      this.masterService.getAllMasters(this.getSearchString(), this.pageNum, this.sortField, this.orderBy,  this.internalSM, this.externalSM, this.Is_Manager)
        .subscribe((result1) => {
          this.masters = result1.rows;
          this.totalCount = result1.count;
          this.getMastersData(this.totalCount);
          this.getScrollPosData();
        }
          , () => {
            this.searchInProgress = false;
            this.openErrorPopup(CONSTANTS.apiErrors.datafetch);
          })
    } else {
      this.getRetainData()
    }
  }

  getRetainData() {
    var ObsCollection = [];
    for (var i = 1; i <= this.pageNum; i++) {
      ObsCollection.push(this.masterService.getAllMasters(this.getSearchString(), i, this.sortField, this.orderBy,  this.internalSM, this.externalSM, this.Is_Manager))
    }

    observableForkJoin(ObsCollection)
      .subscribe((retainedAdmin) => {
        if (retainedAdmin) {
          var totalOrders = [];
          for (var i = 0; i < retainedAdmin.length; i++) {
            this.stateService.setSearchMasters(this);
            totalOrders = totalOrders.concat(retainedAdmin[i]['rows']);
          }
          this.masters = totalOrders;
          this.totalCount = retainedAdmin[0]['count'];
          this.getMastersData(this.totalCount);
          this.getScrollPosData()
        }
      })
  }

  getSearchString() {
    var format = /[!#$%^&*()_+\=\[\]{};':"\\|,<>\/?]/;
    if (format.test(this.searchString)) {
      return '';
    } else return this.searchString;
  }

  findUsers() {
    this.pageNum = 1;
    this.preloaderService.setSearchSpin();
    this.searchInProgress = true;
    this.getInititalMasters();
  }

  initializeComponentData(data) {
    this.pager = data.pager;
    this.pageNum = data.pageNum;
    this.searchString = data.searchString;
    this.totalCount = data.totalCount;
    this.mastersToDisplay = data.mastersToDisplay;
    this.searchInProgress = data.searchInProgress;
    this.sortField = data.sortField;
    this.orderBy = data.orderBy;
    this.Is_Manager = data.Is_Manager;
    this.internalSM = data.internalSM;
    this.externalSM = data.externalSM;
    this.getInititalMasters();
  }

  openErrorPopup(msg) {
    let disposable = this.matDialog.open(ErrorDialogComponent, {data:{
      message: msg
    }, ...this.config.getDialogOptions()});
  }

  onScrollDown() {
    this.config.setClearTimeout();
    this.config.setDelayRecords();
    this.onScrollData();
  }

  handleEvent(event) {
    if (event == false) {
      if (this.totalCount > this.config.getNumRecordsPerPage()) {
        if (window.scrollY > this.config.getScrollYThreshold()) this.scrollEnable = false;
        else this.scrollEnable = true;
      }
    } else
      this.scrollEnable = true;
  }

  onTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  onScrollData() {
    if (this.pageNum < this.pager.totalPages) {
      this.pageNum++;
      this.masterService.getAllMasters(this.getSearchString(), this.pageNum, this.sortField, this.orderBy,  this.internalSM, this.externalSM, this.Is_Manager)
        .subscribe((result) => {
          if (result) {
            this.config.resetShowFlag()
            this.totalCount = result.count;
            if (this.totalCount > this.masters.length) {
              this.masters = this.masters.concat(result.rows);
              this.pager = this.pagerService.getPagerforSearcher(this.totalCount, this.pageNum);
            }
          }
        })
    }
  }

  getMastersData(result) {
    this.config.resetShowFlag();
    this.config.setClearTimeout();
    this.setDisplayMasters();
    this.pager = this.pagerService.getPagerforSearcher(result, this.pageNum, this.config.getnoOrderSearchandComplete());
    this.searchInProgress = false;
    this.config.setDelayRecords();
  }

  goToUserDetails(userId) {
    if(this.checkAdminType()){
      var getPos = document.documentElement.scrollTop;
      this.scrollService.setScrollPosSM(getPos);
      this.router.navigate(['/searchmaster/detail/' + userId])
    }
  }

  getScrollPosData() {
    if (this.scrollService.getScrollPosSM()) {
      var x = this.scrollService.getScrollPosSM()
      this.timer.push(setTimeout(function () {
        window.scrollTo(0, x)
      }))
    }
  }

  showOrderSearch(event,searchDetails){
    event.stopPropagation();
    let navigationExtras: NavigationExtras = {
      queryParams: {
          "User_ID": JSON.stringify([searchDetails.User_ID])
      },
    };
    this.router.navigate(['/orders/search'],navigationExtras);
  }

  setSort(field: string) {
    this.pageNum = 1;
    console.log("setSort", field);
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'ASC';
    }
    this.getInititalMasters();
  }

  setSortDefaultAsDec(field: string) {
    this.pageNum = 1;
    if (field == this.sortField) {
      if (this.orderBy == 'ASC') this.orderBy = 'DESC';
      else this.orderBy = 'ASC';
    } else {
      this.sortField = field;
      this.orderBy = 'DESC';
    }
    this.getInititalMasters();
  }
  
  searchField(search){
    this.scrollService.setScrollPosSM(0);
    var format = /[!#$%^&*()_+\=\[\]{};':"\\|,<>\/?]/;
    search=search.trim();
    this.searchString = search;
    if (this.searchString) {
      let dotIndex = this.searchString.indexOf(".")
      if (dotIndex == 0 && this.isErrorModal) {
        this.isErrorModal = false;
        this.openErrorPopup("First character cannot be dot(.) in search field.");
      } else if (dotIndex != 0) {
        if (!format.test(this.searchString)&& this.searchString==" "){
          this.searchString='';
          this.getInititalMasters();
        }else this.findUsers();
        this.isErrorModal = true;
      }
    } else {
      this.findUsers();
      this.isErrorModal = true;
    }
  }

  getAllSearchManagers(event) {
    if(event.checked) this.Is_Manager = true;
    else this.Is_Manager = false
    this.getInititalMasters();
  }


  getAllInternalSM(event) {
    if(event.checked) this.internalSM = true;
    else this.internalSM = false
    this.getInititalMasters();
  }

  getAllExternalSM(event) {
    if(event.checked) this.externalSM = true;
    else this.externalSM = false;
    this.getInititalMasters();
  }

  csvExport() {
    const fields = [
      {
        label: 'First Name',
        value: (row) => {
          if(row.User_First_Name) return row.User_First_Name.toString();
          else return '';
        }
      },
      {
        label: 'Last Name',
        value: (row) => {
          if(row.User_Last_Name) return row.User_Last_Name.toString();
          else return '';
        }
      },
      {
        label: 'Email Address',
        value: (row) => {
          if(row.Email_Address) return row.Email_Address
          else return '';
        }
      },
      {
        label: 'Search Master Type',
        value: (row) => {
          if(row.User_Type == CONSTANTS.userType.internalSM || row.User_Type == null) return row.Is_Manager == 1 ? "Internal (Manager)" : "Internal";
          else if(row.User_Type == CONSTANTS.userType.externalSM) return row.Is_Manager == 1 ? "External (Manager)" : "External";
        }
      },
      {
        label: 'Order In-Progress',
        value: (row) => {
          if(row.Search_Master_InProgress_Count) return row.Search_Master_InProgress_Count.toString();
          else return Number(0).toString();
        }
      },
      {
        label: 'Search Master Status',
        value: (row) => {
          if(row.User_Status == CONSTANTS.userStatus.inActive) return "Suspended";
          else if(row.User_Status == CONSTANTS.userStatus.active || row.User_Status == CONSTANTS.userStatus.approved ) return "Active";
          else if(row.User_Status == CONSTANTS.userStatus.new) return "Password not set."
        }
      },
    ];  

    this.masterService.downloadSMCSVFile(fields, this.masters)
  }

  getUserRole() {
    if(Number(this.auth.getUserRole()) == CONSTANTS.userRole.superAdmin || Number(this.auth.getUserRole()) == CONSTANTS.userRole.compAdmin) return true
    else return false;
  }

  checkAdminType() {
    if (Number(this.auth.getSearchMasterType()) == CONSTANTS.userType.ReadOnlyAdmin) return false;
    else return true;
  }
}